import React, { FC, useState, useEffect, useRef } from 'react'
import { isNil, startCase } from 'lodash'
import { HiArrowRight } from 'react-icons/hi'
import { useSwipeable } from 'react-swipeable'

import {
  isProductionBatch,
  getNextBatchStatus,
} from '@ephemeris/utils/src/jewelry-production'
import { getNumericComputedStylePropertyValue } from '@ephemeris/utils/src/browser'
import { getPercentage } from '@ephemeris/utils/src/math'
import Loader from '@ephemeris/react-components/src/Loader'

import useBatchContext from '../useBatchContext'

export const AdvanceBatchControl: FC<{
  onEndSlide: () => Promise<void>
  isLoading?: boolean
}> = ({ onEndSlide: handleEndSlide, isLoading = false }) => {
  const { batch } = useBatchContext()
  if (!isProductionBatch(batch)) {
    return null
  }
  if (batch.status === 'Post') {
    return <span>This batch has been posted</span>
  }

  const nextBatchStatus = getNextBatchStatus(batch)

  const currentStatus = startCase(batch.status)
  const nextStatus = startCase(nextBatchStatus)
  const arrowRef = useRef<HTMLDivElement>()
  const advanceBatchTextRef = useRef<HTMLSpanElement>(null)
  const secondaryAdvanceBatchTextRef = useRef<HTMLSpanElement>(null)
  const [
    secondaryAdvanceBatchTextFontSize,
    setSecondaryAdvanceBatchTextFontSize,
  ] = useState<number>()
  const [maxDeltaX, setMaxDeltaX] = useState<number>()

  useEffect(() => {
    if (isNil(arrowRef.current)) {
      return
    }

    const rail = arrowRef.current?.parentElement
    const arrowStyle = window.getComputedStyle(arrowRef.current!)
    const arrowWidth = getNumericComputedStylePropertyValue(
      arrowStyle,
      'width'
    )!
    const railStyle = window.getComputedStyle(rail!)
    const getNumericRailPropertyValue = (property: ComputedStyleProperty) =>
      getNumericComputedStylePropertyValue(railStyle, property)
    const railPaddingLeft = getNumericRailPropertyValue('padding-left')!
    const railPaddingRight = getNumericRailPropertyValue('padding-right')!
    const railWidth = getNumericRailPropertyValue('width')!

    const maxDeltaX =
      railWidth - railPaddingLeft - railPaddingRight - arrowWidth

    setMaxDeltaX(maxDeltaX)
  }, [arrowRef])

  const swipableHandlers = useSwipeable({
    onSwiped: async event => {
      if (isNil(maxDeltaX)) {
        return
      }

      if (event.deltaX >= maxDeltaX) {
        await handleEndSlide()
      }

      const arrow = arrowRef.current
      const advanceBatchText = advanceBatchTextRef.current
      const secondaryAdvanceBatchText = secondaryAdvanceBatchTextRef.current

      arrow?.setAttribute('style', `transform: translate(0px, 0px);`)
      arrow?.classList.add('transition-transform')

      advanceBatchText?.classList.add('transition-opacity')
      advanceBatchText?.setAttribute('style', 'opacity: 1;')

      secondaryAdvanceBatchText?.classList.add('transition-opacity')
      secondaryAdvanceBatchText?.style.setProperty('opacity', '0')
    },
    onSwiping: event => {
      if (isNil(maxDeltaX)) {
        return
      }

      const { deltaX } = event

      const translateX =
        deltaX < 0 ? 0 : deltaX >= maxDeltaX ? maxDeltaX : deltaX
      const secondaryTextOpacity = getPercentage(deltaX, maxDeltaX) / 100
      const primaryTextOpacity = 0.8 - secondaryTextOpacity

      arrowRef.current?.setAttribute(
        'style',
        `transform: translateX(${translateX}px);`
      )
      advanceBatchTextRef.current?.setAttribute(
        'style',
        `
          transform: translateX(${translateX / 2}px);
          opacity: ${primaryTextOpacity};
        `
      )
      secondaryAdvanceBatchTextRef.current!.style.opacity = `${secondaryTextOpacity}`

      arrowRef.current?.classList.remove('transition-transform')
      advanceBatchTextRef.current?.classList.remove('transition-opacity')
      secondaryAdvanceBatchTextRef.current?.classList.remove(
        'transition-opacity'
      )
    },
    trackMouse: true,
    trackTouch: true,
  })

  const refPassthrough = (element: HTMLDivElement) => {
    swipableHandlers.ref(element)
    arrowRef.current = element
  }

  useEffect(() => {
    const element = secondaryAdvanceBatchTextRef.current

    if (isNil(element) || isNil(maxDeltaX)) {
      return
    }

    const HORIZONTAL_MARGIN = 4
    const availableWidth = maxDeltaX - HORIZONTAL_MARGIN

    function getTextWidth(text: string, font: string) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')!

      ctx.font = font

      return ctx.measureText(text).width
    }

    const style = window.getComputedStyle(element)
    const { fontFamily, fontSize, fontStyle } = style
    const text = element.innerText
    let numericFontSize = Number(fontSize.replace('px', ''))
    let font = `${fontStyle} ${numericFontSize}px ${fontFamily}`
    let textWidth = getTextWidth(text, font)

    while (textWidth > availableWidth) {
      numericFontSize -= 1
      font = `${fontStyle} ${numericFontSize}px ${fontFamily}`
      textWidth = getTextWidth(text, font)
    }

    setSecondaryAdvanceBatchTextFontSize(numericFontSize)
  }, [secondaryAdvanceBatchTextRef, maxDeltaX])

  return (
    <div className='w-64 p-2 overflow-hidden bg-gray-900 rounded-md shadow-inner h-14 bg-opacity-30'>
      <div className='relative'>
        <div className='absolute flex flex-col justify-center h-14 -top-2'>
          <span
            className='text-xs whitespace-nowrap'
            ref={secondaryAdvanceBatchTextRef}
            style={{
              opacity: 0,
              fontSize: `${
                secondaryAdvanceBatchTextFontSize
                  ? `${secondaryAdvanceBatchTextFontSize}px`
                  : '0.75rem'
              }`,
            }}
          >
            <span className='font-light line-through'>{currentStatus}</span>
            {' → '}
            <span className='font-bold'>{nextStatus}</span>
          </span>
        </div>
        <div className='absolute flex flex-col justify-center ml-16 h-14 -top-2'>
          <span className='text-xs whitespace-nowrap' ref={advanceBatchTextRef}>
            Advance batch
          </span>
        </div>
      </div>
      <div
        {...swipableHandlers}
        ref={refPassthrough}
        className='relative flex items-center justify-center h-full transition-transform rounded-md cursor-pointer w-14 bg-gray-50'
        style={
          isLoading
            ? { transform: `translateX(${maxDeltaX ?? 0}px)` }
            : undefined
        }
      >
        {isLoading ? (
          <Loader style='dark' />
        ) : (
          <HiArrowRight className='w-1/2 text-gray-600' />
        )}
      </div>
    </div>
  )
}
