export function getJigSlotCoordinate(slotIndex: number) {
  const ROW_NAMES = ['A', 'B', 'C']

  const rowIndex = Math.floor(slotIndex / 6)
  const columnIndex = slotIndex % 6

  const x = ROW_NAMES[rowIndex]
  const y = columnIndex + 1

  return `${x}${y}`
}
