export const PRODUCT_IDS = {
  BIRTH_CHART: {
    JEWELRY: {
      TALISMAN: {
        NECKLACE: '4114301648948',
        NECKLACE_NEW: '7665149346051',
        BRACELET: '5314593095840',
      },
    },
    WALL_ART: {
      POSTER: '1701822398516',
    },
  },
  SYNASTRY: {
    JEWELRY: {
      TALISMAN: {
        NECKLACE: '7569219879171',
      },
    },
  },
  LUNAR: {
    JEWELRY: {
      TALISMAN: {
        NECKLACE: '6793716400288',
      },
    },
    WALL_ART: {
      POSTER: '6831733276832',
    },
  },
}

export const PRODUCT_PRETTY_NAME_BY_PRODUCT_ID = {
  [PRODUCT_IDS.BIRTH_CHART.JEWELRY.TALISMAN.NECKLACE]:
    'Ephemeris Birth Chart Talisman',
  [PRODUCT_IDS.BIRTH_CHART.JEWELRY.TALISMAN.BRACELET]:
    'Ephemeris Birth Chart Talisman',
  [PRODUCT_IDS.LUNAR.JEWELRY.TALISMAN.NECKLACE]: 'Ephemeris Lunar Talisman',
  [PRODUCT_IDS.BIRTH_CHART.WALL_ART.POSTER]: 'Ephemeris Birth Chart Poster',
  [PRODUCT_IDS.LUNAR.WALL_ART.POSTER]: 'Ephemeris Lunar Poster',
}
