import type { FC } from 'react'
import type { Actions } from './actions'

import React from 'react'
import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import { useAuth0 } from '@auth0/auth0-react'
import _ from 'lodash'

import createProvider from '../createProvider'
import actions from './actions'
import reducer from './reducer'

export interface State {
  posters: Product.Poster[]
}
export type ActionType = keyof typeof ACTIONS

const ACTIONS = {
  SET_POSTERS: 'SET_POSTERS',
}

const { provider: Provider, useContextHook: usePostersContext } =
  createProvider<State, ActionType, Actions>({
    initialState: { posters: undefined },
    actions,
    reducer,
  })

const PostersProviderInitizalizer = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)

  const { setPosters } = usePostersContext()

  React.useEffect(() => {
    const fetchPosters = async () => {
      const response = await queryFulfillmentApi('/posters')
      const { posters } = (await response.json()) as {
        posters: Product.Poster[]
      }
      const lunarPosters = _.filter(
        posters,
        ({ printType }) => printType === 'lunar'
      )

      setPosters(lunarPosters)
    }

    fetchPosters()
  }, [])

  return children
}

const PostersProvider: FC = ({ children }) => {
  return (
    <Provider>
      <PostersProviderInitizalizer>{children}</PostersProviderInitizalizer>
    </Provider>
  )
}

export { PostersProvider, usePostersContext }
