import type { State, ActionType } from './PostersProvider'

interface Action {
  type: ActionType
  payload: any
}

export default function reducer(state: State, action: Action): State {
  const { type, payload } = action

  switch (type) {
    case 'SET_POSTERS':
      console.log(`--- setting posters`)
      return { ...state, posters: payload.posters }
    default:
      return state
  }
}
