import { map } from 'lodash'
import { sha256 } from 'js-sha256'

import { ALL_REPORTS_BACKLOG_ITEMS_STATUSES } from '@ephemeris/constants/src/fulfillment/reports-backlog'
import { REPORTS_BACKLOG_TABLE_NAME } from '@ephemeris/constants/src/aws/dynamodb'
import { REPORTS_GENERATOR_EC2_INSTANCE_ID } from '@ephemeris/constants/src/aws/ec2'
import { GENERATE_REPORTS_SSM_LOGS_S3_BUCKET_NAME } from '@ephemeris/constants/src/aws/s3'
import dynamoDb from '@ephemeris/utils/src/aws/dynamodb'
import { runShellScriptInEc2Instances } from '@ephemeris/utils/src/aws/ec2'

export function sendGenerateReportCommand(
  backlogItemId: string,
  orderId: string | number
) {
  return runShellScriptInEc2Instances({
    instanceIds: [REPORTS_GENERATOR_EC2_INSTANCE_ID],
    commands: [
      'source /root/.bash_profile',
      `node $REPORTS_GENERATOR_SCRIPT_PATH --backlogItemId="${backlogItemId}"`,
    ],
    outputS3BucketName: GENERATE_REPORTS_SSM_LOGS_S3_BUCKET_NAME,
    outputS3KeyPrefix: `${orderId}`,
  })
}

export function createReportsBacklogGroup(
  backlogItems: ReportsBacklog.Item[],
  groupId: string
): ReportsBacklog.Group {
  const { orderCreatedAt, destinationEmail, orderId, salesChannel } =
    backlogItems[0]
  return {
    id: groupId,
    destinationEmail,
    orderId,
    salesChannel,
    orderCreatedAt,
    items: backlogItems.sort((a, b) =>
      a.nameOnReport.localeCompare(b.nameOnReport)
    ),
    deliveredAt: ((): string | undefined => {
      const [{ deliveredAt }] = backlogItems.sort((a, b) =>
        (b.deliveredAt ?? '').localeCompare(a.deliveredAt ?? '')
      )
      return deliveredAt
    })(),
    status: ((): ReportsBacklog.Status => {
      const statuses = map(backlogItems, ({ status }) => status)

      const statusesByPriority = statuses.sort((a, b) => {
        const priorityLevelA = ALL_REPORTS_BACKLOG_ITEMS_STATUSES.indexOf(a)
        const priorityLevelB = ALL_REPORTS_BACKLOG_ITEMS_STATUSES.indexOf(b)

        return priorityLevelA - priorityLevelB
      })

      return statusesByPriority[0]
    })(),
  }
}

export function createGroupId(
  orderId: number | string,
  destinationEmail: string
): string {
  return sha256(`${orderId}${destinationEmail}`)
}

export async function getBacklogItemsInGroup(
  groupId: string
): Promise<ReportsBacklog.Item[]> {
  const { Items } = await dynamoDb
    .query({
      TableName: REPORTS_BACKLOG_TABLE_NAME,
      IndexName: 'groupId-index',
      KeyConditionExpression: 'groupId = :groupId',
      ExpressionAttributeValues: {
        ':groupId': groupId,
      },
    })
    .promise()

  return Items as ReportsBacklog.Item[]
}
