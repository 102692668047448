import { getItemFromSessionStorage } from '@ephemeris/utils/src/session'

const FULFILLMENT_API_BASE_URL = process.env.GATSBY_FULFILLMENT_API_URL
const REPORTS_BACKLOG_API_URL = `${FULFILLMENT_API_BASE_URL}/reports-backlog`

async function authenticate({
  username,
  password,
}: {
  username: string
  password: string
}): Promise<{ accessToken: string }> {
  const url = new URL(`${FULFILLMENT_API_BASE_URL}/authenticate`)
  url.searchParams.set('username', username)
  url.searchParams.set('password', password)

  const response = await fetch(url.toString())
  const body = await response.json()

  return body
}

async function getReportsBacklogItems(
  lastEvaluatedKey: Dictionary<string> = null
): Promise<{
  backlogItems: ReportsBacklog.Item[]
  lastEvaluatedKey: Dictionary<any>
}> {
  const url = `${REPORTS_BACKLOG_API_URL}/items`
  const accessToken = getItemFromSessionStorage('accessToken') ?? ''
  const response = await fetch(url, {
    method: 'post',
    body: JSON.stringify({ lastEvaluatedKey }),
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  const body = await response.json()

  return {
    backlogItems: body.backlogItems,
    lastEvaluatedKey: body.lastEvaluatedKey,
  }
}

async function getReportsBacklogItemsByOrderId(
  orderId: number
): Promise<ReportsBacklog.Item[]> {
  const url = `${REPORTS_BACKLOG_API_URL}/items-by-order-id/${orderId}`
  const accessToken = getItemFromSessionStorage('accessToken') ?? ''
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  const body = await response.json()

  return body.backlogItems
}

async function getReportsBacklogItem(id: string): Promise<ReportsBacklog.Item> {
  const urlObject = new URL(`${REPORTS_BACKLOG_API_URL}/item`)
  urlObject.searchParams.set('backlogItemId', id)

  const url = urlObject.toString()
  const accessToken = getItemFromSessionStorage('accessToken') ?? ''
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  const body = await response.json()

  return body.backlogItem
}

async function getReportsBacklogItemsInOrder(
  orderId: number
): Promise<ReportsBacklog.Item[]> {
  const url = `${REPORTS_BACKLOG_API_URL}/items-in-order/${orderId}`
  const accessToken = getItemFromSessionStorage('accessToken') ?? ''
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  const body = await response.json()

  return body.backlogItems
}

async function updateReportsBacklogItems(
  backlogItems: ReportsBacklog.Item[]
): Promise<ReportsBacklog.Item[]> {
  const url = `${REPORTS_BACKLOG_API_URL}/items/update`
  const response = await fetch(url, {
    method: 'post',
    body: JSON.stringify({ backlogItems }),
  })

  const body = await response.json()

  return body.backlogItems
}

async function sendReports(
  params: ReportsBacklog.DeliveryInfo
): Promise<ReportsBacklog.Item[]> {
  const url = `${REPORTS_BACKLOG_API_URL}/sendReports`
  const accessToken = getItemFromSessionStorage('accessToken') ?? ''
  const body = JSON.stringify(params)
  const response = await fetch(url, {
    method: 'post',
    body,
    headers: { Authorization: `Bearer ${accessToken}` },
  })
  const responseBody = await response.json()
  const { backlogItems } = responseBody as {
    backlogItems: ReportsBacklog.Item[]
  }

  return 'error' in responseBody
    ? Promise.reject(responseBody)
    : Promise.resolve(backlogItems)
}

async function getReportsDownloadInfo(
  groupId: string
): Promise<ReportsBacklog.DownloadInfo[]> {
  const url = `${REPORTS_BACKLOG_API_URL}/download-info/${groupId}`
  const response = await fetch(url)
  const body = await response.json()

  return body
}

async function triggerGenerateReport(
  backlogItem: ReportsBacklog.Item
): Promise<void> {
  const { id, orderId } = backlogItem
  const url = `${REPORTS_BACKLOG_API_URL}/reports/generate`
  const accessToken = getItemFromSessionStorage('accessToken') ?? ''
  const body = JSON.stringify({ backlogItemId: id, orderId })
  const response = await fetch(url, {
    method: 'post',
    body,
    headers: { Authorization: `Bearer ${accessToken}` },
  })

  if (response.status !== 200) {
    return Promise.reject(await response.json())
  }
}

export function useReportsBacklogApi() {
  return {
    authenticate,
    getReportsBacklogItems,
    getReportsBacklogItemsByOrderId,
    getReportsBacklogItem,
    getReportsBacklogItemsInOrder,
    updateReportsBacklogItems,
    getReportsDownloadInfo,
    sendReports,
    triggerGenerateReport,
  }
}
