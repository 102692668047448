import type { FC } from 'react'

import React from 'react'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { useAuth0 } from '@auth0/auth0-react'
import { useToasts } from 'react-toast-notifications'

import { padZero } from '@ephemeris/utils/src/string'
import { PRODUCT_IDS } from '@ephemeris/constants/src/store'
import { getNameParticles } from '@ephemeris/utils/src/birth-info'
import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import { createGroupId } from '@ephemeris/fulfillment/src/reports-backlog'
import { ACTIVE_REPORT_TYPES } from '@ephemeris/constants/src/fulfillment/reports-backlog'
import { decodeBase64 } from '@ephemeris/utils/src/buffer'
import { safeParseJson } from '@ephemeris/utils/src/json'
import useProductionContext from '../../useProductionContext'
import { ACTIONS } from '../../ProductionProvider/Store'

interface CreateProps {}

interface FormContentProps {
  index: number
  isSingleItem: boolean
  onRemove: () => void
}

type JewelryItemFormItem = {
  printType: Product.Jewelry.Talisman.PrintType
  jewelryType: string
  lineItemId: string
  lineItemIndex: number
  address1: string
  address2?: string
  country: string
  countryCode: string
  customerName: string
  customerEmail: string
  shippingName: string
  postalCode: string
  province: string
  provinceCode?: string
  city: string
}

type AnyJewelryItemFormItem = JewelryItemFormItem &
  BirthChartJewelryFormItem &
  SynastryJewelryItemFormItem

type SynastryJewelryItemFormItem = {
  customizedProductPageUrl: string
}

type BirthChartJewelryFormItem = {
  foreground: string
  background: string
  gmtOffset: string
  houseSystem: string
  isUnknownTime: string
  isoBirthDate: string
  birthHour: string
  birthMinute: string
  birthPlace: string
  latitude: string
  longitude: string
  zodiacSystem: string
  engravingFirstLine?: string
  engravingSecondLine?: string
}

type PrintTypeConstantKeys = 'BIRTH_CHART' | 'SYNASTRY_CHART' | 'LUNAR'
const PRINT_TYPE: Record<
  PrintTypeConstantKeys,
  Product.Jewelry.Talisman.PrintType
> = {
  BIRTH_CHART: 'birth_chart',
  SYNASTRY_CHART: 'synastry_chart',
  LUNAR: 'lunar',
}
const PRINT_TYPE_NAME_MAP: Record<PrintTypeConstantKeys, string> = {
  BIRTH_CHART: 'Birth Chart Talisman',
  SYNASTRY_CHART: 'Synastry Chart Talisman',
  LUNAR: 'Lunar Talisman',
}
const PRODUCT_ID_MAP: Omit<
  Record<
    `${Product.Jewelry.Talisman.PrintType}_${Product.Jewelry.Type}`,
    string
  >,
  'lunar_bracelet' | 'synastry_chart_bracelet'
> = {
  birth_chart_necklace: PRODUCT_IDS.BIRTH_CHART.JEWELRY.TALISMAN.NECKLACE,
  birth_chart_bracelet: PRODUCT_IDS.BIRTH_CHART.JEWELRY.TALISMAN.BRACELET,
  synastry_chart_necklace: PRODUCT_IDS.SYNASTRY.JEWELRY.TALISMAN.NECKLACE,
  lunar_necklace: PRODUCT_IDS.LUNAR.JEWELRY.TALISMAN.NECKLACE,
}

function createJewelryItemId({
  id,
  index,
}: {
  id: string | number
  index?: number
}): string {
  return `${id}${_.isNil(index) ? '' : `-${padZero(index + 1)}`}`
}

function createReportsBacklogItemId(
  itemId: string | number,
  reportType: ReportType,
  index?: number
): string {
  return `${itemId}-${reportType}${!_.isNil(index) ? `-${index}` : ''}`
}

function createReportsBacklogItems(
  formDataItems: AnyJewelryItemFormItem[],
  customer: JewelryProduction.Customer,
  orderId: number
): ReportsBacklog.Item[] {
  const nowIsoDate = DateTime.utc().toISO()

  return _.flatMap(
    formDataItems,
    (formDataItem: AnyJewelryItemFormItem, i): ReportsBacklog.Item[] => {
      if (formDataItem.printType !== 'birth_chart') {
        return []
      }

      const lineItemId = formDataItem.lineItemId
      const isIndexedItem = _.keys(formDataItems).length > 1
      const index = isIndexedItem ? i : undefined

      return _.map(ACTIVE_REPORT_TYPES, (reportType): ReportsBacklog.Item => {
        const id = createReportsBacklogItemId(lineItemId, reportType, index)
        const orderCreatedAt = nowIsoDate
        const customerNameParticles = getNameParticles(
          formDataItem.customerName
        )
        const isUnknownTime = formDataItem.isUnknownTime === 'on'
        const houseSystem = formDataItem.houseSystem as HouseSystem
        const zodiacSystem = formDataItem.zodiacSystem as ZodiacSystem
        const [year, month, day] = formDataItem.isoBirthDate
          .split('-')
          .map(Number)
        const hour = Number(formDataItem.birthHour)
        const minute = Number(formDataItem.birthMinute)
        const gmtOffset = Number(formDataItem.gmtOffset)
        const locationName = formDataItem.birthPlace
        const latitude = Number(formDataItem.latitude)
        const longitude = Number(formDataItem.longitude)
        const birthInfo: BirthInfo = {
          firstName: customerNameParticles.firstName,
          lastName: customerNameParticles.lastName,
          date: {
            year,
            month,
            day,
            hour,
            minute,
            gmtOffset,
          },
          location: {
            name: locationName,
            latitude,
            longitude,
          },
        }
        const destinationEmail = customer.email
        const groupId = createGroupId(orderId, destinationEmail)

        return {
          id,
          orderId,
          groupId,
          createdAt: orderCreatedAt,
          destinationEmail,
          currentLocation: birthInfo.location,
          houseSystem,
          zodiacSystem,
          isStandalone: false,
          isUnknownTime,
          lineItemId,
          missingProperties: [],
          nameOnReport: formDataItem.customerName,
          reportType,
          retryCount: 0,
          salesChannel: 'etsy',
          status: 'ReadyToBeGenerated',
          orderCreatedAt,
          customer,
          birthInfo,
        }
      })
    }
  )
}

function getSynastryJewelryItemCustomizationInfo(
  formDataItem: SynastryJewelryItemFormItem
): JewelryProduction.Synastry.CustomizationInfo {
  const url = new URL(formDataItem.customizedProductPageUrl)
  const encodedCustomizationInfoJson = url.searchParams.get('customizationInfo')
  console.log(`--- encoded:`, encodedCustomizationInfoJson)
  const customizationInfoJson = decodeBase64(encodedCustomizationInfoJson)
  console.log(`--- json:`, customizationInfoJson)
  const customizationInfo =
    safeParseJson<JewelryProduction.Synastry.CustomizationInfo>(
      customizationInfoJson
    )
  console.log(`--- customizationInfo:`, customizationInfo)

  return customizationInfo
}

function getBirthChartJewelryItemCustomizationInfo(
  formDataItem: BirthChartJewelryFormItem
): JewelryProduction.BirthChart.CustomizationInfo {
  const customerNameParticles = getNameParticles(formDataItem.customerName)
  const foreground = formDataItem.foreground as JewelryProduction.JewelryColor
  const background = formDataItem.background as JewelryProduction.JewelryColor
  const rawFirstLine = formDataItem.engravingFirstLine ?? ''
  const rawSecondLine = formDataItem.engravingSecondLine ?? ''
  const lines = _.map([rawFirstLine, rawSecondLine], line =>
    _.isEmpty(line) ? undefined : line
  )
  const hasEngraving = !_.every(lines, _.isNil)
  const isUnknownTime = formDataItem.isUnknownTime === 'on'
  const houseSystem = formDataItem.houseSystem as HouseSystem
  const zodiacSystem = formDataItem.zodiacSystem as ZodiacSystem
  const engraving = hasEngraving
    ? ((): JewelryProduction.EngravingInfo => {
        const [firstLine, secondLine] = lines
        return { firstLine, secondLine }
      })()
    : undefined
  const [year, month, day] = formDataItem.isoBirthDate.split('-').map(Number)
  const hour = Number(formDataItem.birthHour)
  const minute = Number(formDataItem.birthMinute)
  const gmtOffset = Number(formDataItem.gmtOffset)
  const locationName = formDataItem.birthPlace
  const latitude = Number(formDataItem.latitude)
  const longitude = Number(formDataItem.longitude)
  const birthInfo: BirthInfo = {
    firstName: customerNameParticles.firstName,
    lastName: customerNameParticles.lastName,
    date: {
      year,
      month,
      day,
      hour,
      minute,
      gmtOffset,
    },
    location: {
      name: locationName,
      latitude,
      longitude,
    },
  }
  const theme: JewelryProduction.JeweleryTheme = {
    foreground,
    background,
  }

  return {
    theme,
    engraving,
    hasEngraving,
    birthInfo,
    isUnknownTime,
    houseSystem,
    zodiacSystem,
  }
}

function createJewelryProductionItems(
  formDataItems: AnyJewelryItemFormItem[],
  shippingAddress: JewelryProduction.ShippingAddress,
  customer: JewelryProduction.Customer,
  orderId: number
): JewelryProduction.AnyItem[] {
  const nowIsoDate = DateTime.utc().toISO()
  console.log(`--- formDataItems:`, formDataItems)

  return _.map(
    formDataItems,
    (formDataItem, i): JewelryProduction.BirthChart.Item => {
      console.log(`--- printType:`, formDataItem.printType)
      const isIndexedJewelryItem = _.keys(formDataItems).length > 1
      const index = isIndexedJewelryItem ? i : undefined
      const id = createJewelryItemId({
        id: uuid(),
        index,
      })
      const lineItemId = formDataItem.lineItemId
      const orderName = `EPH${orderId}M3R`
      const uniqueOrderName = `${orderName}${
        formDataItems.length > 1 ? padZero(index + 1) : ''
      }`

      const jewelryType =
        formDataItem.jewelryType as JewelryProduction.JewelryType
      const productId =
        PRODUCT_ID_MAP[`${formDataItem.printType}_${jewelryType}`]

      const customizationInfo = (() => {
        switch (formDataItem.printType) {
          case 'birth_chart':
            return getBirthChartJewelryItemCustomizationInfo(formDataItem)
          case 'synastry_chart':
            return getSynastryJewelryItemCustomizationInfo(formDataItem)
          default:
            throw new Error(`Unknown print type: ${formDataItem.printType}`)
        }
      })()

      const type =
        formDataItem.printType === 'birth_chart'
          ? 'BirthChart'
          : formDataItem.printType === 'synastry_chart'
          ? 'Synastry'
          : 'Moon'

      return {
        id,
        productId,
        lineItemId,
        orderId: `${orderId}`,
        orderName,
        uniqueOrderName,
        jewelryType,
        missingProperties: [],
        createdAt: nowIsoDate,
        orderCreatedAt: nowIsoDate,
        orderUpdatedAt: nowIsoDate,
        productionStatus: null,
        priority: 'Regular',
        history: [],
        status: 'WaitingForRender',
        type,
        customer,
        customizationInfo,
        shippingAddress,
      }
    }
  )
}

const LunarTalismanFormContent: FC<{
  index: number
}> = ({ index }) => {
  return (
    <>
      <label className='font-semibold'>
        Color:
        <select name='color' className='block p-2 rounded' data-index={index}>
          <option value='silver'>Silver</option>
          <option value='gold'>Gold</option>
          <option value='rose-gold'>Rose Gold</option>
        </select>
      </label>

      <label className='font-semibold'>
        Hemisphere:
        <select
          name='hemisphere'
          className='block p-2 rounded'
          data-index={index}
        >
          <option value='north'>North</option>
          <option value='south'>South</option>
        </select>
      </label>
    </>
  )
}

const SynastryTalismanFormContent: FC<{
  index: number
}> = ({ index }) => {
  const [isValidUrl, setIsValidUrl] = React.useState<boolean>(null)
  const [customizationInfo, setCustomizationInfo] =
    React.useState<Product.Synastry.Talisman.CustomizationInfo>(null)

  const handleTextChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    const urlString = event.currentTarget.value.trim()

    if (!urlString) {
      setIsValidUrl(null)
      return
    }

    try {
      const url = new URL(event.currentTarget.value)
      const encodedCustomizationInfoJson =
        url.searchParams.get('customizationInfo')
      const customizationInfoJson = decodeBase64(encodedCustomizationInfoJson)
      const customizationInfo =
        safeParseJson<Product.Synastry.Talisman.CustomizationInfo>(
          customizationInfoJson
        )

      setCustomizationInfo(customizationInfo)
      setIsValidUrl(true)
    } catch {
      setCustomizationInfo(null)
      setIsValidUrl(false)
    }
  }

  return (
    <div>
      <label className='font-semibold'>
        Customized product page URL:
        <div className='mt-2' />
        <input
          type='text'
          className='w-full p-2 rounded'
          placeholder='https://ephemeris.co/products/syanstry-talisman?customizationInfo=eysdfsdHdhjLAJKDhJKdh...'
          data-index={index}
          name='customizedProductPageUrl'
          onChange={handleTextChange}
        />
      </label>
      {isValidUrl === false && (
        <div className='mt-2'>
          <span className='text-rose-400'>The URL inserted is invalid</span>
        </div>
      )}
      {!_.isNil(customizationInfo) && (
        <div className='mt-2'>
          <textarea
            className='w-full h-16 p-4 font-mono leading-normal'
            value={JSON.stringify(customizationInfo, null, 2)}
            readOnly
          />
        </div>
      )}
    </div>
  )
}

const BirthChartTalismanFormContent: FC<{
  index: number
  defaultDate: DateTime
}> = ({ index, defaultDate }) => {
  const [isUnknownTime, setIsUnknownTime] = React.useState<boolean>(false)
  const [birthHour, setBirthHour] = React.useState(defaultDate.hour)
  const [birthMinute, setBirthMinute] = React.useState(defaultDate.minute)

  React.useEffect(() => {
    if (isUnknownTime) {
      setBirthHour(12)
      setBirthMinute(0)
    } else {
      setBirthHour(defaultDate.hour)
      setBirthMinute(defaultDate.minute)
    }
  }, [isUnknownTime])

  const handleIsUnknownTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = event
    setIsUnknownTime(target.checked)
  }

  const handleBirthHourChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { target } = event
    setBirthHour(Number(target.value))
  }

  const handleBirthMinuteChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { target } = event
    setBirthMinute(Number(target.value))
  }

  return (
    <>
      <div className='flex items-center gap-2'>
        <label className='font-semibold'>Unknown time:</label>
        <input
          type='checkbox'
          name='isUnknownTime'
          value={isUnknownTime ? 'on' : 'off'}
          onChange={handleIsUnknownTimeChange}
          data-index={index}
        />
      </div>

      <div
        className={`flex items-center gap-4 ${
          isUnknownTime ? 'opacity-50 pointer-events-none' : ''
        }`}
      >
        <div>
          <label className='font-semibold'>Birth Hour:</label>
          <select
            className='block p-2 rounded'
            name='birthHour'
            data-index={index}
            value={birthHour}
            onChange={handleBirthHourChange}
          >
            {Array.from(Array(24).keys()).map(hour => (
              <option value={hour} key={hour}>
                {padZero(hour)}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className='font-semibold'>Birth Minute:</label>
          <select
            className='block p-2 rounded'
            name='birthMinute'
            data-index={index}
            value={birthMinute}
            onChange={handleBirthMinuteChange}
          >
            {Array.from(Array(60).keys()).map(minute => (
              <option value={minute} key={minute}>
                {padZero(minute)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label className='font-semibold'>Birth Place:</label>
        <input
          className='block p-2 rounded'
          placeholder='e.g.: Resende, Rio de Janeiro, Brasil'
          type='text'
          name='birthPlace'
          data-index={index}
        />
      </div>

      <div>
        <label className='font-semibold'>Latitude:</label>
        <input
          className='block p-2 rounded'
          type='number'
          name='latitude'
          lang='en'
          step={0.00001}
          data-index={index}
        />
      </div>

      <div>
        <label className='font-semibold'>Longitude:</label>
        <input
          className='block p-2 rounded'
          type='number'
          name='longitude'
          lang='en'
          step={0.00001}
          data-index={index}
        />
      </div>

      <label className='font-semibold'>
        GMT Offset:
        <select
          name='gmtOffset'
          defaultValue='0'
          className='block p-2 rounded'
          data-index={index}
        >
          <option value='-12'>GMT -12:00</option>
          <option value='-11'>GMT -11:00</option>
          <option value='-10'>GMT -10:00</option>
          <option value='-9.5'>GMT -09:30</option>
          <option value='-9'>GMT -09:00</option>
          <option value='-8'>GMT -08:00</option>
          <option value='-7'>GMT -07:00</option>
          <option value='-6'>GMT -06:00</option>
          <option value='-5'>GMT -05:00</option>
          <option value='-4.5'>GMT -04:30</option>
          <option value='-4'>GMT -04:00</option>
          <option value='-3.5'>GMT -03:30</option>
          <option value='-3'>GMT -03:00</option>
          <option value='-2'>GMT -02:00</option>
          <option value='-1'>GMT -01:00</option>
          <option value='0'>GMT</option>
          <option value='1'>GMT +01:00</option>
          <option value='2'>GMT +02:00</option>
          <option value='3'>GMT +03:00</option>
          <option value='3.5'>GMT +03:30</option>
          <option value='4'>GMT +04:00</option>
          <option value='4.5'>GMT +04:30</option>
          <option value='5'>GMT +05:00</option>
          <option value='5.5'>GMT +05:30</option>
          <option value='5.75'>GMT +05:45</option>
          <option value='6'>GMT +06:00</option>
          <option value='6.5'>GMT +06:30</option>
          <option value='7'>GMT +07:00</option>
          <option value='8'>GMT +08:00</option>
          <option value='8.75'>GMT +08:45</option>
          <option value='9'>GMT +09:00</option>
          <option value='9.5'>GMT +09:30</option>
          <option value='10'>GMT +10:00</option>
          <option value='10.5'>GMT +10:30</option>
          <option value='11'>GMT +11:00</option>
          <option value='11.5'>GMT +11:30</option>
          <option value='12'>GMT +12:00</option>
          <option value='12.75'>GMT +12:45</option>
          <option value='13'>GMT +13:00</option>
          <option value='14'>GMT +14:00</option>
        </select>
      </label>

      <label className='font-semibold'>
        Foreground:
        <select
          name='foreground'
          className='block p-2 rounded'
          data-index={index}
        >
          <option value='silver'>Silver</option>
          <option value='black'>Black</option>
          <option value='gold'>Gold</option>
          <option value='rose-gold'>Rose Gold</option>
        </select>
      </label>

      <label className='font-semibold'>
        Background:
        <select
          name='background'
          className='block p-2 rounded'
          data-index={index}
        >
          <option value='black'>Black</option>
          <option value='silver'>Silver</option>
          <option value='gold'>Gold</option>
          <option value='rose-gold'>Rose Gold</option>
        </select>
      </label>

      <label className='font-semibold'>
        House System:
        <select
          name='houseSystem'
          className='block p-2 rounded'
          data-index={index}
        >
          <option value='placidus'>Placidus</option>
          <option value='equal'>Equal</option>
          <option value='whole_sign'>Whole Sign</option>
          <option value='porphyry'>Porphyry</option>
          <option value='koch'>Koch</option>
        </select>
      </label>

      <label className='font-semibold'>
        Zodiac System:
        <select
          name='zodiacSystem'
          className='block p-2 rounded'
          data-index={index}
        >
          <option value='tropical'>Tropical</option>
          <option value='sidereal'>Sidereal (Lahiri)</option>
        </select>
      </label>
    </>
  )
}

const FormContent: FC<FormContentProps> = ({
  index,
  isSingleItem,
  onRemove,
}) => {
  const [printType, setPrintType] =
    React.useState<Product.Jewelry.Talisman.PrintType>('birth_chart')
  const [jewelryType, setJewelryType] =
    React.useState<Product.Jewelry.Type>('necklace')
  const defaultDate = React.useMemo(DateTime.local, [])
  const defaultIsoDate = defaultDate.toISODate()

  React.useEffect(() => {
    if (printType === 'lunar') {
      setJewelryType('necklace')
    }
  }, [printType])

  const handleJewelryTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { target } = event
    setJewelryType(target.value as Product.Jewelry.Type)
  }

  const handlePrintTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { target } = event
    setPrintType(target.value as Product.Jewelry.Talisman.PrintType)
  }

  return (
    <div className='border-darkBlue first:border-none first:mt-0 first:pt-0 pt-4 mt-4 border-t'>
      <style>
        {`
          input, select {
            border: 1px solid #ccc;
          }
        `}
      </style>
      <div className='flex items-center gap-2'>
        <h1 className='text-2xl font-bold'>
          {_.startCase(printType)} {_.startCase(jewelryType)} #{index + 1}
        </h1>
        {!isSingleItem && '|'}
        <button
          className={`underline ${isSingleItem ? 'hidden' : ''}`}
          disabled={isSingleItem}
          onClick={onRemove}
        >
          Remove
        </button>
      </div>

      <div className='mt-8' />

      <div className='flex flex-1'>
        {/* Product Info */}
        <div className='w-full'>
          <h2 className='font-semibold text-gray-600'>Product Info</h2>

          <div className='mt-4' />

          <div className='flex flex-col gap-4'>
            <div>
              <label className='font-semibold'>Print Type:</label>
              <select
                className='block p-2 rounded'
                name='printType'
                data-index={index}
                onChange={handlePrintTypeChange}
              >
                {_.map(PRINT_TYPE, (value, key) => (
                  <option value={value} key={key} disabled={value === 'lunar'}>
                    {PRINT_TYPE_NAME_MAP[key]}
                  </option>
                ))}
              </select>
            </div>

            <div className={printType === 'lunar' ? 'opacity-50' : ''}>
              <label className='font-semibold'>Jewelry Type:</label>
              <select
                className='block p-2 rounded'
                name='jewelryType'
                value={printType === 'lunar' ? 'necklace' : undefined}
                disabled={
                  printType === 'lunar' || printType === 'synastry_chart'
                }
                data-index={index}
                onChange={handleJewelryTypeChange}
              >
                <option value='necklace'>Necklace</option>
                <option value='bracelet'>Bracelet</option>
              </select>
            </div>

            {(printType === 'birth_chart' || printType === 'lunar') && (
              <>
                <div>
                  <label className='font-semibold'>
                    Engraving (First Line):
                  </label>
                  <input
                    className='block p-2 rounded'
                    type='text'
                    name='engravingFirstLine'
                    data-index={index}
                  />
                </div>

                <div>
                  <label className='font-semibold'>
                    Engraving (Second Line):
                  </label>
                  <input
                    className='block p-2 rounded'
                    type='text'
                    name='engravingSecondLine'
                    data-index={index}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className='w-full'>
          {/* Birth Chart & Lunar talisman */}
          {printType === 'birth_chart' || printType === 'lunar' ? (
            <>
              {/* Astrological info */}
              <h2 className='font-semibold text-gray-600'>Astrological Info</h2>

              <div className='mt-4' />

              <div className='flex flex-col gap-4'>
                <div>
                  <label className='font-semibold'>Name on Report:</label>
                  <input
                    className='block p-2 rounded'
                    type='text'
                    name='customerName'
                    data-index={index}
                  />
                </div>

                <div>
                  <label className='font-semibold'>Birth date:</label>
                  <input
                    className='block p-2 rounded'
                    type='date'
                    name='isoBirthDate'
                    defaultValue={defaultIsoDate}
                    data-index={index}
                  />
                </div>

                {printType === 'birth_chart' ? (
                  <BirthChartTalismanFormContent
                    index={index}
                    defaultDate={defaultDate}
                  />
                ) : (
                  <LunarTalismanFormContent index={index} />
                )}
              </div>
            </>
          ) : null}

          {printType === 'synastry_chart' ? (
            <SynastryTalismanFormContent index={index} />
          ) : null}

          <input
            type='hidden'
            name='lineItemId'
            value={uuid()}
            data-index={index}
          />
          <input
            type='hidden'
            name='lineItemIndex'
            value={index}
            data-index={index}
          />
        </div>
      </div>
    </div>
  )
}

export const Create: FC<CreateProps> = ({}) => {
  const [itemCount, setItemCount] = React.useState(1)
  const [isCreating, setIsCreating] = React.useState(false)
  const { addToast } = useToasts()
  const productionContext = useProductionContext()
  const { getAccessTokenSilently } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)

  async function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    setIsCreating(true)

    const target = event.target as unknown as HTMLFormElement['target'] & {
      elements: (HTMLInputElement | HTMLSelectElement)[]
    }
    const elements = target.elements

    const formData = _.reduce(
      elements,
      (acc, element) => {
        const { name, value } = element
        if (!name || !value) {
          return acc
        }

        const dataIndex = element.getAttribute('data-index')
        const index = _.isNil(dataIndex) ? undefined : Number(dataIndex)

        // Shipping info
        if (_.isNil(index)) {
          return acc
        }

        return {
          ...acc,
          [index]: { ...acc[index], [name]: value },
        }
      },
      [] as AnyJewelryItemFormItem[]
    )
    const shippingFormData = _.filter(elements, element => {
      if (!element.name || !element.value) {
        return false
      }
      const dataIndex = element.getAttribute('data-index')
      return _.isNil(dataIndex)
    })
    const shippingAddress = _.reduce(
      shippingFormData,
      (acc, shippingFormDataItem) => {
        const { name, value } = shippingFormDataItem

        if (shippingFormDataItem.name === 'shippingName') {
          const { firstName, lastName } = getNameParticles(value)

          return { ...acc, firstName, lastName }
        }

        if (shippingFormDataItem.name === 'customerEmail') {
          return acc
        }

        return { ...acc, [name]: value }
      },
      {} as JewelryProduction.ShippingAddress
    )
    const email = _.find(
      elements,
      element => element.name === 'customerEmail'
    ).value

    const customerName = _.find(
      elements,
      element => element.name === 'customerName'
    )?.value
    const customerNameParticles = customerName
      ? getNameParticles(customerName)
      : {}
    const customer: JewelryProduction.Customer = {
      firstName: shippingAddress.firstName,
      lastName: shippingAddress.lastName,
      ...customerNameParticles,
      email,
    }

    const orderId = +new Date()
    const jewelryItems = createJewelryProductionItems(
      formData,
      shippingAddress,
      customer,
      orderId
    )
    const reportsBacklogItems = createReportsBacklogItems(
      formData,
      customer,
      orderId
    )

    const createJewelryItemsPromise = queryFulfillmentApi(
      '/jewelry-production/items/create',
      { jewelryItems },
      'POST'
    )

    const createReportsBacklogItemsPromise = !_.isEmpty(reportsBacklogItems)
      ? queryFulfillmentApi(
          '/reports-backlog/items/create',
          { reportsBacklogItems },
          'POST'
        )
      : Promise.resolve(null)

    try {
      await Promise.all([
        createJewelryItemsPromise,
        createReportsBacklogItemsPromise,
      ])

      addToast('Successfully created items', {
        appearance: 'success',
        autoDismiss: true,
      })

      const updatedJewelryItems = [
        ...(productionContext.jewelryItems ?? []),
        ...jewelryItems,
      ]
      productionContext.dispatch({
        type: ACTIONS.SET_JEWELRY_ITEMS,
        payload: { jewelryItems: updatedJewelryItems },
      })
    } catch (error) {
      addToast('Failed to create items', {
        appearance: 'error',
        autoDismiss: false,
      })
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <form
      method='post'
      className='flex flex-col gap-3'
      onSubmit={handleFormSubmit}
    >
      {_.range(itemCount).map(index => (
        <FormContent
          key={index}
          index={index}
          isSingleItem={itemCount === 1}
          onRemove={() => {
            setItemCount(itemCount - 1)
          }}
        />
      ))}

      {/* Shipping Info */}
      <div className='border-darkBlue w-full pt-4 border-t'>
        <h2 className='font-semibold text-gray-600'>Shipping Info</h2>

        <div className='mt-4' />

        <div className='flex flex-1'>
          <div className='flex flex-col w-full gap-3'>
            <div className='flex flex-col gap-4'>
              <div>
                <label className='font-semibold'>Customer Name:</label>
                <input
                  className='block p-2 rounded'
                  type='text'
                  name='shippingName'
                />
              </div>

              <div>
                <label className='font-semibold'>Email:</label>
                <input
                  className='block p-2 rounded'
                  type='text'
                  name='customerEmail'
                />
              </div>

              <div>
                <label className='font-semibold'>Address line 1:</label>
                <input
                  className='block p-2 rounded'
                  type='text'
                  name='address1'
                />
              </div>

              <div>
                <label className='font-semibold'>
                  Address line 2 (optional):
                </label>
                <input
                  className='block p-2 rounded'
                  type='text'
                  name='address2'
                />
              </div>
            </div>
          </div>

          <div className='flex flex-col w-full gap-3'>
            <div>
              <label className='font-semibold'>City</label>
              <input
                className='block p-2 rounded'
                placeholder='e.g.: Rio de Janeiro'
                type='text'
                name='city'
              />
            </div>

            <div>
              <label className='font-semibold'>State (optional):</label>
              <input
                className='block p-2 rounded'
                placeholder='e.g.: Rio de Janeiro'
                type='text'
                name='province'
              />
            </div>

            <div>
              <label className='font-semibold'>State Code (optional):</label>
              <input
                className='block p-2 rounded'
                placeholder='e.g.: RJ'
                type='text'
                name='provinceCode'
              />
            </div>
          </div>

          <div className='flex flex-col w-full gap-3'>
            <div>
              <label className='font-semibold'>Country:</label>
              <input
                className='block p-2 rounded'
                placeholder='e.g.: United States of America'
                type='text'
                name='country'
              />
            </div>

            <div>
              <label className='font-semibold'>Country Code:</label>
              <input
                className='block p-2 rounded'
                placeholder='e.g.: US'
                type='text'
                name='countryCode'
              />
            </div>

            <div>
              <label className='font-semibold'>Postal Code:</label>
              <input
                className='block p-2 rounded'
                type='text'
                name='postalCode'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='flex justify-center'>
        <a
          className='p-4 cursor-pointer'
          onClick={() => {
            setItemCount(itemCount + 1)
          }}
        >
          <div className='flex items-center gap-2'>
            <span className='text-lg font-bold'>+</span>
            <span className='underline'>Add another item</span>
          </div>
        </a>
      </div>

      <button
        type='submit'
        className='p-4 bg-darkBlue text-[snow] font-semibold text-lg mt-4'
      >
        {isCreating ? 'Creating...' : 'Create'}
      </button>
    </form>
  )
}
