import type { RouteComponent } from '@ephemeris/types/src/reach-router'

import React, { FC, useState, useMemo } from 'react'
import { Router, RouteComponentProps, Link } from '@reach/router'
import { isNil, orderBy, uniqBy } from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { useTable, Column } from 'react-table'
import { DateTime } from 'luxon'
import { navigate } from 'gatsby'
import { useToasts } from 'react-toast-notifications'
import useKeyPress from 'react-use-keypress'

import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import Loader from '@ephemeris/react-components/src/Loader'

import JewelryItem from './JewelryItem'
import CreateJewelryItem from './Create'
import StatusLabel from '../../StatusLabel'
import useProductionContext from '../useProductionContext'
import { ACTIONS } from '../ProductionProvider/Store'

function getCellDisplayValueTransformer(
  accessor: Column<JewelryProduction.Item>['accessor']
): (value: any) => JSX.Element {
  return (value: any): JSX.Element => {
    switch (accessor) {
      case 'customer': {
        const { firstName, lastName } = value as JewelryProduction.Customer
        return (
          <>
            {firstName} {lastName}
          </>
        )
      }
      case 'orderCreatedAt':
        return (
          <>{DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)}</>
        )
      case 'status':
        return <StatusLabel status={value as JewelryProduction.Status} />
      default:
        return <>{`${value}`}</>
    }
  }
}

const CreateJewelryItemModal: FC<RouteComponent> = () => {
  useKeyPress('Escape', () => {
    navigate('../')
  })

  return (
    <div className='fixed inset-0 z-10 p-8 bg-darkBlue bg-opacity-30'>
      <div className='w-full h-full rounded-lg bg-[snow] shadow-lg p-6 overflow-scroll'>
        <header className='flex items-center justify-between'>
          <div className='w-px h-full' />
          <h1 className='text-2xl font-bold'>Create Jewelry Item</h1>
          <Link to='../' className='underline'>
            Close
          </Link>
        </header>
        <div className='mt-4' />
        <CreateJewelryItem />
      </div>
    </div>
  )
}

export const JewelryItems: FC<RouteComponentProps> = ({}) => {
  const [isRefreshing, setIsRefreshing] = useState(false)
  const { jewelryItems, jewelryItemsInitialLastEvaluatedKey, dispatch } =
    useProductionContext()
  const [lastEvaluatedKey, setLastEvaluatedKey] =
    React.useState<Dictionary<any>>()
  const { getAccessTokenSilently } = useAuth0()
  const { addToast } = useToasts()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)

  React.useEffect(() => {
    if (!isNil(jewelryItemsInitialLastEvaluatedKey)) {
      setLastEvaluatedKey(jewelryItemsInitialLastEvaluatedKey)
    }
  }, [jewelryItemsInitialLastEvaluatedKey])

  async function refresh() {
    setIsRefreshing(true)

    try {
      const response = await queryFulfillmentApi(
        'jewelry-production/items',
        {
          lastEvaluatedKey,
        },
        'POST'
      )
      const {
        jewelryItems: newJewelryItems,
        lastEvaluatedKey: newLastEvaluatedKey,
      } = (await response.json()) as {
        jewelryItems: JewelryProduction.Item[]
        lastEvaluatedKey: Dictionary<any>
      }

      const aggregatedJewelryItems = uniqBy(
        [...jewelryItems, ...newJewelryItems],
        'id'
      )
      const orderedJewelryItems = orderBy(
        aggregatedJewelryItems,
        'orderCreatedAt',
        'desc'
      )

      console.log(`--- number of items:`, orderedJewelryItems.length)

      dispatch({
        type: ACTIONS.SET_JEWELRY_ITEMS,
        payload: { jewelryItems: orderedJewelryItems },
      })
      setLastEvaluatedKey(newLastEvaluatedKey)

      addToast('Successfully refreshed', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(`Refresh failed. Error: ${error.message}`, {
        appearance: 'error',
      })
    } finally {
      setIsRefreshing(false)
    }
  }

  const columns = useMemo<Column<JewelryProduction.Item>[]>(
    () => [
      { Header: 'Order', accessor: 'uniqueOrderName' },
      { Header: 'Customer', accessor: 'customer' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Date', accessor: 'orderCreatedAt' },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: jewelryItems ?? [] })

  const Table: FC<RouteComponentProps> = () => (
    <div className='w-full'>
      <div className='sticky top-0 z-10 flex items-center justify-between h-16 p-4 border-b-2 bg-gray-50 border-lightGold'>
        <button
          className={`
              focus:outline-none flex items-center
              ${isRefreshing ? 'no-underline' : 'underline'}
            `}
          onClick={refresh}
          disabled={isRefreshing}
        >
          {isRefreshing && (
            <div className='mr-2'>
              <Loader style='dark' />
            </div>
          )}
          Refresh{isRefreshing ? 'ing' : ''}
        </button>
        <h1 className='text-2xl font-bold'>Jewelry Items</h1>
        <div>
          <Link to='create' className='underline'>
            Create +
          </Link>
        </div>
      </div>
      {isNil(jewelryItems) ? (
        <div className='flex justify-center w-full mt-12'>
          <Loader style='dark' />
        </div>
      ) : (
        <div className='w-full'>
          <table {...getTableProps()} className={`w-full`}>
            <thead className='sticky border-b-2 shadow-md top-16 border-blueGray-700'>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    return (
                      <th
                        className='p-4 font-bold cursor-pointer bg-gray-50 text-darkBlue'
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    )
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row)

                const jewelryItem = jewelryItems[rowIndex]
                const rowBackgroundColor =
                  rowIndex % 2 === 0 ? 'bg-coolGray-100' : 'bg-coolGray-300'

                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => {
                      navigate(`/production/item/${jewelryItem.id}`, {
                        state: { jewelryItem },
                      })
                    }}
                    className={`cursor-pointer hover:bg-opacity-75 ${rowBackgroundColor}`}
                  >
                    {row.cells.map((cell, columnIndex: number) => {
                      const { accessor } = columns[columnIndex] as {
                        accessor: keyof JewelryProduction.Item
                      }
                      const value = jewelryItem[accessor]
                      const transformDisplayValue =
                        getCellDisplayValueTransformer(accessor)
                      const displayValue = transformDisplayValue(value)

                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`
                      text-ephemerisBlue
                      ${accessor === 'status' ? 'p-0' : 'p-4'}
                    `}
                        >
                          {displayValue}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <div className='flex justify-center p-4 '>
            <button className='underline' onClick={refresh}>
              Load more
            </button>
          </div>
        </div>
      )}
      <Router>
        <CreateJewelryItemModal path='create' />
      </Router>
    </div>
  )

  return (
    <Router>
      <Table path='/*' />
      <JewelryItem path='item/:jewelryItemId/*' />
    </Router>
  )
}
