export function isEither<T>(targetValue: T, allowedValues: T[]): boolean {
  for (const allowedValue of allowedValues) {
    if (targetValue === allowedValue) {
      return true
    }
  }

  return false
}

export function isNeither<T>(targetValue: T, disallowedValues: T[]): boolean {
  for (const allowedValue of disallowedValues) {
    if (targetValue === allowedValue) {
      return false
    }
  }

  return true
}

export function areEqual(...items: any[]): boolean {
  return items.every(item => item === items[0])
}
