import { capitalize } from './string'

export function getFullName(
  birthInfo: Pick<BirthInfo, 'firstName' | 'lastName'>
): string {
  const { firstName: unsanitizedFirstName, lastName: unsanitizedLastName } =
    birthInfo

  const [firstName, lastName] = [unsanitizedFirstName, unsanitizedLastName].map(
    name => (name ?? '').trim()
  )

  return `${firstName} ${lastName}`.trim()
}

export function getNameParticles(fullName: string): {
  firstName: string
  lastName: string
} {
  const names = fullName.split(/ /g)
  const capitalizedNames = names.map(capitalize)
  const [firstName, ...lastNames] = capitalizedNames
  const lastName = lastNames.join(' ')

  return { firstName, lastName }
}
