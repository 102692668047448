import type { RouteComponent } from '@ephemeris/types/src/reach-router'

import React, { FC, useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  capitalize,
  filter,
  findIndex,
  isEmpty,
  isNil,
  isNull,
  map,
  some,
} from 'lodash'
import { DateTime } from 'luxon'
import useKeypress from 'react-use-keypress'

import {
  createEmptyPreviewBatch,
  createPreviewBatch,
  sortBatchJewelryItems,
  isBirthChartJewelryItem,
  isMoonJewelryItem,
} from '@ephemeris/utils/src/jewelry-production'
import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import Loader from '@ephemeris/react-components/src/Loader'

import Batch from '../Batch'
import StatusLabel from '../../../StatusLabel'
import JewelryItemThumbnail from '../../../JewelryItemThumbnail'
import useProductionContext from '../../useProductionContext'

type CreateProps = RouteComponent<{ batch?: JewelryProduction.PreviewBatch }>

interface SearchResultsProps {
  batch?: JewelryProduction.PreviewBatch
  jewelryItems: JewelryProduction.AnyItem[]
  onAddItemToBatch: (jewelryItem: JewelryProduction.Item) => void
  onRemoveItemFromBatch: (jewelryItem: JewelryProduction.Item) => void
}

function removeJewelryItemAtIndex(
  jewelryItems: (JewelryProduction.Item | null)[],
  index: number
) {
  let jewelryItemsCopy = [...jewelryItems]

  jewelryItemsCopy[index] = null

  return jewelryItemsCopy
}

function insertJewelryItemAtIndex(
  jewelryItems: (JewelryProduction.Item | null)[],
  jewelryItem: JewelryProduction.Item,
  index: number
) {
  let jewelryItemsCopy = [...jewelryItems]

  jewelryItemsCopy[index] = jewelryItem

  return jewelryItemsCopy
}

const SearchResults: FC<SearchResultsProps> = ({
  batch,
  jewelryItems,
  onAddItemToBatch: handleAddItemToBatch,
  onRemoveItemFromBatch: handleRemoveItemFromBatch,
}) => {
  if (isNil(batch)) {
    return <Loader style='dark' />
  }

  return (
    <div id='search-results-content'>
      {map(jewelryItems, (jewelryItem, index) => {
        const isJewelryItemInBatch = some(
          batch.jewelryItems,
          batchJewelryItem =>
            isNull(batchJewelryItem)
              ? false
              : batchJewelryItem.id === jewelryItem.id
        )

        return (
          <div
            key={jewelryItem.id}
            className='border-gray-200 border-b-1'
            id={`search-result-${jewelryItem.id}`}
          >
            {index > 0 && <div className='w-full h-px my-2 bg-gray-200' />}
            <div
              className={`flex flex-col p-2 rounded-md hover:bg-coolGray-100`}
            >
              <div className='flex items-center'>
                <div className={`w-24`}>
                  <JewelryItemThumbnail jewelryItem={jewelryItem} />
                </div>
                <div className='ml-4'>
                  <h3 className='text-lg font-bold'>
                    {jewelryItem.uniqueOrderName}
                  </h3>
                  <div className='text-xs font-light'>
                    Placed by{' '}
                    {`${jewelryItem.customer.firstName} ${jewelryItem.customer.lastName}`}{' '}
                    on{' '}
                    {DateTime.fromISO(
                      jewelryItem.orderCreatedAt
                    ).toLocaleString(DateTime.DATETIME_MED)}
                  </div>

                  <div className='mt-2' />

                  <div className='flex items-center text-sm'>
                    <span className='font-semibold'>Status</span>:{' '}
                    <div className='ml-1'>
                      <StatusLabel jewelryItem={jewelryItem} />
                    </div>
                  </div>
                  {isBirthChartJewelryItem(jewelryItem) &&
                    (() => {
                      const {
                        customizationInfo: {
                          theme: { foreground, background },
                        },
                      } = jewelryItem
                      return (
                        <div className='text-sm'>
                          <span className='font-semibold '>Colors</span>:{' '}
                          {`${capitalize(foreground)}/${capitalize(
                            background
                          )}`}
                        </div>
                      )
                    })()}

                  <div>
                    <button
                      className='underline outline-none'
                      onClick={() => {
                        const action = isJewelryItemInBatch
                          ? handleRemoveItemFromBatch
                          : handleAddItemToBatch
                        action(jewelryItem)
                      }}
                    >
                      {isJewelryItemInBatch
                        ? 'Remove from batch'
                        : 'Add to batch'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const Create: FC<CreateProps> = ({ location }) => {
  const [batch, setBatch] = useState<
    JewelryProduction.PreviewBatch | undefined
  >(location?.state?.batch)
  const [searchResults, setSearchResults] = useState<JewelryProduction.Item[]>(
    []
  )
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [nextBatchNumber, setNextBatchNumber] = useState<number>()
  const [windowHeight, setWindowHeight] = useState(0)
  const [searchResultsHeight, setSearchResultsHeight] = useState(0)
  const { getAccessTokenSilently } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)
  const { jewelryItems } = useProductionContext()

  useEffect(() => {
    async function fetchNextBatchNumber() {
      const response = await queryFulfillmentApi(
        '/jewelry-production/batches/next-batch-number'
      )
      const responseBody = await response.json()
      const { batchNumber } = responseBody as { batchNumber: number }

      setNextBatchNumber(batchNumber)
    }

    fetchNextBatchNumber()
  }, [])

  useEffect(() => {
    if (isNil(nextBatchNumber)) {
      return
    }

    console.log(`--- next batch:`, nextBatchNumber)

    const newEmptyOrUpdatedBatch = isNil(batch)
      ? createEmptyPreviewBatch(nextBatchNumber)
      : createPreviewBatch(batch.jewelryItems, nextBatchNumber).batch

    setBatch(newEmptyOrUpdatedBatch)
  }, [nextBatchNumber])

  useLayoutEffect(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useEffect(() => {
    const searchResultsElement = document.querySelector(
      '#search-results-content'
    )

    setSearchResultsHeight(searchResultsElement?.scrollHeight ?? 0)
  }, [searchResults])

  useKeypress('Escape', () => {
    setSearchResults([])
    searchInputRef.current?.blur()
  })

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {
      target: { value },
    } = event

    const searchTerm = value.toLowerCase()

    if (isNil(jewelryItems)) {
      return
    }

    if (isEmpty(searchTerm)) {
      setSearchResults([])
      return
    }

    const searchResults = filter(
      jewelryItems,
      ({ orderId, orderName, customer, shippingAddress, status }) => {
        return (
          status !== 'Suspended' &&
          (orderId.startsWith(searchTerm) ||
            (orderName ?? '').toLowerCase().startsWith(searchTerm) ||
            `#${orderName ?? ''}`.toLowerCase().startsWith(searchTerm) ||
            customer.firstName.toLowerCase().startsWith(searchTerm) ||
            customer.lastName.toLowerCase().startsWith(searchTerm) ||
            shippingAddress.firstName.toLowerCase().startsWith(searchTerm) ||
            (shippingAddress.lastName &&
              shippingAddress.lastName.toLowerCase().startsWith(searchTerm)) ||
            shippingAddress.city.toLowerCase().startsWith(searchTerm) ||
            customer.email?.toLowerCase().startsWith(searchTerm))
        )
      }
    )

    setSearchResults(searchResults)
  }

  function removeItemFromBatch(jewelryItem: JewelryProduction.Item) {
    if (isNil(batch)) {
      return
    }

    const itemIndex = findIndex(batch.jewelryItems, batchJewelryItem =>
      isNull(batchJewelryItem) ? false : batchJewelryItem.id === jewelryItem.id
    )

    if (itemIndex < 0) {
      return
    }

    const updatedJewelryItems = removeJewelryItemAtIndex(
      batch.jewelryItems,
      itemIndex
    )
    const regroupedBatch = sortBatchJewelryItems({
      ...batch,
      jewelryItems: updatedJewelryItems,
    })

    setBatch(regroupedBatch)
  }

  function addItemToBatch(jewelryItem: JewelryProduction.Item) {
    if (isNil(batch)) {
      return
    }

    const nextAvailableSlot = findIndex(batch.jewelryItems, isNull)

    if (nextAvailableSlot < 0) {
      console.log(`--- no more empty slots!`)
      return
    }

    const updatedJewelryItems = insertJewelryItemAtIndex(
      batch.jewelryItems,
      jewelryItem,
      nextAvailableSlot
    )
    const regroupedBatch = sortBatchJewelryItems({
      ...batch,
      jewelryItems: updatedJewelryItems,
    })

    setBatch(regroupedBatch)
    setSearchResults([])
  }

  return (
    <div className='h-full py-8 bg-gray-50'>
      <div className='flex flex-col'>
        <div className='flex-grow-0 px-12'>
          <input
            type='text'
            placeholder='Search for order, customer, etc'
            className='w-full p-4 rounded-md shadow-xl outline-none border-coolGray-200 focus:border'
            ref={searchInputRef}
            autoFocus={true}
            onChange={handleSearchChange}
            onFocus={handleSearchChange}
          />
          {!isEmpty(searchResults) && (
            <div className='relative'>
              <div
                className='absolute inset-0 z-20 p-4 overflow-scroll bg-white rounded-md shadow-lg top-2'
                style={{
                  maxHeight: windowHeight * 0.85,
                  height: searchResultsHeight + searchResultsHeight * 0.15,
                }}
              >
                <SearchResults
                  batch={batch}
                  jewelryItems={searchResults}
                  onAddItemToBatch={addItemToBatch}
                  onRemoveItemFromBatch={removeItemFromBatch}
                />
              </div>
            </div>
          )}
        </div>
        <div className='flex-grow'>
          {isNil(batch) ? (
            <div className='flex flex-col items-center justify-center mt-8'>
              <Loader style='dark' />
            </div>
          ) : (
            <Batch
              key={JSON.stringify(batch)}
              batch={batch}
              isCreatingBatchManually={true}
              onRemoveItemFromBatch={removeItemFromBatch}
            />
          )}
        </div>
      </div>
    </div>
  )
}
