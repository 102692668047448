import React from 'react'

import Button, { ButtonComposites, ButtonProps } from './Button'

import type { FC } from 'react'

export interface ActionMenuProps {}
export interface ActionMenuComposites {
  Button: FC<ButtonProps> & ButtonComposites
}

export const ActionMenu: FC<ActionMenuProps> & ActionMenuComposites = ({
  children,
}) => {
  return <>{children}</>
}

ActionMenu.Button = Button
