export const MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE = 20
export const MAX_BATCH_SIZE = 18
export const ORDERED_BATCH_PRODUCTION_STATUSES: JewelryProduction.BatchStatus[] =
  ['ReadyForProduction', 'Engrave', 'Print', 'Resin', 'Pack', 'Post']
export const WHITE_PIXEL_BASE_64_JPG_IMAGE =
  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAABAAEDAREAAhEBAxEB/8QAFAABAAAAAAAAAAAAAAAAAAAACv/EABQQAQAAAAAAAAAAAAAAAAAAAAD/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8AfwD/2Q=='
export const JIG_SIZE = { width: 4700, height: 3292 }
export const JIG_SLOT_SIZE = { width: 600, height: 600 }
export const TALISMAN_JIG_SLOTS_COORDINATES: Geometry.Point[] = [
  // First row
  { x: 313, y: 331 },
  { x: 1043, y: 329 },
  { x: 1775, y: 324 },
  { x: 2502, y: 321 },
  { x: 3236, y: 317 },
  { x: 3966, y: 317 },

  // Second row
  { x: 315, y: 1261 },
  { x: 1046, y: 1258 },
  { x: 1778, y: 1256 },
  { x: 2506, y: 1253 },
  { x: 3245, y: 1253 },
  { x: 3966, y: 1247 },

  // Third row
  { x: 317, y: 2196 },
  { x: 1049, y: 2196 },
  { x: 1781, y: 2190 },
  { x: 2512, y: 2184 },
  { x: 3243, y: 2183 },
  { x: 3976, y: 2181 },
]
export const ENGRAVING_JIG_SLOTS_COORDINATES: Geometry.Point[] = [
  // First row
  { x: 325, y: 337 },
  { x: 1057, y: 333 },
  { x: 1791, y: 331 },
  { x: 2521, y: 331 },
  { x: 3251, y: 329 },
  { x: 3983, y: 329 },

  // Second row
  { x: 325, y: 1267 },
  { x: 1057, y: 1265 },
  { x: 1787, y: 1263 },
  { x: 2523, y: 1263 },
  { x: 3251, y: 1261 },
  { x: 3983, y: 1261 },

  // Third row
  { x: 323, y: 2197 },
  { x: 1055, y: 2199 },
  { x: 1789, y: 2195 },
  { x: 2523, y: 2195 },
  { x: 3251, y: 2193 },
  { x: 3983, y: 2191 },
]
export const QR_CODES_FRAME_SIZE = { width: 3300, height: 2550 }
export const QR_CODE_SIZE = { width: 410, height: 410 }
export const QR_CODES_COORDINATES: Geometry.Point[] = [
  // First row
  { x: 170, y: 254 },
  { x: 680, y: 254 },
  { x: 1190, y: 254 },
  { x: 1700, y: 254 },
  { x: 2210, y: 254 },
  { x: 2720, y: 254 },

  //Second row
  { x: 170, y: 798 },
  { x: 680, y: 798 },
  { x: 1190, y: 798 },
  { x: 1700, y: 798 },
  { x: 2210, y: 798 },
  { x: 2720, y: 798 },

  // Third row
  { x: 170, y: 1342 },
  { x: 680, y: 1342 },
  { x: 1190, y: 1342 },
  { x: 1700, y: 1342 },
  { x: 2210, y: 1342 },
  { x: 2720, y: 1342 },
]
