import React, { useEffect, useState, FC } from 'react'
import { isNil } from 'lodash'
import { useToasts } from 'react-toast-notifications'
import { RouteComponentProps } from '@reach/router'
import { HiX } from 'react-icons/hi'

import { useReportsBacklogApi } from '@ephemeris/fulfillment-api/src/useReportsBacklogApi'
import Loader from '@ephemeris/react-components/src/Loader'

import ReportsBacklogTable from '../ReportsBacklogTable'
import TableItemDetail from '../TableItemDetail'

const {
  getReportsBacklogItems,
  sendReports: sendReportsApi,
  triggerGenerateReport,
  getReportsBacklogItemsByOrderId,
} = useReportsBacklogApi()

export const ReportsBacklog: FC<RouteComponentProps> = () => {
  async function sendReports({
    groupId,
    destinationEmail,
    downloadUrl,
  }: ReportsBacklog.DeliveryInfo): Promise<void> {
    if (isNil(backlogItems)) {
      return
    }

    try {
      const updatedBacklogItems = await sendReportsApi({
        groupId,
        destinationEmail,
        downloadUrl,
      })
      const backlogItemsNotInGroup = backlogItems.filter(
        backlogItem => backlogItem.groupId !== groupId
      )

      console.log(
        `--- did send email and updated backlogItems:`,
        updatedBacklogItems
      )

      setBacklogItems([...updatedBacklogItems, ...backlogItemsNotInGroup])
    } catch ({ error, message }) {
      console.log(`--- ${message}. error:`, error)
    }
  }

  const [backlogItems, setBacklogItems] =
    useState<ReportsBacklog.Item[]>(undefined)
  const [searchItems, setSearchItems] = useState<ReportsBacklog.Item[]>([])
  useEffect(() => {
    updateBacklogItems()

    window.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        setSelectedBacklogGroup(null)
      }
    })
  }, [])

  const { addToast } = useToasts()
  const [lastEvaluatedKey, setLastEvaluatedKey] =
    useState<Dictionary<string>>(null)
  const [selectedBacklogGroup, setSelectedBacklogGroup] =
    useState<Optional<ReportsBacklog.Group>>(undefined)
  const [searchTerm, setSearchTerm] = React.useState<string>(null)
  const searchInputRef = React.useRef<HTMLInputElement>(null)

  const [isRefreshing, setIsRefreshing] = useState(false)

  const handleChangeSearchTerm = setSearchTerm

  const handleSearch = async () => {
    if (!searchTerm) {
      setSearchItems([])
      return
    }

    const searchItems = await getReportsBacklogItemsByOrderId(
      Number(searchTerm)
    )

    setSearchItems(searchItems)
  }

  const handleClearSearch = () => {
    setSearchItems([])
    setSearchTerm(null)

    if (searchInputRef.current) {
      searchInputRef.current.value = ''
    }
  }

  async function updateBacklogItems(
    lastEvaluatedKey?: Dictionary<any>
  ): Promise<void> {
    const response = await getReportsBacklogItems(lastEvaluatedKey)

    setLastEvaluatedKey(response.lastEvaluatedKey)

    if (isNil(response.backlogItems)) {
      return
    }

    setBacklogItems(response.backlogItems)
  }

  async function refresh(): Promise<void> {
    try {
      setIsRefreshing(true)
      await updateBacklogItems()
      addToast('The info was refreshed', {
        autoDismiss: true,
        appearance: 'info',
      })
    } catch {
      addToast('Error trying to refresh data', {
        autoDismiss: true,
        appearance: 'error',
      })
    } finally {
      setIsRefreshing(false)
    }
  }

  async function generateReport(
    backlogItem: ReportsBacklog.Item
  ): Promise<void> {
    console.log(`--- will generate report:`, backlogItem)
    try {
      await triggerGenerateReport(backlogItem)
      addToast('The report is being generated', {
        appearance: 'info',
        autoDismiss: true,
      })
    } catch (error) {
      console.log(`--- could not generate report. Error:`, error)
      addToast('Failed to generate report', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  async function handleLoadNextPage() {
    const response = await getReportsBacklogItems(lastEvaluatedKey)

    setLastEvaluatedKey(response.lastEvaluatedKey)

    if (isNil(response.backlogItems)) {
      return
    }

    setBacklogItems([...backlogItems, ...response.backlogItems])
    console.log(`--- did load next page:`, backlogItems, backlogItems.length)
  }

  return (
    <>
      {selectedBacklogGroup && (
        <TableItemDetail
          group={selectedBacklogGroup}
          onClose={(): void => setSelectedBacklogGroup(null)}
          onSendReports={sendReports}
          onTriggerGenerateReport={generateReport}
        />
      )}
      <div className='bg-darkBlue p-4'>
        <div className='relative'>
          <button
            className={`
              absolute top-0 left-0 focus:outline-none flex items-center text-gray-50
              ${isRefreshing ? 'no-underline' : 'underline'}
            `}
            onClick={refresh}
            disabled={isRefreshing}
          >
            {isRefreshing && (
              <div className='mr-2'>
                <Loader />
              </div>
            )}
            Refresh{isRefreshing ? 'ing' : ''}
          </button>
        </div>
        <div className='flex items-center justify-center w-full p-4'>
          <h1 className='text-bold text-gray-50 text-xl'>Reports</h1>
        </div>
      </div>
      {isNil(backlogItems) ? (
        <div className='flex justify-center w-full pt-12'>
          <Loader style='dark' />
        </div>
      ) : (
        <>
          <div className='flex items-center w-full p-4 overflow-hidden rounded'>
            <div className='relative flex bg-white'>
              <input
                type='text'
                placeholder='order id only'
                className='h-11 px-2 mr-4'
                onInput={e => handleChangeSearchTerm(e.currentTarget.value)}
                ref={searchInputRef}
              />
              <button
                className='text-ephemerisBlue w-4 mr-4'
                onClick={handleClearSearch}
              >
                <HiX />
              </button>
            </div>
            <button
              className='border-ephemerisBlue p-2 border-2 border-solid rounded'
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
          <ReportsBacklogTable
            key={`${backlogItems.length}`}
            searchItems={searchItems}
            backlogItems={backlogItems}
            onRowClick={setSelectedBacklogGroup}
            onLoadNextPage={handleLoadNextPage}
          />
        </>
      )}
    </>
  )
}
