import type { RouteComponent } from '@ephemeris/types/src/reach-router'

import React, {
  FC,
  EffectCallback,
  DependencyList,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import {
  isNil,
  capitalize,
  map,
  range,
  padStart,
  isEmpty,
  debounce,
  startCase,
} from 'lodash'
import { Redirect, navigate } from '@reach/router'
import { DateTime } from 'luxon'
import { useForm, useWatch } from 'react-hook-form'
import { useAuth0 } from '@auth0/auth0-react'

import { getDateTime, getGmtOffset } from '@ephemeris/utils/src/DateTime'
import { decodeBase64, encodeBase64 } from '@ephemeris/utils/src/buffer'
import { safeParseJson } from '@ephemeris/utils/src/json'
import {
  isBirthChartJewelryItem,
  isMoonJewelryItem,
  isSynastryJewelryItem,
} from '@ephemeris/utils/src/jewelry-production'
import { MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE } from '@ephemeris/constants/src/fulfillment/jewelry-production'
import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import Loader from '@ephemeris/react-components/src/Loader'
import { useModal } from '../../../../Modal'
import useProductionContext from '../../../useProductionContext'
import { ACTIONS } from '../../../ProductionProvider/Store'

type EditProps = RouteComponent<{ jewelryItem: JewelryProduction.AnyItem }>

const Input: FC<{
  label: string
}> = ({ label, children }) => {
  return (
    <div className='flex flex-col gap-2'>
      <label className='font-bold'>{label}</label>
      <div className='max-w-sm'>
        {React.Children.map(children, (child: React.ReactElement) => {
          if (child.type === 'input' || child.type === 'select') {
            return React.cloneElement(child, {
              className: `${child.props.className} p-2 rounded border-2 border-lightGold`,
            })
          }

          return child
        })}
      </div>
    </div>
  )
}

function useDidUpdateEffect(callback: EffectCallback, inputs: DependencyList) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true
      return
    }

    callback()
  }, inputs)
}

const fetchGmtOffset = debounce(
  async ({
    birthInfo,
    setGmtOffset,
    setIsFetchingGmtOffset,
  }: {
    birthInfo: BirthInfo
    setGmtOffset: React.Dispatch<React.SetStateAction<number | undefined>>
    setIsFetchingGmtOffset: React.Dispatch<React.SetStateAction<boolean>>
  }) => {
    setIsFetchingGmtOffset(true)

    try {
      const gmtOffset = await getGmtOffset(birthInfo as BirthInfo)
      console.log(`--- got gmt offset:`, gmtOffset)
      setGmtOffset(gmtOffset)
    } catch (error) {
      console.error(`--- could not get gmt offset: `, error)
    } finally {
      setIsFetchingGmtOffset(false)
    }
  },
  500
)

const EditBirthChartJewelryItem: FC<{
  jewelryItem: JewelryProduction.BirthChart.Item
}> = ({ jewelryItem }) => {
  const defaultBirthInfo = useMemo(
    () => ({
      date: {},
      location: {},
    }),
    []
  )
  const {
    customizationInfo: {
      birthInfo = defaultBirthInfo,
      theme: { background, foreground },
      engraving,
    },
  } = jewelryItem
  const { firstLine, secondLine } = engraving ?? {}
  const {
    date: { hour, minute, gmtOffset: birthGmtOffset },
    location: { latitude, longitude, name: birthLocationName },
  } = birthInfo
  const birthDate = (() => {
    try {
      return getDateTime(birthInfo as BirthInfo)
    } catch {
      return undefined
    }
  })()
  const isoBirthDate = birthDate?.toISODate()
  const jewelryColors: JewelryProduction.JewelryColor[] = useMemo(
    () => ['black', 'gold', 'silver', 'rose-gold'],
    []
  )
  const { dispatch } = useProductionContext()
  const { getAccessTokenSilently } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)
  const [isFetchingGmtOffset, setIsFetchingGmtOffset] = useState(false)
  const [isUpdatingJewelryItem, setIsUpdatingJewelryItem] = useState(false)
  const [gmtOffset, setGmtOffset] = useState<number | undefined>(birthGmtOffset)
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      birthDate: isoBirthDate,
      birthHour: hour,
      birthMinute: minute,
      gmtOffset,
      foreground,
      background,
      firstLine,
      secondLine,
      latitude,
      longitude,
      birthLocationName,
    },
  })
  const formValues = useWatch({ control })

  useDidUpdateEffect(() => {
    const { birthDate, birthHour, birthMinute, latitude, longitude } =
      formValues
    const [year, month, day] = isNil(birthDate)
      ? []
      : birthDate.split('-').map(dateComponent => Number(dateComponent))
    const birthInfo = {
      date: { year, month, day, hour: birthHour, minute: birthMinute },
      location: { latitude, longitude },
    }

    fetchGmtOffset({
      birthInfo: birthInfo as BirthInfo,
      setGmtOffset,
      setIsFetchingGmtOffset,
    })
  }, [
    formValues.birthDate,
    formValues.birthHour,
    formValues.birthMinute,
    formValues.latitude,
    formValues.longitude,
  ])

  useEffect(() => {
    setValue('gmtOffset', gmtOffset)
  }, [gmtOffset])

  const { showModal, dismissModal } = useModal()

  const onSubmit = async data => {
    const {
      birthDate,
      birthHour: hour,
      birthMinute: minute,
      latitude,
      longitude,
      gmtOffset,
      foreground,
      background,
      firstLine,
      secondLine,
    } = data
    const [year, month, day] = birthDate?.split('-').map(Number)

    if (foreground === background) {
      showModal({
        title: 'Please, review the info',
        message: 'Details and background colors must be different',
        body: (
          <button className='text-primary mt-2' onClick={dismissModal}>
            Ok
          </button>
        ),
      })
      return
    }

    const timestamp = +new Date()
    const updatedJewelryItem: FullyPartial<JewelryProduction.BirthChart.Item> =
      {
        ...jewelryItem,
        renders: {
          ...jewelryItem.renders,
          front: jewelryItem.renders?.front
            ? `${jewelryItem.renders.front}&t=${timestamp}`
            : undefined,
          back: jewelryItem.renders?.back
            ? `${jewelryItem.renders.back}&t=${timestamp}`
            : undefined,
        },
        customizationInfo: {
          ...jewelryItem.customizationInfo,
          birthInfo: {
            ...jewelryItem.customizationInfo.birthInfo,
            date: {
              ...jewelryItem.customizationInfo?.birthInfo?.date,
              year,
              month,
              day,
              hour,
              minute,
              gmtOffset,
            },
            location: {
              ...jewelryItem.customizationInfo?.birthInfo?.location,
              latitude,
              longitude,
            },
          },
          theme: {
            ...jewelryItem.customizationInfo?.theme,
            foreground,
            background,
          },
          engraving: {
            ...jewelryItem.customizationInfo?.engraving,
            firstLine: isEmpty(firstLine) ? undefined : firstLine,
            secondLine: isEmpty(secondLine) ? undefined : secondLine,
          },
        },
      }

    setIsUpdatingJewelryItem(true)

    try {
      const response = await queryFulfillmentApi(
        '/jewelry-production/item',
        { jewelryItem: updatedJewelryItem },
        'PUT'
      )

      const { jewelryItem } = (await response.json()) as {
        jewelryItem: JewelryProduction.Item
      }

      dispatch({
        type: ACTIONS.UPDATE_JEWELRY_ITEM,
        payload: { jewelryItem },
      })
      navigate(`/production/item/${jewelryItem.id}`, {
        state: { jewelryItem },
      })
    } catch (error) {
    } finally {
      setIsUpdatingJewelryItem(false)
    }
  }

  useEffect(() => {
    if (!formValues.foreground) {
      return
    }

    if (formValues.foreground !== 'black') {
      setValue('background', 'black')
    }
  }, [formValues.foreground])

  useEffect(() => {
    if (!formValues.background) {
      return
    }

    if (formValues.background !== 'black') {
      setValue('foreground', 'black')
    }
  }, [formValues.background])

  return (
    <div className='text-ephemerisBlue'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input label='Birth Date'>
          <input
            {...register('birthDate', {
              required: true,
            })}
            type='date'
          />
        </Input>

        <Input label='Birth Hour'>
          <select
            {...register('birthHour', { required: true, valueAsNumber: true })}
          >
            {map(range(0, 24), hour => (
              <option value={hour} key={hour}>
                {padStart(`${hour}`, 2, '0')}
              </option>
            ))}
          </select>
        </Input>

        <Input label='Birth Minute'>
          <select
            {...register('birthMinute', {
              required: true,
              valueAsNumber: true,
            })}
          >
            {map(range(0, 60), minute => (
              <option value={minute} key={minute}>
                {padStart(`${minute}`, 2, '0')}
              </option>
            ))}
          </select>
        </Input>

        <Input label='Latitude'>
          <input
            {...register('latitude', { required: true, valueAsNumber: true })}
            type='decimal'
          />
        </Input>

        <Input label='Longitude'>
          <input
            {...register('longitude', { required: true, valueAsNumber: true })}
            type='decimal'
          />
        </Input>

        <Input label='GMT Offset'>
          {isFetchingGmtOffset ? (
            <Loader style='dark' />
          ) : (
            <input
              {...register('gmtOffset', { valueAsNumber: true })}
              className='bg-transparent'
              onKeyDown={() => {
                alert(
                  'Change date/time or latitude/longitude and this fields will be automatically updated'
                )
              }}
              readOnly
            />
          )}
        </Input>

        <Input label='Details Color'>
          <select {...register('foreground', { required: true })}>
            {map(jewelryColors, color => (
              <option value={color} key={color}>
                {startCase(color)}
              </option>
            ))}
          </select>
        </Input>

        <Input label='Background Color'>
          <select {...register('background', { required: true })}>
            {map(jewelryColors, color => (
              <option value={color} key={color}>
                {startCase(color)}
              </option>
            ))}
          </select>
        </Input>

        <Input label='Engraving First Line'>
          <input {...register('firstLine')} />
        </Input>

        <Input label='Engraving Second Line'>
          <input {...register('secondLine')} />
        </Input>

        <div
          className={`px-4 py-2 rounded-md flex justify-center items-center border-2 border-ephemerisBlue cursor-pointer mt-4`}
          onClick={() => {
            const submitButton = document.querySelector(
              'input[type="submit"]'
            ) as HTMLInputElement

            submitButton.click()
          }}
        >
          {isUpdatingJewelryItem && <Loader style='dark' />}
          <span className='font-bold'>
            {isUpdatingJewelryItem ? 'Saving' : 'Save'}
          </span>
          <input type='submit' hidden />
        </div>
      </form>
      <div
        className='flex justify-center w-full mt-1 cursor-pointer'
        onClick={() => {
          navigate(`/production/item/${jewelryItem.id}`, {
            state: { jewelryItem },
          })
        }}
      >
        <span className='underline'>Cancel</span>
      </div>
    </div>
  )
}

const EditSynastryJewelryItem: FC<{
  jewelryItem: JewelryProduction.Synastry.Item
}> = ({ jewelryItem }) => {
  const urlInput = React.useRef<HTMLInputElement>()
  const [networkStatus, setNetworkStatus] = React.useState<'idle' | 'loading'>(
    'idle'
  )
  const { getAccessTokenSilently } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)
  const { dispatch } = useProductionContext()

  useEffect(() => {
    const url = `https://ephemeris.co/products/synastry-talisman?customizationInfo=${encodeBase64(
      JSON.stringify(jewelryItem.customizationInfo)
    )}`
    window.open(url, '_blank').focus()
  }, [])

  const handleSubmit: React.FormEventHandler = async event => {
    event.preventDefault()

    const urlString = urlInput.current?.value

    if (!urlString) {
      return
    }

    const url = new URL(urlString)
    const encodedCustomizationInfoJson =
      url.searchParams.get('customizationInfo')
    const customizationInfoJson = decodeBase64(encodedCustomizationInfoJson)
    const customizationInfo = safeParseJson<
      JewelryProduction.Synastry.Item['customizationInfo']
    >(customizationInfoJson)

    if (!customizationInfo) {
      return
    }

    const updatedJewelryItem = {
      ...jewelryItem,
      customizationInfo: {
        ...jewelryItem.customizationInfo,
        ...customizationInfo,
      },
    }

    setNetworkStatus('loading')

    try {
      const response = await queryFulfillmentApi(
        '/jewelry-production/item',
        { jewelryItem: updatedJewelryItem },
        'PUT'
      )

      const { jewelryItem } = (await response.json()) as {
        jewelryItem: JewelryProduction.Item
      }

      dispatch({
        type: ACTIONS.UPDATE_JEWELRY_ITEM,
        payload: { jewelryItem },
      })
      navigate(`/production/item/${jewelryItem.id}`, {
        state: { jewelryItem },
      })
    } catch (error) {
    } finally {
      setNetworkStatus('idle')
    }
  }

  const handleCancel: React.MouseEventHandler = event => {
    navigate(`/production/item/${jewelryItem.id}`, {
      state: { jewelryItem },
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex items-center gap-2'>
        <span>Edited page URL:</span>
        <input
          type='text'
          className='borderslack-gray-300 border rounded'
          ref={urlInput}
        />
      </div>

      <div className='pt-2' />

      <div className='flex items-center gap-4'>
        <button
          type='submit'
          disabled={networkStatus === 'loading'}
          className={`p-2 rounded ${
            networkStatus === 'loading' ? '' : 'bg-ephemerisBlue text-gray-50'
          }`}
        >
          {networkStatus === 'loading' ? 'Updating...' : 'Save'}
        </button>
        <button type='button' className='underline' onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </form>
  )
}

const EditMoonJewelryItem: FC<{
  jewelryItem: JewelryProduction.Moon.Item
}> = ({ jewelryItem }) => {
  const {
    customizationInfo: { year, day, month, hemisphere, color, engraving },
  } = jewelryItem
  const { firstLine, secondLine } = engraving ?? {}
  const colors: JewelryProduction.JewelryColor[] = [
    'gold',
    'silver',
    'rose-gold',
  ]

  const isoDate = DateTime.utc(year, month, day).toISODate()
  const { getAccessTokenSilently } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)
  const [isUpdatingJewelryItem, setIsUpdatingJewelryItem] = useState(false)
  const { register, handleSubmit } = useForm({
    defaultValues: {
      date: isoDate,
      hemisphere: hemisphere,
      color,
      firstLine,
      secondLine,
    },
  })

  const onSubmit = async data => {
    const { date, color, hemisphere, firstLine, secondLine } = data
    const [year, month, day] = date?.split('-').map(Number)

    const updatedJewelryItem: FullyPartial<JewelryProduction.Moon.Item> = {
      ...jewelryItem,
      customizationInfo: {
        ...jewelryItem.customizationInfo,
        year,
        month,
        day,
        color,
        hemisphere,
        engraving: {
          ...jewelryItem.customizationInfo?.engraving,
          firstLine: isEmpty(firstLine) ? undefined : firstLine,
          secondLine: isEmpty(secondLine) ? undefined : secondLine,
        },
      },
    }

    setIsUpdatingJewelryItem(true)

    try {
      const response = await queryFulfillmentApi(
        '/jewelry-production/item',
        { jewelryItem: updatedJewelryItem },
        'PUT'
      )

      const { jewelryItem } = (await response.json()) as {
        jewelryItem: JewelryProduction.Item
      }

      navigate(`/production/item/${jewelryItem.id}`, { state: { jewelryItem } })
    } catch (error) {
    } finally {
      setIsUpdatingJewelryItem(false)
    }
  }

  return (
    <div className='text-ephemerisBlue'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input label='Date'>
          <input
            {...register('date', {
              required: true,
            })}
            type='date'
          />
        </Input>

        <Input label='Hemisphere'>
          <select
            {...register('hemisphere', {
              required: true,
            })}
          >
            {map(['North', 'South'], hemisphere => (
              <option value={hemisphere.toLocaleLowerCase()} key={hemisphere}>
                {hemisphere}
              </option>
            ))}
          </select>
        </Input>

        <Input label='Color'>
          <select {...register('color', { required: true })}>
            {map(colors, color => (
              <option value={color} key={color}>
                {capitalize(color)}
              </option>
            ))}
          </select>
        </Input>

        <Input label='Engraving First Line'>
          <input
            {...register('firstLine', {
              maxLength: MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE,
            })}
          />
        </Input>

        <Input label='Engraving Second Line'>
          <input
            {...register('secondLine', {
              maxLength: MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE,
            })}
          />
        </Input>

        <div
          className={`px-4 py-2 rounded-md flex justify-center items-center border-2 border-ephemerisBlue cursor-pointer mt-4`}
          onClick={() => {
            const submitButton = document.querySelector(
              'input[type="submit"]'
            ) as HTMLInputElement

            submitButton.click()
          }}
        >
          {isUpdatingJewelryItem && <Loader style='dark' />}
          <span className='font-bold'>
            {isUpdatingJewelryItem ? 'Saving' : 'Save'}
          </span>
          <input type='submit' hidden />
        </div>
      </form>
      <div
        className='flex justify-center w-full mt-1 cursor-pointer'
        onClick={() => {
          navigate(`/production/item/${jewelryItem.id}`, {
            state: { jewelryItem },
          })
        }}
      >
        <span className='underline'>Cancel</span>
      </div>
    </div>
  )
}

export const Edit: FC<EditProps> = ({ location }) => {
  const { state } = location
  if (isNil(state)) {
    return <Redirect to='../' />
  }

  const { jewelryItem: targetJewelryItem } = state
  const { jewelryItems } = useProductionContext()
  const jewelryItem = jewelryItems.find(
    ({ id }) => id === targetJewelryItem.id
  ) as JewelryProduction.AnyItem

  if (isBirthChartJewelryItem(jewelryItem)) {
    return <EditBirthChartJewelryItem jewelryItem={jewelryItem} />
  } else if (isSynastryJewelryItem(jewelryItem)) {
    return <EditSynastryJewelryItem jewelryItem={jewelryItem} />
  } else if (isMoonJewelryItem(jewelryItem)) {
    return <EditMoonJewelryItem jewelryItem={jewelryItem} />
  }
}
