import React from 'react'

interface CreateProviderParameters<State, ActionNames, Actions> {
  initialState: State
  reducer: (state: State, action: ReducerAction<ActionNames>) => State
  actions: (dispatch: React.Dispatch<ReducerAction<ActionNames>>) => Actions
}

export function createReadonlyProvider<State>() {
  const Context = React.createContext(undefined)

  const provider: React.FC<State> = ({ children, ...props }) => {
    return <Context.Provider value={props}>{children}</Context.Provider>
  }

  const useContextHook = () => {
    const context = React.useContext<State>(Context)
    if (!context) {
      throw new Error(`context hook used outside its provider`)
    }
    return context
  }

  return {
    provider,
    useContextHook,
  }
}

export default function createProvider<State, ActionNames, Actions>({
  initialState,
  reducer,
  actions,
}: CreateProviderParameters<State, ActionNames, Actions>) {
  const Context = React.createContext<(State & Actions) | undefined>(undefined)

  const provider: React.FC<Partial<State>> = ({ children, ...props }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    return (
      <Context.Provider value={{ ...state, ...actions(dispatch), ...props }}>
        {children}
      </Context.Provider>
    )
  }

  const useContextHook = () => {
    const context = React.useContext<State & Actions>(Context)
    if (!context) {
      throw new Error(`context hook used outside its provider`)
    }
    return context
  }

  return {
    provider,
    useContextHook,
  }
}
