import type { FC } from 'react'
import type { RouteComponent } from '@ephemeris/types/src/reach-router'

import React from 'react'
import _ from 'lodash'
import { Router } from '@reach/router'
import { useToasts } from 'react-toast-notifications'
import { useAuth0 } from '@auth0/auth0-react'
import { useTable, Column } from 'react-table'
import { DateTime } from 'luxon'

import {
  PostersProvider,
  usePostersContext,
} from '../providers/PostersProvider'
import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import Loader from '@ephemeris/react-components/src/Loader'

import PosterPage from './PosterPage'

interface PostersPageProps {}

interface StatusLabelProps {
  poster?: Product.Poster
}

export const StatusLabel: FC<StatusLabelProps> = ({ poster }) => {
  const { status: productStatus, customizedAt } = poster
  const status =
    productStatus === 'cancelled' || productStatus === 'fulfilled'
      ? productStatus
      : !!customizedAt
      ? 'customized'
      : 'waiting_for_customization'

  const backgroundColorClass = (() => {
    switch (status) {
      case 'customized':
        return 'bg-indigo-400'
      case 'fulfilled':
        return 'bg-emerald-400'
      case 'waiting_for_customization':
        return 'bg-amber-400'
      case 'cancelled':
        return 'bg-rose-400'
      default:
        return 'bg-transparent'
    }
  })()

  if (_.isNil(status)) {
    throw new Error(
      `'status' cannot be nil. Choose at least one prop to pass down the value of 'status'.`
    )
  }

  return (
    <span
      className={`text-white px-2 py-0.5 rounded-full ${backgroundColorClass}`}
    >
      {_.startCase(status)}
    </span>
  )
}

function getCellDisplayValueTransformer(
  accessor: Column<Product.Poster>['accessor'],
  poster?: Product.Poster
): (value: any) => JSX.Element {
  return (value: any): JSX.Element => {
    switch (accessor) {
      case 'customer': {
        const { firstName, lastName } = value as JewelryProduction.Customer
        return (
          <>
            {firstName} {lastName}
          </>
        )
      }
      case 'orderCreatedAt':
        return (
          <>{DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)}</>
        )
      case 'customizedAt':
        return <StatusLabel poster={poster} />
      default:
        return <>{`${value}`}</>
    }
  }
}

const PostersPage: FC<RouteComponent & PostersPageProps> = ({ navigate }) => {
  const { posters, setPosters } = usePostersContext()
  const [isRefreshing, setIsRefreshing] = React.useState(false)
  const { addToast } = useToasts()
  const { getAccessTokenSilently } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)

  async function refresh() {
    setIsRefreshing(true)

    try {
      const response = await queryFulfillmentApi('/posters')
      const { posters } = (await response.json()) as {
        posters: Product.Poster[]
      }
      const lunarPosters = _.filter(
        posters,
        ({ printType }) => printType === 'lunar'
      )

      setPosters(lunarPosters)

      addToast('Successfully refreshed', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (error) {
      addToast(`Refresh failed. Error: ${error.message}`, {
        appearance: 'error',
      })
    } finally {
      setIsRefreshing(false)
    }
  }

  const tableItems = React.useMemo(() => {
    return _.orderBy(posters, 'orderCreatedAt', 'desc')
  }, [posters])

  const columns = React.useMemo<Column<Product.Poster>[]>(
    () => [
      { Header: 'Order', accessor: 'uniqueOrderName' },
      { Header: 'Customer', accessor: 'customer' },
      { Header: 'Status', accessor: 'customizedAt' },
      { Header: 'Date', accessor: 'orderCreatedAt' },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableItems })

  return (
    <div className='w-full'>
      <div className='sticky top-0 h-16 p-4 border-b-2 bg-gray-50 border-lightGold'>
        <div className='relative'>
          <button
            className={`
              absolute top-0 left-0 focus:outline-none flex items-center
              ${isRefreshing ? 'no-underline' : 'underline'}
            `}
            onClick={refresh}
            disabled={isRefreshing}
          >
            {isRefreshing && (
              <div className='mr-2'>
                <Loader style='dark' />
              </div>
            )}
            Refresh{isRefreshing ? 'ing' : ''}
          </button>
        </div>
        <div className='flex justify-center'>
          <h1 className='text-2xl font-bold'>Posters</h1>
        </div>
      </div>
      {_.isNil(posters) ? (
        <div className='flex justify-center w-full mt-12'>
          <Loader style='dark' />
        </div>
      ) : (
        <table {...getTableProps()} className={`w-full`}>
          <thead className='sticky border-b-2 shadow-md top-16 border-blueGray-700'>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (
                    <th
                      className='p-4 font-bold cursor-pointer bg-gray-50 text-darkBlue'
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row)

              const poster = tableItems[rowIndex]
              const isCustomized = !!poster.customizedAt
              const rowBackgroundColor =
                rowIndex % 2 === 0 ? 'bg-coolGray-100' : 'bg-coolGray-300'

              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    navigate(poster.id, {
                      state: { poster },
                    })
                  }}
                  className={`cursor-pointer hover:bg-opacity-75 ${rowBackgroundColor}`}
                >
                  {row.cells.map((cell, columnIndex: number) => {
                    const { accessor } = columns[columnIndex] as {
                      accessor: keyof Product.Poster
                    }
                    const value = poster[accessor]
                    const transformDisplayValue =
                      getCellDisplayValueTransformer(accessor, poster)
                    const displayValue = transformDisplayValue(value)

                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`
                      text-ephemerisBlue
                      ${accessor === 'status' ? 'p-0' : 'p-4'}
                    `}
                      >
                        {displayValue}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export const PostersSection: FC<RouteComponent> = () => {
  return (
    <PostersProvider>
      <Router>
        <PostersPage path='/*' />
        <PosterPage path='/:posterId/*' />
      </Router>
    </PostersProvider>
  )
}
