import { replace, isNil } from 'lodash/fp'
import { GetTokenSilentlyOptions } from '@auth0/auth0-react'

import { FULFILLMENT_API_BASE_URL } from '../constants/api'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

function validateBrowser() {
  if (typeof window === 'undefined')
    throw new Error('useFulfillmentApi can only be used on the browser.')
}

export default (
  getAccessToken?: (options?: GetTokenSilentlyOptions) => Promise<string>
) => {
  validateBrowser()
  return async (route: string, body?: any, method: HttpMethod = 'GET') => {
    const sanitizedRoute = replace(/^\//, '', route)
    const stringifiedBody = isNil(body) ? undefined : JSON.stringify(body)
    const url = `${FULFILLMENT_API_BASE_URL}/${sanitizedRoute}`
    const headers = await (async () => {
      if (isNil(getAccessToken)) {
        return
      }

      const accessToken = await getAccessToken({
        audience: 'https://ephemeris-fulfillment-api',
      })
      return [['Authorization', `Bearer ${accessToken}`]]
    })()

    return fetch(url, {
      method,
      body: stringifiedBody,
      headers,
    })
  }
}
