import React, { FC } from 'react'
import { isEmpty, isNil, map, startCase } from 'lodash'
import { DateTime } from 'luxon'

import { getDateTime } from '@ephemeris/utils/src/DateTime'
import { isEither, isNeither } from '@ephemeris/utils/src/compare'
import { ORDERED_BATCH_PRODUCTION_STATUSES } from '@ephemeris/constants/src/fulfillment/jewelry-production'

interface HistoryProps {
  jewelryItem: JewelryProduction.Item
}

const Description: FC<{ event: JewelryProduction.History.Event }> = ({
  event,
}) => {
  const { additionalData, newStatus, name } = event

  if (
    isNeither<JewelryProduction.AnyStatus>(newStatus, [
      'UndergoingProduction',
      'Suspended',
      ...ORDERED_BATCH_PRODUCTION_STATUSES,
    ])
  ) {
    return null
  }

  if (isNil(additionalData) || isEmpty(additionalData)) {
    return null
  }

  return (
    <div className='flex flex-col p-2 mt-2 text-sm bg-white border-2 rounded-md border-lightGold'>
      {(() => {
        switch (true) {
          case newStatus === 'Suspended': {
            const { suspendedBy, suspensionReason } = additionalData

            if (isEither(undefined, [suspendedBy, suspensionReason])) {
              return null
            }

            return (
              <>
                <span>
                  <span className='font-medium'>
                    {name === 'ItemSuspended'
                      ? 'Suspended'
                      : name === 'ItemRemovedFromBatch'
                      ? 'Removed'
                      : ''}{' '}
                    by:
                  </span>{' '}
                  {suspendedBy}
                </span>
                <span>
                  <span className='font-medium'>Reason:</span>{' '}
                  {suspensionReason}
                </span>
              </>
            )
          }

          case ORDERED_BATCH_PRODUCTION_STATUSES.includes(
            newStatus as JewelryProduction.BatchStatus
          ):
          case newStatus === 'UndergoingProduction': {
            const { batchNumber } = additionalData

            return (
              <>
                <div>
                  <span className='font-medium'>Batch</span> #
                  {batchNumber ?? <span className='italic'>unkown</span>}
                </div>
                <div>
                  <span className='font-light line-through'>
                    {event.previousStatus}
                  </span>
                  {' → '}
                  <span className='font-bold'>{event.newStatus!}</span>{' '}
                </div>
              </>
            )
          }

          default:
            return null
        }
      })()}
    </div>
  )
}

export const History: FC<HistoryProps> = ({ jewelryItem }) => {
  return (
    <>
      {map(jewelryItem.history, (event, index) => {
        const { name, date: isoDate } = event
        const date = getDateTime(isoDate)

        return (
          <div className='flex flex-col' key={index}>
            {index > 0 && (
              <div className='w-2 h-8 my-2 ml-2 border-l-2 border-dotted border-darkBlue' />
            )}
            <span className='font-bold'>{startCase(name)}</span>
            <span className='text-xs font-light'>
              {date.toLocaleString(DateTime.DATETIME_MED)}
            </span>
            <Description event={event} />
          </div>
        )
      })}
    </>
  )
}
