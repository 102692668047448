import React from 'react'

interface BatchContextProps {
  batch: JewelryProduction.AnyBatch
}

export const BatchContext = React.createContext<BatchContextProps | undefined>(
  undefined
)

function useBatchContext() {
  const context = React.useContext(BatchContext)
  if (!context) {
    throw new Error(
      `'useBatchContext' is meant to be used within the Batch component only`
    )
  }
  return context
}

export default useBatchContext
