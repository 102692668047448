export function getNumericComputedStylePropertyValue(
  style: CSSStyleDeclaration,
  property: ComputedStyleProperty,
  unit = 'px'
) {
  const propertyValue = style.getPropertyValue(property)

  if (
    propertyValue === null ||
    propertyValue === undefined ||
    propertyValue.length === 0
  ) {
    return
  }

  const numericValue = Number(propertyValue.replace(unit, ''))

  if (Number.isNaN(numericValue)) {
    return
  }

  return numericValue
}
