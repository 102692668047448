export function getReportName(reportType: ReportType): string {
  switch (reportType) {
    case 'reading':
      return 'Astrological Birth Chart Reading'
    case 'forecasting':
      return 'Astrological Forecast Reading'
    case 'saturnCycles':
      return 'Astrological Saturn Cycles Reading'
    case 'jupiterCycles':
      return 'Astrological Jupiter Cycles Reading'
    case 'solarReturn':
      return 'Astrological Solar Return Reading'
    case 'soulAtlas':
      return 'Soul Atlas'
  }
}
