export const π = Math.PI

export function absolute(value: number): number {
  return Math.abs(value)
}

export function hoursToMinutes(hours: number): number {
  return hours * 60
}

export function minutesToHours(minutes: number): number {
  return minutes / 60
}

export function daysToSeconds(days: number): number {
  return days * 24 * 60 * 60
}

export function twoThirds(value: number): number {
  return (value / 3) * 2
}

export function invertSign(value: number): number {
  return value * -1
}

export function getOrdinal(value: number): string {
  const remainderOf10 = value % 10
  const remainderOf100 = value % 100
  if (remainderOf10 === 1 && remainderOf100 !== 11) {
    return `${value}st`
  }
  if (remainderOf10 === 2 && remainderOf100 !== 12) {
    return `${value}nd`
  }
  if (remainderOf10 === 3 && remainderOf100 !== 13) {
    return `${value}rd`
  }
  return `${value}th`
}

export function half(value: number): number {
  return value / 2
}

export function whole(value: number): number {
  return Math.floor(value)
}

export function getPercentage(
  partialValue: number,
  totalValue: number
): number {
  return (100 * partialValue) / totalValue
}

export function percent(
  percentage,
  {
    of: value,
  }: {
    of: number
  }
) {
  return (percentage / 100) * value
}

export function isEven(value: number): boolean {
  return value % 2 === 0
}

export function old_getCoordinatesOfAngle(
  angle: number,
  radius: number,
  distanceFromBorder = 0
): Geometry.Point {
  radius = radius - distanceFromBorder

  return {
    x: distanceFromBorder + radius + radius * Math.cos((angle * π) / 180),
    y: distanceFromBorder + radius + radius * Math.sin((-angle * π) / 180),
  }
}

export function getPointForAngleInArc(circle: Geometry.Circle, angle: number) {
  return {
    x: circle.center.x + circle.radius * Math.cos((angle * π) / 180),
    y: circle.center.y + circle.radius * Math.sin((-angle * π) / 180),
  }
}
