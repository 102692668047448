import React from 'react'
import {
  ProductionReducerAction,
  ProductionState,
} from './ProductionProvider/Store'

interface ProductionContextProps extends ProductionState {
  dispatch: React.Dispatch<ProductionReducerAction>
}

export const ProductionContext = React.createContext<
  ProductionContextProps | undefined
>(undefined)

function useProductionContext() {
  const context = React.useContext(ProductionContext)
  if (!context) {
    throw new Error(
      `'useProductionContext' is meant to be used within the Production component only`
    )
  }
  return context
}

export default useProductionContext
