import { isNil } from 'lodash'
import React, { FC } from 'react'

import {
  isMoonJewelryItem,
  isBirthChartJewelryItem,
  isSynastryJewelryItem,
} from '@ephemeris/utils/src/jewelry-production'

interface JewelryItemThumbnailProps {
  jewelryItem: JewelryProduction.AnyItem
}

export const JewelryItemThumbnail: FC<JewelryItemThumbnailProps> = ({
  jewelryItem,
}) => {
  const { renders } = jewelryItem
  const thumbnail = renders?.thumbnail ?? (renders as any)?.flatPreview
  const { foreground, background } = (() => {
    return isMoonJewelryItem(jewelryItem)
      ? {
          foreground: jewelryItem.customizationInfo.color,
          background: jewelryItem.customizationInfo.color,
        }
      : isBirthChartJewelryItem(jewelryItem) ||
        isSynastryJewelryItem(jewelryItem)
      ? jewelryItem.customizationInfo.theme
      : { foreground: undefined, background: undefined }
  })()

  if (isNil(foreground) || isNil(background)) {
    return null
  }

  if (isNil(renders)) {
    return null
  }

  if (isNil(thumbnail)) {
    return null
  }

  const borderColor = (() => {
    switch (foreground) {
      case 'black':
        return 'border-black'
      case 'gold':
        return 'border-amber-300'
      case 'silver':
        return 'border-blueGray-300'
      case 'rose-gold':
        return 'border-rose-300'
      default:
        throw new Error(`unrecognized foreground color '${foreground}'`)
    }
  })()
  const backgroundColor = (() => {
    switch (background) {
      case 'black':
        return 'bg-black'
      case 'gold':
        return 'bg-amber-300'
      case 'silver':
        return 'bg-blueGray-300'
      case 'rose-gold':
        return 'bg-rose-300'
      default:
        throw new Error(`unrecognized backgroundColor color '${background}'`)
    }
  })()

  const imageFilter = (() => {
    if (background === 'black' && foreground === 'gold') {
      return `invert(0.75) sepia(1) saturate(1)`
    }

    if (background === 'black' && foreground === 'silver') {
      return `invert(0.75) brightness(125)`
    }

    if (background === 'black' && foreground === 'rose-gold') {
      return `invert(0.75) sepia(1) saturate(1) hue-rotate(290deg)`
    }

    return 'none'
  })()

  return (
    <div
      className={`rounded-full border-2 ${backgroundColor} ${borderColor} overflow-hidden`}
    >
      <img src={thumbnail} style={{ filter: imageFilter }} />
    </div>
  )
}
