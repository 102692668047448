interface IsBetweenOptions {
  exclusiveRight?: boolean
  exclusiveLeft?: boolean
}

export function getNumericValue(value: any): number | undefined {
  const numericValue = Number(value === null ? undefined : value)
  return isNaN(numericValue) ? undefined : numericValue
}

export function isFloat(value: number): boolean {
  return `${value}`.includes('.')
}

export function isBetween(
  value: number,
  min: number,
  max: number,
  options?: IsBetweenOptions
): boolean {
  const { exclusiveLeft = false, exclusiveRight = false } = options ?? {}
  const leftVerification = exclusiveLeft ? value > min : value >= min
  const rightVerification = exclusiveRight ? value < max : value <= max

  return leftVerification && rightVerification
}

export function romanize(value: number) {
  const numerals = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }
  let roman = ''

  for (let i in numerals) {
    while (value >= numerals[i]) {
      roman += i
      value -= numerals[i]
    }
  }
  return roman
}
