import type React from 'react'
import type { ActionType } from './PostersProvider'

export interface Actions {
  setPosters(posters: Product.Poster[]): void
}

export default (
  dispatch: React.Dispatch<ReducerAction<ActionType>>
): Actions => {
  return {
    setPosters: (posters: Product.Poster[]) =>
      dispatch({ type: 'SET_POSTERS', payload: { posters } }),
  }
}
