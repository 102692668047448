import React, { FC } from 'react'
import { startCase, isNil } from 'lodash'

interface StatusLabelProps {
  jewelryItem?: JewelryProduction.Item
  status?: JewelryProduction.Status
}

function getBackgroundColorForStatus(status: JewelryProduction.Status) {
  switch (status) {
    case 'ReadyForProduction':
      return 'bg-indigo-400'
    case 'WaitingForCustomization':
    case 'WaitingForMissingData':
    case 'Suspended':
    case 'WaitingForRender':
      return 'bg-amber-400'
    case 'Posted':
      return 'bg-emerald-400'
    case 'UndergoingProduction':
      return 'bg-orange-400'
    case 'Cancelled':
      return 'bg-rose-400'
    default:
      return 'bg-transparent'
  }
}

export const StatusLabel: FC<StatusLabelProps> = props => {
  const status = props.status ?? props.jewelryItem?.status

  if (isNil(status)) {
    throw new Error(
      `'status' cannot be nil. Choose at least one prop to pass down the value of 'status'.`
    )
  }

  return (
    <span
      className={`text-white px-2 py-0.5 rounded-full ${getBackgroundColorForStatus(
        status
      )}`}
    >
      {startCase(status)}
    </span>
  )
}
