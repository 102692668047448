export function createBase64DataUrl(buffer: Buffer, mimeType: string) {
  return `data:${mimeType};base64,${buffer.toString('base64')}`
}

export function encodeBase64(text: string) {
  return Buffer.from(text).toString('base64')
}

export function decodeBase64(base64: string) {
  return Buffer.from(base64, 'base64').toString()
}
