import React from 'react'

import type { FC } from 'react'

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>
export type ButtonComposites = {
  Icon: FC
}

export const Button: FC<ButtonProps> & ButtonComposites = ({
  children,
  className,
  ...props
}) => {
  const [icon, ...rest] = React.Children.toArray(children)

  return (
    <button
      {...props}
      className={`p-1 rounded-md hover:bg-coolGray-100 focus:outline-none ${className}`}
    >
      <div className='flex items-center'>
        {icon}
        <div className='ml-2'>{rest}</div>
      </div>
    </button>
  )
}

Button.Icon = ({ children }) => (
  <div className='w-6 text-ephemerisBlue'>{children}</div>
)
