import React, { FC, useReducer, useEffect } from 'react'
import { orderBy } from 'lodash/fp'
import { useAuth0 } from '@auth0/auth0-react'

import { ProductionContext } from '../useProductionContext'
import { productionReducer, initialState } from '.'
import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import { ACTIONS } from './Store'

export const ProductionProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(productionReducer, initialState)
  const { getAccessTokenSilently } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)

  useEffect(() => {
    async function getJewelryItems() {
      const response = await queryFulfillmentApi(
        'jewelry-production/items',
        null,
        'POST'
      )
      const { jewelryItems, lastEvaluatedKey } = (await response.json()) as {
        jewelryItems: JewelryProduction.Item[]
        lastEvaluatedKey: Dictionary<any>
      }
      const orderedJewelryItems = orderBy(
        'orderCreatedAt',
        'desc',
        jewelryItems
      )

      dispatch({
        type: ACTIONS.SET_JEWELRY_ITEMS,
        payload: { jewelryItems: orderedJewelryItems, lastEvaluatedKey },
      })
    }

    async function getBatchesInProduction() {
      const response = await queryFulfillmentApi(
        '/jewelry-production/batches/undergoing-production'
      )

      const { batches } = (await response.json()) as {
        batches: JewelryProduction.Batch[]
      }
      const orderedBatches = orderBy('batchNumber', 'desc', batches)

      console.log(`--- did fetch batches in production`)

      dispatch({
        type: ACTIONS.SET_PRODUCTION_BATCHES,
        payload: { batches: orderedBatches },
      })
    }

    getJewelryItems()
    getBatchesInProduction()
  }, [])

  return (
    <ProductionContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ProductionContext.Provider>
  )
}
