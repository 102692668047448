import type { FC, InputHTMLAttributes, ChangeEvent } from 'react'

import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { isNil, map } from 'lodash'

import { useReportsBacklogApi } from '@ephemeris/fulfillment-api/src/useReportsBacklogApi'
import { getDateTime } from '@ephemeris/utils/src/DateTime'
import Loader from '@ephemeris/react-components/src/Loader'
import { getNameParticles } from '@ephemeris/utils/src/birth-info'
import { ACTIVE_REPORT_TYPES } from '@ephemeris/constants/src/fulfillment/reports-backlog'
import { RouteComponent } from '@ephemeris/types/src/reach-router'

type EditBacklogItemProps = RouteComponent & { backlogItemId?: string }

const { getReportsBacklogItem, updateReportsBacklogItems } =
  useReportsBacklogApi()

const Label: FC = ({ children }) => {
  return <p className='ml-2 text-xs font-bold'>{children}</p>
}
const TextField: FC<InputHTMLAttributes<HTMLInputElement>> = props => {
  return <input {...props} className='w-full p-2 rounded-md shadow-sm' />
}

const FormRow: FC<
  {
    label: string
    value: string | number | undefined
    name: string
  } & Pick<InputHTMLAttributes<HTMLInputElement>, 'type' | 'onChange'>
> = ({ label, value, type = 'text', name, onChange }) => {
  return (
    <div className='mt-6 first:mt-0'>
      <Label>{label}</Label>
      <div className='mt-2' />
      <TextField
        type={type}
        defaultValue={value}
        name={name}
        onChange={onChange}
      />
    </div>
  )
}

export const EditBacklogItem: FC<EditBacklogItemProps> = ({
  backlogItemId,
}) => {
  const { addToast } = useToasts()

  function onValueUpdate(event: ChangeEvent<HTMLInputElement>): void {
    if (isNil(backlogItem)) {
      return
    }

    const { target } = event
    const { name } = target

    const updatedBacklogItem: ReportsBacklog.Item =
      ((): ReportsBacklog.Item => {
        const { birthInfo } = backlogItem

        if (name === 'nameOnReport') {
          const nameOnReport = target.value
          const { firstName, lastName } = getNameParticles(nameOnReport)

          return {
            ...backlogItem,
            birthInfo: {
              ...birthInfo,
              firstName,
              lastName,
            },
            nameOnReport,
          }
        } else if (name === 'birthDate') {
          const [year, month, day] = target.value
            .split('-')
            .map(item => Number(item))

          return {
            ...backlogItem,
            birthInfo: {
              ...birthInfo,
              date: { ...birthInfo.date, year, month, day },
            },
          }
        } else if (name === 'birthHour') {
          const hour = target.valueAsNumber

          return {
            ...backlogItem,
            birthInfo: {
              ...birthInfo,
              date: { ...birthInfo.date, hour },
            },
          }
        } else if (name === 'birthMinute') {
          const minute = target.valueAsNumber

          return {
            ...backlogItem,
            birthInfo: {
              ...birthInfo,
              date: { ...birthInfo.date, minute },
            },
          }
        } else if (name === 'gmtOffset') {
          const gmtOffset = target.valueAsNumber

          return {
            ...backlogItem,
            birthInfo: {
              ...birthInfo,
              date: { ...birthInfo.date, gmtOffset },
            },
          }
        } else if (name === 'birthPlaceName') {
          const name = target.value

          return {
            ...backlogItem,
            birthInfo: {
              ...birthInfo,
              location: { ...birthInfo.location, name },
            },
          }
        } else if (name === 'latitude') {
          const latitude = target.valueAsNumber

          return {
            ...backlogItem,
            birthInfo: {
              ...birthInfo,
              location: {
                ...birthInfo.location,
                latitude,
              },
            },
          }
        } else if (name === 'longitude') {
          const longitude = target.valueAsNumber

          return {
            ...backlogItem,
            birthInfo: {
              ...birthInfo,
              location: {
                ...birthInfo.location,
                longitude,
              },
            },
          }
        } else if (name === 'destinationEmail') {
          const destinationEmail = target.value

          return {
            ...backlogItem,
            destinationEmail,
          }
        }

        return backlogItem
      })()

    setBacklogItem(updatedBacklogItem)
  }

  async function saveInfo(): Promise<void> {
    setIsSaving(true)

    try {
      const reportTypesPattern = ACTIVE_REPORT_TYPES.join('|')
      const splitPattern = `-(${reportTypesPattern})-?([0-9]+)?`

      const [baseId, _, index] = backlogItem!.id.split(new RegExp(splitPattern))
      const backlogItems = map(ACTIVE_REPORT_TYPES, reportType => ({
        ...backlogItem!,
        reportType,
        id: `${baseId}-${reportType}${isNil(index) ? '' : `-${index}`}`,
      }))

      await updateReportsBacklogItems(backlogItems)
      addToast('The info was updated', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch {
      addToast('Could not update info', {
        appearance: 'error',
        autoDismiss: true,
      })
    } finally {
      setIsSaving(false)
    }
  }

  const [backlogItem, setBacklogItem] =
    useState<Optional<ReportsBacklog.Item>>(undefined)
  useEffect(() => {
    getReportsBacklogItem(backlogItemId).then(backlogItem => {
      setBacklogItem(backlogItem)
    })
  }, [])
  const [isSaving, setIsSaving] = useState(false)

  return backlogItem ? (
    ((): JSX.Element => {
      const { birthInfo, nameOnReport, destinationEmail } = backlogItem
      const {
        date: { gmtOffset },
        location: { name: birthPlaceName, latitude, longitude },
      } = birthInfo
      const birthDate = getDateTime(birthInfo)

      return (
        <div className='flex flex-col items-center justify-center h-screen bg-ephemerisBlue'>
          <div className='w-1/3 p-8 rounded-lg shadow-md bg-gray-50'>
            <FormRow
              label='Name on Report'
              value={nameOnReport}
              name='nameOnReport'
              onChange={onValueUpdate}
            />
            <FormRow
              label='Birth Date'
              value={birthDate.toISODate()}
              type='date'
              name='birthDate'
              onChange={onValueUpdate}
            />
            <div className='mt-6'>
              <Label>Birth Time</Label>
              <div className='mt-2' />
              <div className='flex items-center justify-between ml-2'>
                <div className='flex items-center'>
                  <p className='mr-2 text-xs'>Hour:</p>
                  <TextField
                    type='number'
                    defaultValue={birthDate.hour}
                    name='birthHour'
                    min={0}
                    max={23}
                    onChange={onValueUpdate}
                  />
                </div>
                <div className='flex items-center ml-2'>
                  <p className='mr-2 text-xs'>Minute:</p>
                  <TextField
                    type='number'
                    defaultValue={birthDate.minute}
                    name='birthMinute'
                    min={0}
                    max={60}
                    onChange={onValueUpdate}
                  />
                </div>
              </div>
            </div>
            <FormRow
              label='GMT Offset'
              value={gmtOffset}
              type='number'
              name='gmtOffset'
              onChange={onValueUpdate}
            />
            <FormRow
              label='Birth Location Name'
              value={birthPlaceName}
              type='text'
              name='birthPlaceName'
              onChange={onValueUpdate}
            />
            <div className='mt-6'>
              <Label>Birth Location Coordinates</Label>
              <div className='mt-2' />
              <div className='flex items-center justify-between ml-2'>
                <div className='flex items-center'>
                  <p className='mr-2 text-xs'>Latitude:</p>
                  <TextField
                    type='number'
                    defaultValue={latitude}
                    name='latitude'
                    onChange={onValueUpdate}
                  />
                </div>
                <div className='flex items-center ml-2'>
                  <p className='mr-2 text-xs'>Longitude:</p>
                  <TextField
                    type='number'
                    defaultValue={longitude}
                    name='longitude'
                    onChange={onValueUpdate}
                  />
                </div>
              </div>
            </div>
            <FormRow
              label='Email to Send Report'
              value={destinationEmail}
              type='email'
              name='destinationEmail'
              onChange={onValueUpdate}
            />

            <div className='mt-8' />

            <button
              className={`
                    bg-primary text-gray-50 font-bold p-4 rounded-md w-full hover:bg-opacity-75 flex justify-center items-center transition duration-100
                    ${isSaving ? 'bg-gray-400 cursor-default' : ''}
                  `}
              onClick={saveInfo}
            >
              {isSaving && <Loader />}
              {isSaving ? <>Saving</> : <>Save</>}
            </button>
          </div>
        </div>
      )
    })()
  ) : (
    <div>Loading...</div>
  )
}
