import { map } from 'lodash'
import { updateJewelryItem } from './actions'
import { ProductionState, ProductionReducerAction, ACTIONS } from './Store'

type ProductionReducer = (
  state: ProductionState,
  action: ProductionReducerAction
) => ProductionState

const reducer: ProductionReducer = (
  state: ProductionState,
  action: ProductionReducerAction
) => {
  const { type, payload } = action

  switch (type) {
    case ACTIONS.SET_JEWELRY_ITEMS:
      console.log(`--- payload.lastEvaluatedKey:`, payload.lastEvaluatedKey)
      return {
        ...state,
        jewelryItems: payload.jewelryItems,
        jewelryItemsInitialLastEvaluatedKey:
          state.jewelryItemsInitialLastEvaluatedKey ?? payload.lastEvaluatedKey,
      }

    case ACTIONS.UPDATE_JEWELRY_ITEM: {
      const { jewelryItems } = state
      const { jewelryItem } = payload

      const updatedJewelryItems = updateJewelryItem(jewelryItems, jewelryItem)

      return { ...state, jewelryItems: updatedJewelryItems }
    }

    case ACTIONS.SET_PRODUCTION_BATCHES:
      return {
        ...state,
        batches: { ...state.batches, inProduction: payload.batches },
      }

    case ACTIONS.ADD_PRODUCTION_BATCH: {
      const { batches } = state
      const { batch } = payload
      const productionBatches = batches.inProduction ?? []

      return {
        ...state,
        batches: {
          ...batches,
          inProduction: [...productionBatches, batch],
        },
      }
    }

    case ACTIONS.UPDATE_PRODUCTION_BATCH: {
      const { batches } = state
      const { batch: updatedBatch } = payload
      const productionBatches = batches.inProduction ?? []

      return {
        ...state,
        batches: {
          ...batches,
          inProduction: map(productionBatches, batch =>
            batch.id === updatedBatch.id ? updatedBatch : batch
          ),
        },
      }
    }

    default:
      return state
  }
}

export const initialState: ProductionState = {
  batches: {},
  jewelryItems: undefined,
  jewelryItemsInitialLastEvaluatedKey: undefined,
}
export default reducer
