export const ALL_REPORTS_BACKLOG_ITEMS_STATUSES: ReportsBacklog.Status[] = [
  'BeingGenerated',
  'FailedToBeGenerated',
  'WaitingForMissingData',
  'ReadyToBeGenerated',
  'SuccessfullyGenerated',
  'Delivered',
]

export const ACTIVE_REPORT_TYPES: ReportType[] = [
  'reading',
  'forecasting',
  'saturnCycles',
]
