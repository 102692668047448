import '../css/index.css'

import React, { FC, useEffect } from 'react'
import { Router, Link, RouteComponentProps } from '@reach/router'
import { useAuth0 } from '@auth0/auth0-react'
import { useSessionStorage } from 'react-use-sessionstorage'
import { ToastProvider } from 'react-toast-notifications'
import { IconContext } from 'react-icons'

import { getEnvironmentInfo } from '@ephemeris/utils/src/environment'

import { ModalProvider } from '../components/Modal'
import ReportsBacklog from '../components/ReportsBacklog'
import Production from '../components/Production'
import EditBacklogItem from '../components/EditBacklogItem'
import PostersPage from '../components/PostersPage'
import ChartGenerator from '../components/ChartGenerator'

type UseSsrSessionStorageOutput = [string, React.Dispatch<string>]

const useSsrSessionStorage = (
  key: string,
  initial: string | undefined
): UseSsrSessionStorageOutput => {
  return (
    typeof window === 'undefined'
      ? [initial, (): undefined => undefined]
      : useSessionStorage(key, initial)
  ) as UseSsrSessionStorageOutput
}

const Layout: FC = ({ children }) => {
  const { isDevelopment } = getEnvironmentInfo()
  const mainContent = <div className='bg-gray-50'>{children}</div>

  if (!isDevelopment) {
    return <>{mainContent}</>
  }

  return (
    <div className='absolute inset-0 w-full h-full px-2 pt-4 bg-amber-400'>
      <div className='flex justify-center w-full'>
        <h1 className='text-3xl font-bold text-gray-600'>DEVELOPMENT</h1>
      </div>

      <div className='mt-4'>{mainContent}</div>
    </div>
  )
}

const Menu: FC<RouteComponentProps> = () => (
  <div className='flex flex-col text-lightGold'>
    <Link to='reports' className='underline'>
      Reports
    </Link>

    <div className='mt-4' />

    <span className='block text-lg'>Jewelry Production</span>
    <Link to='production' className='text-sm underline'>
      Items List
    </Link>
    <Link to='production/batches' className='text-sm underline'>
      Batches
    </Link>

    <div className='mt-4' />

    <Link to='posters' className='text-lg underline'>
      Posters
    </Link>

    <div className='mt-4' />

    <Link to='chart-generator' className='text-lg underline'>
      Chart Generator
    </Link>
  </div>
)

const App = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [_, setAccessToken] = useSsrSessionStorage('accessToken', undefined)

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    getAccessTokenSilently()
      .then(accessToken => {
        console.log(`--- did set accessToken:`, accessToken)
        setAccessToken(accessToken)
      })
      .catch(error => {
        console.log(`--- could not get access token. error:`, error)
      })
  }, [isAuthenticated])

  return (
    <Layout>
      <div className='flex'>
        <div className='min-h-screen px-4 py-8 border-r-2 bg-darkBlue border-darkGold whitespace-nowrap'>
          <div className='sticky top-8'>
            <Router>
              <Menu path='*' />
            </Router>
          </div>
        </div>
        <div className='w-full min-h-screen'>
          <Router>
            <Production path='production/*' />
            <ReportsBacklog path='reports' />
            <EditBacklogItem path='edit/:backlogItemId' />
            <PostersPage path='posters/*' />
            <ChartGenerator path='chart-generator/*' />
          </Router>
        </div>
      </div>
    </Layout>
  )
}

export default () => (
  <IconContext.Provider value={{ size: '_' }}>
    <ModalProvider rootElementId='modal-root'>
      <ToastProvider>
        <App />
      </ToastProvider>
    </ModalProvider>
  </IconContext.Provider>
)
