import type { FC } from 'react'

import React, { useState, useEffect } from 'react'
import { map, isNil, some, filter, isNull, startCase } from 'lodash'
import { DateTime } from 'luxon'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from '@reach/router'
import { Menu } from '@headlessui/react'
import {
  HiOutlineCheckCircle,
  HiOutlineDotsVertical,
  HiOutlineSwitchHorizontal,
  HiOutlineXCircle,
} from 'react-icons/hi'

import {
  getJigSlotCoordinate,
  isSynastryJewelryItem,
} from '@ephemeris/utils/src/jewelry-production'

import useBatchContext from '../useBatchContext'
import {
  isPreviewBatch,
  isProductionBatch,
  isBirthChartJewelryItem,
  isMoonJewelryItem,
} from '@ephemeris/utils/src/jewelry-production'
import ActionMenu, { ActionMenuProps, ActionMenuComposites } from './ActionMenu'
import JewelryItemThumbnail from '../../../../JewelryItemThumbnail'

interface JigProps {
  isRenderingBatches: boolean
  isSelectingItems: boolean
  isCreatingBatchManually: boolean
  selectedItems: { jewelryItem: JewelryProduction.Item; jigIndex: number }[]
  onSelectJewelryItem: (jewelryItem: JewelryProduction.Item) => void
  onRemoveJewelryItem: (jewelryItem: JewelryProduction.Item) => void
}

interface Composites {
  ActionMenu: FC<ActionMenuProps> & ActionMenuComposites
}

interface JigSlot {
  jewelryItem: JewelryProduction.AnyItem | null
}

type SlotProps = Pick<JigSlot, 'jewelryItem'> & {
  forceFlip: boolean
  index: number
  isSelectingItems: boolean
  onSelect: (jewelryItem: JewelryProduction.AnyItem) => void
  isSelected: boolean
}

function getJigSlotBackgroundColor(jewelryItem: JewelryProduction.AnyItem) {
  if (isMoonJewelryItem(jewelryItem)) {
    const {
      customizationInfo: { color },
    } = jewelryItem

    switch (color) {
      case 'gold':
        return 'bg-amber-300'
      case 'silver':
        return 'bg-blueGray-300'
      case 'rose-gold':
        return 'bg-rose-300'
      default:
        throw new Error(`unrecognized backgroundColor color '${color}'`)
    }
  } else if (
    isBirthChartJewelryItem(jewelryItem) ||
    isSynastryJewelryItem(jewelryItem)
  ) {
    const {
      customizationInfo: {
        theme: { background },
      },
    } = jewelryItem ?? { customizationInfo: { theme: {} } }

    switch (background) {
      case 'black':
        return 'bg-black'
      case 'gold':
        return 'bg-amber-300'
      case 'silver':
        return 'bg-blueGray-300'
      case 'rose-gold':
        return 'bg-rose-300'
      default:
        throw new Error(`unrecognized backgroundColor color '${background}'`)
    }
  }

  return 'bg-black'
}

function getJigSlotEngravingTextColor(jewelryItem: JewelryProduction.AnyItem) {
  if (isMoonJewelryItem(jewelryItem)) {
    const {
      customizationInfo: { color },
    } = jewelryItem

    switch (color) {
      case 'gold':
      case 'silver':
      case 'rose-gold':
        return 'text-darkBlue'

      default:
        throw new Error(`unrecognized backgroundColor color '${color}'`)
    }
  } else if (
    isBirthChartJewelryItem(jewelryItem) ||
    isSynastryJewelryItem(jewelryItem)
  ) {
    const {
      customizationInfo: {
        theme: { background },
      },
    } = jewelryItem ?? { customizationInfo: { theme: {} } }

    switch (background) {
      case 'black':
        return 'text-gray-200'
      case 'gold':
      case 'silver':
      case 'rose-gold':
        return 'text-darkBlue'

      default:
        throw new Error(`unrecognized backgroundColor color '${background}'`)
    }
  }
}

const Slot: FC<SlotProps> = ({
  jewelryItem,
  forceFlip,
  index,
  isSelectingItems,
  onSelect,
  isSelected,
}) => {
  const [currentFace, setCurrentFace] = useState<'front' | 'back'>('front')
  const isEmptySlot = isNil(jewelryItem)

  if (isEmptySlot) {
    return (
      <div className='w-20 h-20 overflow-hidden bg-gray-900 border-b-[1px] border-pink-300 rounded-full shadow-inner bg-opacity-30 lg:w-32 lg:h-32' />
    )
  }

  const {
    customizationInfo: {
      engraving: { firstLine, secondLine } = { firstLine: '', secondLine: '' },
    },
  } = jewelryItem ?? { customizationInfo: { theme: {} } }

  const backgroundColor = getJigSlotBackgroundColor(jewelryItem)
  const engravingTextColor = getJigSlotEngravingTextColor(jewelryItem)

  useEffect(() => {
    setTimeout(() => {
      setCurrentFace(forceFlip ? 'back' : 'front')
    }, index * 50)
  }, [forceFlip])

  return (
    <motion.div
      className={`w-full h-full overflow-hidden rounded-full`}
      onMouseEnter={() => {
        if (isSelectingItems) {
          return
        }
        setCurrentFace(forceFlip ? 'front' : 'back')
      }}
      onMouseLeave={() => {
        if (isSelectingItems) {
          return
        }
        setCurrentFace(forceFlip ? 'back' : 'front')
      }}
    >
      <Link
        className='w-full h-full'
        to={isSelectingItems ? '' : `/production/item/${jewelryItem!.id}`}
        state={{
          jewelryItem,
          jigSlotCoordinate: getJigSlotCoordinate(index),
        }}
        replace={isSelectingItems}
        onClick={() => {
          if (!isSelectingItems) {
            return
          }

          onSelect(jewelryItem!)
        }}
      >
        <AnimatePresence>
          {isSelectingItems && (
            <div className='relative z-10'>
              <motion.div
                className={`
                  absolute inset-0 flex items-center justify-center w-full h-20 lg:h-32 transition-all rounded-full
                  ${isSelected ? '' : 'bg-gray-900 bg-opacity-75'}
                `}
                initial={{ opacity: 0, scale: '50%' }}
                animate={{ opacity: 1, scale: '100%' }}
                exit={{ opacity: 0, scale: '50%' }}
              >
                {isSelected && (
                  <motion.div
                    className='w-1/2 h-1/2'
                    initial={{ opacity: 0, scale: '15%', translateY: '-100%' }}
                    animate={{ opacity: 1, scale: '100%', translateY: 0 }}
                    exit={{ opacity: 0, scale: '15%', translateY: '-100%' }}
                    transition={{ type: 'spring', duration: 0.2 }}
                  >
                    <HiOutlineCheckCircle className='w-full h-full text-green-400 rounded-full bg-gray-50 bg-opacity-90' />
                  </motion.div>
                )}
              </motion.div>
            </div>
          )}
        </AnimatePresence>
        <div className='relative w-full h-full'>
          <motion.div
            key={`front-${index}`}
            className='absolute inset-0 w-full h-full border-b border-pink-400 rounded-full'
            style={{
              backfaceVisibility: 'hidden',
              WebkitBackfaceVisibility: 'hidden',
            }}
            initial={{ rotateY: 0 }}
            animate={{ rotateY: currentFace === 'back' ? -180 : 0 }}
            transition={{ type: 'spring' }}
          >
            <JewelryItemThumbnail jewelryItem={jewelryItem} />
          </motion.div>
          <motion.div
            key={`back-${index}`}
            className={`absolute inset-0 w-full h-full p-[0.05rem] flex flex-col justify-center overflow-hidden rounded-full items-center ${backgroundColor} ${engravingTextColor} overflow-hidden text-xs break-words text-center`}
            style={{
              backfaceVisibility: 'hidden',
              WebkitBackfaceVisibility: 'hidden',
            }}
            initial={{ rotateY: 180 }}
            animate={{ rotateY: currentFace === 'back' ? 0 : 180 }}
            transition={{ type: 'spring' }}
          >
            <motion.div>{firstLine}</motion.div>
            <motion.div>{secondLine}</motion.div>
          </motion.div>
        </div>
      </Link>
    </motion.div>
  )
}

function formatDateOfCreation(
  batch: JewelryProduction.Batch | JewelryProduction.PreviewBatch
) {
  if (!isProductionBatch(batch)) {
    return
  }

  const { createdAt } = batch

  return DateTime.fromISO(createdAt).toLocaleString(DateTime.DATETIME_MED)
}

export const Jig: FC<JigProps> & Composites = ({
  isSelectingItems,
  isCreatingBatchManually,
  onSelectJewelryItem: handleSelectJewelryItem,
  onRemoveJewelryItem: handleRemoveJewelryItem,
  selectedItems,
  children,
}) => {
  const { batch } = useBatchContext()
  const isPreview = isPreviewBatch(batch)
  const dateOfCreation = formatDateOfCreation(batch)
  const numberOfItems = filter(
    batch.jewelryItems,
    jewelryItem => !isNull(jewelryItem)
  ).length
  const [forceFlip, setForceFlip] = useState(false)

  const [actionMenu] = React.Children.toArray(children)

  console.log(`--- batch:`, batch)

  return (
    <div className='p-8 bg-jigBordeaux rounded-2xl'>
      <div className='flex justify-end w-full transition-all'>
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button
                className={`
                  w-10 h-10 p-1 font-bold rounded-md focus:outline-none hover:shadow-md hover:border-t hover:border-pink-300 text-amber-300
                  ${open ? 'shadow-md border-t border-pink-300' : ''}
                `}
              >
                <HiOutlineDotsVertical className='w-8' />
              </Menu.Button>
              <Menu.Items className='absolute z-50 flex flex-col p-2 mt-12 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-md'>
                <Menu.Item>
                  <ActionMenu.Button
                    onClick={() => {
                      setForceFlip(!forceFlip)
                    }}
                  >
                    <ActionMenu.Button.Icon>
                      <HiOutlineSwitchHorizontal />
                    </ActionMenu.Button.Icon>
                    {forceFlip ? 'Unflip all' : 'Flip all'}
                  </ActionMenu.Button>
                </Menu.Item>
                <ActionMenu />
                {actionMenu}
              </Menu.Items>
            </>
          )}
        </Menu>
      </div>
      <div className='grid grid-cols-6 gap-4 px-8 font-bold pl-11 justify-items-center text-gray-50'>
        <span>1</span>
        <span>2</span>
        <span>3</span>
        <span>4</span>
        <span>5</span>
        <span>6</span>
      </div>
      <div className='flex'>
        <div className='flex flex-col justify-around font-bold h-inherit text-gray-50'>
          <span>A</span>
          <span>B</span>
          <span>C</span>
        </div>
        <div className='grid flex-grow grid-cols-6 gap-4 p-8 gap-y-8 justify-items-center'>
          {map(batch.jewelryItems, (jewelryItem, index) => {
            const isEmptySlot = isNull(jewelryItem)
            const isSelected = some(
              selectedItems,
              ({ jigIndex }) => jigIndex === index
            )

            const jewelryType = isEmptySlot
              ? undefined
              : jewelryItem.jewelryType
            const backgroundColor = isEmptySlot
              ? undefined
              : isBirthChartJewelryItem(jewelryItem) ||
                isSynastryJewelryItem(jewelryItem)
              ? jewelryItem.customizationInfo.theme.background
              : jewelryItem.customizationInfo.color
            const foregroundColor = isEmptySlot
              ? undefined
              : isBirthChartJewelryItem(jewelryItem) ||
                isSynastryJewelryItem(jewelryItem)
              ? jewelryItem.customizationInfo.theme.foreground
              : undefined
            const formattedJewelryItemType = startCase(jewelryType)
            const formattedBackgroundColor = startCase(backgroundColor)
            const formattedForegroundColor = startCase(foregroundColor)

            return (
              <div key={index}>
                <div
                  className={`w-20 h-20 lg:w-32 lg:h-32`}
                  key={jewelryItem?.id ?? `empty-${index}`}
                >
                  {isCreatingBatchManually && !isEmptySlot && (
                    <div className='relative'>
                      <div className='absolute z-10 -top-2 -right-2'>
                        <button
                          className='outline-none'
                          onClick={() => {
                            handleRemoveJewelryItem(jewelryItem!)
                          }}
                        >
                          <HiOutlineXCircle className='w-10 transition-transform transform rounded-full shadow-md hover:scale-110 bg-darkBlue text-rose-500' />
                        </button>
                      </div>
                    </div>
                  )}
                  <div
                    className={`
                    rounded-full h-full w-full
                    ${isSelected ? 'shadow-md' : ''}
                  `}
                  >
                    <Slot
                      jewelryItem={jewelryItem}
                      forceFlip={forceFlip}
                      index={index}
                      isSelectingItems={isSelectingItems}
                      onSelect={handleSelectJewelryItem}
                      isSelected={isSelected}
                    />
                  </div>
                </div>
                {jewelryItem && (
                  <div className='flex justify-center w-full mt-2 text-xs opacity-75 text-gray-50'>
                    <div className='flex flex-col items-center'>
                      <div className='font-bold'>
                        #{jewelryItem.uniqueOrderName}
                      </div>
                      <div>{formattedJewelryItemType}</div>
                      <div>
                        {formattedForegroundColor &&
                          `${formattedForegroundColor}/`}
                        {formattedBackgroundColor}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className='text-amber-300'>
        <div className='flex justify-between'>
          <div className='flex flex-col items-start w-1/2'>
            <span className='font-mono text-3xl'>
              Batch #{`${batch.batchNumber}${isPreview ? ' Preview' : ''}`}
            </span>
            <span className='font-bold'>
              {numberOfItems} item{numberOfItems > 1 ? 's' : ''}
            </span>
            {dateOfCreation && (
              <span className='text-lg'>{dateOfCreation}</span>
            )}
          </div>
          {isProductionBatch(batch) && (
            <div className='w-1/2'>
              <span className='font-bold'>Current status:</span>{' '}
              {startCase(batch.status)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

Jig.ActionMenu = ActionMenu
