export function capitalize(str: string): string {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

export function isValidEmail(email: string): boolean {
  const validEmailRegex =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

  return email.match(validEmailRegex) ? true : false
}

/**
 * @abstract Checks if the given string is invalid. An invalid string is either one of the below:
 * 1. Is `null`
 * 2. Is `undefined`
 * 3. Is empty
 * 4. Is composed completely by whitespaces
 */
export function isInvalidString(str: string | undefined | null): boolean {
  return (str ?? '').trim().length === 0
}

export function padZero(value: string | number, numberOfZeroes = 1) {
  const stringValue = String(value)

  return stringValue.length < numberOfZeroes + 1
    ? `${new Array(numberOfZeroes)
        .fill(null)
        .reduce(acc => `0${acc}`, stringValue)}`
    : stringValue
}
