import type { FC } from 'react'
import type { Column } from 'react-table'

import React, { useState, useMemo } from 'react'
import { useTable } from 'react-table'
import { groupBy, join, map, startCase, uniqBy } from 'lodash'
import { DateTime } from 'luxon'
import { useToasts } from 'react-toast-notifications'

import { createReportsBacklogGroup } from '@ephemeris/fulfillment/src/reports-backlog'
import { ALL_REPORTS_BACKLOG_ITEMS_STATUSES } from '@ephemeris/constants/src/fulfillment/reports-backlog'
import Loader from '@ephemeris/react-components/src/Loader'

interface ReportsBacklogTableProps {
  searchItems: ReportsBacklog.Item[]
  backlogItems: ReportsBacklog.Item[]
  onRowClick(backlogGroup: ReportsBacklog.Group): void
  onLoadNextPage: () => void
}

interface Filter {
  statuses: ReportsBacklog.Status[]
}

type SortingMethod = keyof ReportsBacklog.Item
type SortingDirection = 'ascending' | 'descending'

function getCellDisplayValueTransformer(
  accessor: Column<ReportsBacklog.Group>['accessor']
): (value: any) => JSX.Element {
  return (value: any): JSX.Element => {
    switch (accessor) {
      case 'orderCreatedAt':
        return <>{DateTime.fromISO(value).toFormat('yyyy-MM-dd, HH:mm')}</>
      case 'status':
      case 'salesChannel':
        return <>{startCase(value)}</>
      case 'items': {
        const backlogItems = value as ReportsBacklog.Item[]
        const uniqueBacklogItems = uniqBy(
          backlogItems,
          ({ lineItemId, nameOnReport }) => `${lineItemId}-${nameOnReport}`
        )
        const names = join(
          map(uniqueBacklogItems, ({ nameOnReport }) => nameOnReport).sort(
            (a, b) => a.localeCompare(b)
          ),
          ', '
        )
        return <>{names}</>
      }
      default:
        return <>{value}</>
    }
  }
}

const CopyTextIcon = (): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
  >
    <rect x='9' y='9' width='13' height='13' rx='2' ry='2'></rect>
    <path d='M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1'></path>
  </svg>
)

const ExternalLinkIcon = (): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
    className='w-4 h-4'
  >
    <path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'></path>
    <polyline points='15 3 21 3 21 9'></polyline>
    <line x1='10' y1='14' x2='21' y2='3'></line>
  </svg>
)

export const ReportsBacklogTable: FC<ReportsBacklogTableProps> = ({
  searchItems,
  backlogItems,
  onRowClick,
  onLoadNextPage,
}) => {
  const { addToast } = useToasts()
  const [activeFilters, setActiveFilters] = useState<Filter>({
    statuses: ALL_REPORTS_BACKLOG_ITEMS_STATUSES,
  })
  const [activeSorting, setActiveSorting] = useState<{
    sortingMethod: SortingMethod
    sortingDirection: SortingDirection
  }>({ sortingMethod: 'orderCreatedAt', sortingDirection: 'descending' })

  function handleTableHeaderClick(accessor: SortingMethod): void {
    const direction =
      activeSorting.sortingDirection === 'ascending'
        ? 'descending'
        : 'ascending'

    setActiveSorting({ sortingDirection: direction, sortingMethod: accessor })
  }

  const tableItems = useMemo(() => {
    // const filteredBacklogItems = backlogItems.filter(({ status }) =>
    //   activeFilters.statuses.includes(status)
    // )

    const items = searchItems.length > 0 ? searchItems : backlogItems

    const groupedBacklogItems = groupBy(items, 'groupId')
    const groups = map(groupedBacklogItems, createReportsBacklogGroup)
    const sortedGroups = groups.sort((a, b) => {
      const { sortingMethod } = activeSorting
      const [itemA, itemB] =
        activeSorting.sortingDirection === 'ascending' ? [a, b] : [b, a]

      const [valueA, valueB] = [itemA, itemB].map(item => item[sortingMethod])

      return `${valueA}`.localeCompare(`${valueB}`)
    })

    return sortedGroups
  }, [backlogItems, activeSorting, activeFilters, searchItems])

  const columns = useMemo<Column<ReportsBacklog.Group>[]>(
    () => [
      {
        Header: 'Order Id',
        accessor: 'orderId',
      },
      {
        Header: 'Names',
        accessor: 'items',
      },
      {
        Header: 'Email',
        accessor: 'destinationEmail',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Created At',
        accessor: 'orderCreatedAt',
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: tableItems })

  return (
    <table {...getTableProps()} className={`w-full`}>
      <thead className='border-blueGray-700 sticky top-0 border-b-2 shadow-md'>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => {
              const { sortingMethod, sortingDirection } = activeSorting
              const isCurrentSortingMethod =
                sortingMethod === columns[index].accessor
              const sortingDirectionArrow =
                sortingDirection === 'ascending' ? '▲' : '▼'

              return (
                <th
                  className='bg-gray-50 text-ephemerisBlue p-4 font-bold cursor-pointer'
                  onClick={(): void => {
                    const { accessor } = columns[index]
                    handleTableHeaderClick(accessor as SortingMethod)
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                  {isCurrentSortingMethod && ` ${sortingDirectionArrow}`}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row)

          const group = tableItems[rowIndex]
          const rowBackgroundColor =
            rowIndex % 2 === 0 ? 'bg-blueGray-50' : 'bg-blueGray-100'

          return (
            <tr
              {...row.getRowProps()}
              onClick={(): void => {
                onRowClick(group)
              }}
              className={`cursor-pointer bg-gray-50 hover:bg-opacity-25 ${rowBackgroundColor}`}
            >
              {row.cells.map((cell, columnIndex: number) => {
                const { accessor } = columns[columnIndex] as {
                  accessor: keyof ReportsBacklog.Group
                }
                const transformCellDisplayValue =
                  getCellDisplayValueTransformer(accessor)
                const group = tableItems[rowIndex]
                const { status } = group
                const value = group[accessor]
                const displayValue = transformCellDisplayValue(value)
                const backgroundColor = ((): string => {
                  switch (status) {
                    case 'SuccessfullyGenerated':
                      return 'bg-emerald-50'
                    case 'WaitingForMissingData':
                      return 'bg-amber-400'
                    case 'Delivered':
                      return 'bg-emerald-200'
                    case 'FailedToBeGenerated':
                      return 'bg-rose-500'
                    case 'BeingGenerated':
                      return 'bg-amber-100'
                    default:
                      return rowBackgroundColor
                  }
                })()

                return (
                  <td
                    {...cell.getCellProps()}
                    className={`p-4 border border-solid border-ephemerisBlue text-ephemerisBlue ${
                      accessor === 'status' && backgroundColor
                    }`}
                  >
                    {accessor === 'orderId' ? (
                      <a
                        onClick={(e): void => e.stopPropagation()}
                        href={
                          group.salesChannel === 'shopify'
                            ? `https://ephemeris-co.myshopify.com/admin/orders/${group.orderId}`
                            : `https://www.etsy.com/your/orders/sold/completed?order_id=${group.orderId}`
                        }
                        target='_blank'
                        className='flex items-center underline'
                      >
                        {displayValue}
                        <div className='ml-2'>
                          <ExternalLinkIcon />
                        </div>
                      </a>
                    ) : accessor === 'destinationEmail' ? (
                      <div className='flex items-center justify-between'>
                        {displayValue}
                        <button
                          className='w-5 h-5'
                          onClick={async (e): Promise<void> => {
                            const { destinationEmail } = group

                            e.stopPropagation()
                            await navigator.clipboard.writeText(
                              destinationEmail
                            )

                            addToast(
                              `The email '${destinationEmail}' has been copied!`,
                              { appearance: 'info', autoDismiss: true }
                            )
                          }}
                        >
                          <CopyTextIcon />
                        </button>
                      </div>
                    ) : accessor === 'status' && status === 'BeingGenerated' ? (
                      <div className='flex items-center justify-between'>
                        {displayValue}
                        <Loader style='dark' />
                      </div>
                    ) : (
                      displayValue
                    )}
                  </td>
                )
              })}
            </tr>
          )
        })}
        <tr>
          <td className='h-16'>
            <div className='flex items-center justify-center w-full h-full p-4'>
              <button onClick={onLoadNextPage} className='hover:underline'>
                Load More
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
