import { map } from 'lodash'
import { isNil } from 'lodash/fp'

export function updateJewelryItem(
  jewelryItems: JewelryProduction.Item[] | undefined,
  jewelryItemToBeUpdated: JewelryProduction.Item
): JewelryProduction.Item[] | undefined {
  console.log(`--- will update jewelryItem: `, jewelryItemToBeUpdated)
  if (isNil(jewelryItems)) {
    console.log(`--- nope. is nill`)
    return
  }

  return map(jewelryItems, jewelryItem =>
    jewelryItem.id === jewelryItemToBeUpdated.id
      ? jewelryItemToBeUpdated
      : jewelryItem
  )
}

export function refreshJewelryItems(): JewelryProduction.Item[] | undefined {
  console.log(`--- will refresh`)
  return
}
