import { DateTime, DurationObject, DurationUnit, ZoneOptions } from 'luxon'
import { isNumber, isString } from 'lodash'
import memoize from 'memoizee'

import { absolute, hoursToMinutes } from './math'
import { isFloat } from './number'

const { convertDateTimeToJulianDay, convertJulianDayToDateTime } = (() => {
  const DAY = 86400000
  const HALF_DAY = DAY / 2
  const UNIX_EPOCH_JULIAN_DATE = 2440587.5
  const UNIX_EPOCH_JULIAN_DAY = 2440587

  function convert(date: DateTime) {
    return toJulianDay(date) + toMillisecondsInJulianDay(date) / DAY
  }

  function convertToDate(julianDay: number) {
    return DateTime.fromMillis(
      (Number(julianDay) - UNIX_EPOCH_JULIAN_DATE) * DAY
    )
  }

  function toJulianDay(date: DateTime) {
    return ~~((+date.toJSDate() + HALF_DAY) / DAY) + UNIX_EPOCH_JULIAN_DAY
  }

  function toMillisecondsInJulianDay(date: DateTime) {
    return (+date.toJSDate() + HALF_DAY) % DAY
  }

  return {
    convertDateTimeToJulianDay: convert,
    convertJulianDayToDateTime: convertToDate,
  }
})()

function millisecondsToDays(milliseconds: number): number {
  return milliseconds / 1000 / 24 / 60 / 60
}

function getDateTimeFromJulianDay(julianDay: number, gmtOffset = 0): DateTime {
  try {
    const date = convertJulianDayToDateTime(julianDay)

    return gmtOffset === 0
      ? date
      : adjustGmtOffset(date, gmtOffset, { keepLocalTime: false })
  } catch (error) {
    throw new Error(
      `could not get DateTime. error: ${error.message}. julianDay: ${julianDay}`
    )
  }
}

function getAbsoluteGmtOffsetStringValue(gmtOffset: number): string {
  const absoluteOffset = absolute(gmtOffset)

  if (isFloat(absoluteOffset)) {
    const [wholePart, decimalPart] = `${absoluteOffset}`.split('.')
    const decimal = Number(`0.${decimalPart}`)
    const sexagecimalValue = decimal * 60

    return `${wholePart}:${sexagecimalValue}`
  }

  return `${absoluteOffset}`
}

function adjustGmtOffset(
  date: DateTime,
  gmtOffset: number,
  zoneOptions: ZoneOptions
): DateTime {
  const gmtOffsetSign = gmtOffset < 0 ? '-' : '+'
  const gmtOffsetValue = getAbsoluteGmtOffsetStringValue(gmtOffset)

  return date.setZone(`UTC${gmtOffsetSign}${gmtOffsetValue}`, zoneOptions)
}

function getDateTimeFromBirthInfo(birthInfo: BirthInfo): DateTime {
  const { date: birthDate } = birthInfo
  const { year, month, day, hour, minute, gmtOffset } = birthDate
  const date = DateTime.fromObject({
    year,
    month,
    day,
    hour,
    minute,
  })

  return adjustGmtOffset(date, gmtOffset, { keepLocalTime: true })
}

function getDateTimeFromIsoDate(isoDate: IsoDate, gmtOffset: number): DateTime {
  const date = DateTime.fromISO(isoDate)
  return adjustGmtOffset(date, gmtOffset, { keepLocalTime: true })
}

export function getDateTime(
  source: number | IsoDate | BirthInfo,
  gmtOffset?: number
): DateTime {
  if (isNumber(source)) {
    return getDateTimeFromJulianDay(source, gmtOffset)
  } else if (isString(source)) {
    return getDateTimeFromIsoDate(source, gmtOffset ?? 0)
  }

  return getDateTimeFromBirthInfo(source)
}

export function getJulianDay(date: DateTime): JulianDay {
  const utcDate = date.toUTC()

  try {
    return convertDateTimeToJulianDay(utcDate)
  } catch (error) {
    throw new Error(
      `could not get julian day. error: ${error.message}. date: ${date}`
    )
  }
}

export function now(gmtOffset?: number): DateTime {
  return DateTime.utc().toUTC(hoursToMinutes(gmtOffset ?? 0))
}

export function mapDateTime<TResult>(
  startDate: DateTime,
  endDate: DateTime,
  interval: DurationObject,
  iteratee: (
    date: DateTime,
    index: number,
    startDate: DateTime,
    endDate: DateTime
  ) => TResult
): TResult[] {
  let i = 0
  let currentDate = startDate
  let results: TResult[] = []

  while (currentDate < endDate) {
    const result = iteratee(currentDate, i, startDate, endDate)

    results.push(result)

    i++
    currentDate = currentDate.plus(interval)
  }

  return results
}

export function forEachDateTime(
  startDate: DateTime,
  endDate: DateTime,
  interval: DurationObject,
  iteratee: (
    date: DateTime,
    index: number,
    startDate: DateTime,
    endDate: DateTime
  ) => void
): void {
  let i = 0
  let currentDate = startDate

  while (currentDate < endDate) {
    iteratee(currentDate, i, startDate, endDate)

    i++
    currentDate = currentDate.plus(interval)
  }
}

export function hasSame(
  dateA: DateTime,
  dateB: DateTime,
  ...units: DurationUnit[]
): boolean {
  let has = true

  units.forEach(unit => {
    if (!dateA.hasSame(dateB, unit)) {
      has = false
      return
    }
  })

  return has
}

export function getNumberOfDaysBetween(
  dateA: DateTime,
  dateB: DateTime
): number {
  return Math.ceil(
    Math.abs(millisecondsToDays(dateA.diff(dateB).toObject().milliseconds!))
  )
}

export function isBetween(
  date: DateTime,
  dates: [DateTime, DateTime]
): boolean {
  const [dateA, dateB] = dates.sort((a, b) => +a - +b)

  return date >= dateA && date <= dateB
}

export function getTimeFromNow(duration: DurationObject): DateTime {
  return DateTime.utc().plus(duration)
}

export function convertDateTimeToUnixTimestamp(date: DateTime): number {
  return date.toSeconds()
}

export const asUtcIso = (isoDate: string) => {
  const date = DateTime.fromISO(isoDate)

  return date.toUTC().toISO()
}

const fetchGmtOffsetFromUrl = memoize(async (url: string) => {
  const response = await fetch(url)
  const body = await response.json()
  const { offset } = body

  return offset
})

export function getGmtOffset(birthInfo: BirthInfo): Promise<number> {
  const baseUrl = new URL(
    'https://s4u3fh1e4e.execute-api.us-east-1.amazonaws.com/dev/get-timezone-offset'
  )
  const { searchParams } = baseUrl
  const {
    date: { year, month, day },
    location: { latitude, longitude },
  } = birthInfo

  searchParams.set('year', `${year}`)
  searchParams.set('month', `${month}`)
  searchParams.set('day', `${day}`)
  searchParams.set('latitude', `${latitude}`)
  searchParams.set('longitude', `${longitude}`)

  const url = baseUrl.toString()

  return fetchGmtOffsetFromUrl(url)
}
