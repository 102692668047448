import React, { FC } from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import Batch from './Batches/Batch'
import Batches from './Batches'
import CreateBatch from './Batches/Create'
import JewelryItems from './JewelryItems'
import ProductionProvider from './ProductionProvider'

export const Production: FC<RouteComponentProps> = () => {
  return (
    <ProductionProvider>
      <Router>
        <Batches path='/batches/*' />
        <Batch path='/batches/:batchId' />
        <CreateBatch path='/batches/create' />
        <JewelryItems path='/*' />
      </Router>
    </ProductionProvider>
  )
}
