import React, { FC, useState, useCallback, useRef } from 'react'
import { capitalize, findIndex, isEmpty, isNil, map, startCase } from 'lodash'
import { Redirect, Link, Router } from '@reach/router'
import { useAuth0 } from '@auth0/auth0-react'
import {
  HiOutlineDuplicate,
  HiOutlineExternalLink,
  HiOutlineQuestionMarkCircle,
  HiExclamationCircle,
  HiChevronDown,
} from 'react-icons/hi'
import { Disclosure } from '@headlessui/react'
import { DateTime } from 'luxon'
import { useDropzone } from 'react-dropzone'
import { useToasts } from 'react-toast-notifications'
import ReactTooltip from 'react-tooltip'

import { getDateTime } from '@ephemeris/utils/src/DateTime'
import {
  isSuspendedJewelryProductionItem,
  getJewelryItemById,
  isBirthChartJewelryItem,
} from '@ephemeris/utils/src/jewelry-production'
import { MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE } from '@ephemeris/constants/src/fulfillment/jewelry-production'
import Edit from './Edit'
import { getJigSlotCoordinate } from '@ephemeris/fulfillment/src/jig'
import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import Loader from '@ephemeris/react-components/src/Loader'
import StatusLabel from '../../../StatusLabel'
import { isEither } from '@ephemeris/utils/src/compare'
import History from './History'
import useProductionContext from '../../useProductionContext'
import { ACTIONS } from '../../ProductionProvider/Store'
import { RouteComponent } from '@ephemeris/types/src/reach-router'
import { ORDERED_BATCH_PRODUCTION_STATUSES } from '@ephemeris/constants/src/fulfillment/jewelry-production'

type JewelryItemProps = RouteComponent & {
  jewelryItemId?: string
}

type CustomizationInfoProps = RouteComponent & {
  jewelryItem: JewelryProduction.AnyItem
  isUploadingCustomRender: boolean
}

type EditButtonProps = RouteComponent & {
  jewelryItem: JewelryProduction.Item
}

interface InfoRowProps {
  title: string
  value: string
  copyValue?: boolean
  valueStyle?: 'plain' | 'text-field'
}

function formatShippingAddress(
  shippingAddress: JewelryProduction.ShippingAddress
) {
  const {
    address1,
    address2,
    firstName,
    lastName,
    province,
    city,
    country,
    postalCode,
  } = shippingAddress

  return `${firstName} ${lastName}, ${address1}${
    !isNil(address2) ? ` ${address2}` : ''
  }, ${city}${
    !isNil(province) ? `, ${province}` : ''
  }, ${country} – ${postalCode}`
}

function formatBirthDate(birthInfo: BirthInfo) {
  const birthDate = (() => {
    try {
      return getDateTime(birthInfo)
    } catch {
      return undefined
    }
  })()

  if (isNil(birthDate)) {
    return 'invalid date'
  }

  return birthDate.toFormat('MMMM dd, yyyy, HH:mm ZZZZ')
}

function formatThemeText(theme: JewelryProduction.JeweleryTheme) {
  const { foreground = '', background = '' } = theme

  if (isEmpty(foreground) || isEmpty(background)) {
    return 'unknown'
  }

  return `${startCase(foreground)} details on ${startCase(
    background
  )} background`
}

const Subheading: FC = ({ children }) => (
  <div>
    <h3 className='text-lg font-semibold'>{children}</h3>
  </div>
)

const InfoRow: FC<InfoRowProps> = ({ title, value, copyValue, valueStyle }) => {
  return (
    <div className='flex items-center leading-loose'>
      <span className='font-semibold'>{title}</span>:{' '}
      <span className='ml-1'>
        {valueStyle === 'plain' ? (
          <span className='font-light'>{value}</span>
        ) : valueStyle === 'text-field' ? (
          <input
            type='text'
            defaultValue={value}
            contentEditable={false}
            className='px-2 font-mono border rounded-md border-ephemerisBlue bg-gray-50'
          />
        ) : (
          <>{value}</>
        )}
      </span>
      {copyValue && (
        <HiOutlineDuplicate
          className='w-6 ml-1 cursor-pointer text-ephemerisBlue'
          onClick={async () => {
            await navigator.clipboard.writeText(value)
          }}
        />
      )}
    </div>
  )
}

const Errors: FC<{ errors?: JewelryProduction.ItemError[] }> = ({
  errors = [],
}) => {
  if (isEmpty(errors)) {
    return null
  }

  return (
    <div className='p-2 border-2 rounded-md border-rose-500'>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className='flex items-center outline-none text-rose-500'>
              <span>Errors</span>
              <HiChevronDown
                className={`
              w-5 ml-1
              ${open ? 'transform rotate-180' : ''}
            `}
              />
            </Disclosure.Button>
            <Disclosure.Panel className='text-gray-500'>
              {map(errors, ({ name, message, operation, date }, index) => (
                <div key={`error-${index}`} className='pl-1'>
                  <span className='font-bold text-rose-500'>
                    • Error #{index + 1}
                  </span>
                  <div className='pl-3'>
                    <div>
                      <span className='font-mono font-bold'>Date</span>:{' '}
                      {DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)}
                    </div>
                    <div>
                      <span className='font-mono font-bold'>Operation</span>:{' '}
                      {operation}
                    </div>
                    <div>
                      <span className='font-mono font-bold'>Name</span>: {name}
                    </div>
                    <div>
                      <span className='font-mono font-bold'>Message</span>:{' '}
                      {message}
                    </div>
                  </div>
                </div>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

const CustomizationInfo: FC<CustomizationInfoProps> = ({
  jewelryItem,
  isUploadingCustomRender,
}) => {
  const { missingProperties, errors, renders, jewelryType, customizationInfo } =
    jewelryItem
  const { engraving } = customizationInfo
  const { firstLine = '', secondLine = '' } = engraving ?? {}
  const { front: frontRenderUrl = '', back: backRenderUrl = null } =
    renders ?? {}
  const jewelryTypeName = capitalize(jewelryType)
  const birthChartRenderImgRef = useRef<HTMLImageElement>(null)

  return (
    <>
      {!isEmpty(missingProperties) && (
        <div className='flex flex-col text-rose-500'>
          <InfoRow title='Missing info' value='' />
          {map(missingProperties, (property, index) => (
            <span className={`property-${index}`} key={index}>
              • {property}
            </span>
          ))}
        </div>
      )}
      {isBirthChartJewelryItem(jewelryItem) &&
        (() => {
          const {
            customizationInfo: { birthInfo },
          } = jewelryItem
          const birthDateText = formatBirthDate(birthInfo as BirthInfo)
          return <InfoRow title='Birth Date' value={birthDateText} />
        })()}
      <InfoRow title='Type' value={jewelryTypeName} />
      {isBirthChartJewelryItem(jewelryItem) &&
        (() => {
          const {
            customizationInfo: { theme },
          } = jewelryItem
          const themeText = formatThemeText(theme)
          return <InfoRow title='Colors' value={themeText} />
        })()}
      <div>
        <span className='font-bold'>Engraving:</span>
        {isEmpty(firstLine) && isEmpty(secondLine) ? (
          <span className='italic'> none</span>
        ) : (
          <div className='px-2 py-1'>
            <div className='flex items-center'>
              <InfoRow title='First Line' value={firstLine} />
              {firstLine.length > MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE ? (
                <span className='flex items-center ml-2 text-amber-500'>
                  <HiExclamationCircle className='w-6 mr-1' />
                  More than {MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE}{' '}
                  characters
                </span>
              ) : null}
            </div>
            <div className='flex items-center'>
              <InfoRow title='Second Line' value={secondLine} />
              {secondLine.length >
              MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE ? (
                <span className='flex items-center ml-2 text-amber-500'>
                  <HiExclamationCircle className='w-6 mr-1' />
                  More than {MAX_NUMBER_OF_CHARACTERS_IN_ENGRAVING_LINE}{' '}
                  characters
                </span>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <InfoRow title='Renders' value={''} />
      <div className='flex max-w-2xl'>
        <div className='w-1/2 overflow-hidden border rounded-full shadow-md border-ephemerisBlue'>
          {isUploadingCustomRender && (
            <div className='relative'>
              <div
                className='absolute inset-0 flex items-center justify-center h-full bg-white bg-opacity-90'
                style={{ height: birthChartRenderImgRef.current?.height }}
              >
                <div className='flex flex-col items-center'>
                  <Loader style='dark' size='lg' />
                  <span className='text-lg font-bold'>
                    Uploading custom render
                  </span>
                </div>
              </div>
            </div>
          )}
          <img src={frontRenderUrl} ref={birthChartRenderImgRef} />
        </div>
        <div className='w-1/2 overflow-hidden border rounded-full shadow-md border-ephemerisBlue'>
          <img src={backRenderUrl ?? ''} />
        </div>
      </div>

      <div className='mt-8' />
      <Errors errors={errors} />
    </>
  )
}

const EditButton: FC<EditButtonProps> = ({ jewelryItem }) => {
  return (
    <Link to='edit' className='underline' state={{ jewelryItem }}>
      Edit info
    </Link>
  )
}

export const JewelryItem: FC<JewelryItemProps> = ({ jewelryItemId }) => {
  const { jewelryItems } = useProductionContext()

  if (isNil(jewelryItems) || isNil(jewelryItemId)) {
    return <Redirect to='/production' />
  }

  const jewelryItem = getJewelryItemById(jewelryItems, jewelryItemId)

  if (isNil(jewelryItem)) {
    return <Redirect to='/production' />
  }

  async function toggleSuspensionStatus() {
    if (!!isNil(jewelryItem)) {
      return
    }

    setIsChangingSuspensionStatus(true)

    const { id } = jewelryItem

    const routeSufix =
      jewelryItem.status === 'Suspended' ? 'suspend' : 'unsuspend'

    try {
      const response = await queryFulfillmentApi(
        `/jewelry-production/item/${id}/${routeSufix}`,
        {
          suspensionReason: suspensionReasonTextAreaRef.current?.value,
          suspenderName: user.name,
        },
        'PUT'
      )
      const responseBody = await response.json()

      if (response.status > 299) {
        throw responseBody.data.error
      }

      const { jewelryItem: updatedJewelryItem } = responseBody as {
        jewelryItem: JewelryProduction.Item
      }

      dispatch({
        type: ACTIONS.UPDATE_JEWELRY_ITEM,
        payload: { jewelryItem: updatedJewelryItem },
      })
    } catch (error) {
      addToast(`Could not change suspension status. Error: ${error.message}`, {
        appearance: 'error',
      })
    } finally {
      setIsChangingSuspensionStatus(false)
    }
  }

  const { dispatch } = useProductionContext()
  const [isChangingSuspensionStatus, setIsChangingSuspensionStatus] =
    useState(false)
  const [isConfirmingSuspend, setIsConfirmingSuspend] = useState(false)
  const [isUploadingCustomRender, setIsUploadingCustomRender] = useState(false)
  const { addToast } = useToasts()
  const bottomDivRef = useRef<HTMLDivElement>(null)
  const { getAccessTokenSilently, user } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)
  const suspensionReasonTextAreaRef = useRef<HTMLTextAreaElement>(null)

  const onDragEnter = useCallback(() => {
    bottomDivRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const onDropRejected = useCallback(files => {
    const [file] = files
    const { errors } = file
    const [{ message }] = errors

    addToast(message, { appearance: 'error', autoDismiss: true })
  }, [])

  const onDropAccepted = useCallback(files => {
    if (files.length > 1) {
      addToast('Please, select only 1 image', {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    const [file] = files
    const reader = new FileReader()

    setIsUploadingCustomRender(true)

    reader.onload = async () => {
      const base64DataUrl = reader.result as string
      const response = await queryFulfillmentApi(
        `/jewelry-production/item/${jewelryItem.id}/add-custom-render`,
        { renderImageBase64DataUrl: base64DataUrl },
        'PUT'
      )

      if (response.status > 299) {
        const responseBody = await response.json()
        const {
          data: { error },
        } = responseBody

        addToast(`Could not add custom render image. Error: ${error.message}`)
        return
      }

      setIsUploadingCustomRender(false)
      addToast('Successfully added custom render', {
        autoDismiss: true,
        appearance: 'success',
      })
    }
    reader.readAsDataURL(file)
  }, [])

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDragEnter,
    onDropRejected,
    onDropAccepted,
    accept: ['image/png'],
    noClick: true,
  })
  const { batchInfo } = jewelryItem
  const { customer, orderCreatedAt, status, shippingAddress, uniqueOrderName } =
    jewelryItem
  const { phone } = shippingAddress
  const { firstName, lastName, email } = customer

  const orderDateText = DateTime.fromISO(orderCreatedAt).toFormat(
    'MMMM dd, yyyy, HH:mm ZZZZ'
  )
  const shippingAddressText = formatShippingAddress(shippingAddress)

  const canEditItem = (() => {
    const { productionStatus } = jewelryItem
    const productionStatusIndex = isNil(productionStatus)
      ? 0
      : findIndex(
          ORDERED_BATCH_PRODUCTION_STATUSES,
          status => status === productionStatus
        )
    const printStatusIndex = findIndex(
      ORDERED_BATCH_PRODUCTION_STATUSES,
      status => status === 'Print'
    )

    return productionStatusIndex < printStatusIndex
  })()

  return (
    <div className='text-darkBlue' {...getRootProps()}>
      {isDragActive && (
        <div className='relative'>
          <div className='fixed inset-0 bg-gray-900 bg-opacity-25'>
            <div className='w-full h-full p-8'>
              <div className='flex items-center justify-center w-full h-full border-white border-dashed rounded-lg border-[1.25rem] px-8'>
                <div className='flex flex-col items-center'>
                  <span className='p-8 text-4xl font-bold text-center bg-white bg-opacity-75 rounded-lg text-darkBlue'>
                    Upload custom birth chart render
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='p-8'>
        {isChangingSuspensionStatus ? (
          <Loader style='dark' />
        ) : (
          <StatusLabel jewelryItem={jewelryItem} />
        )}
        <div className='flex items-center'>
          <h1 className='mr-2 text-2xl font-black'>#{uniqueOrderName}</h1>|
          <div className='flex items-center ml-2'>
            <a
              className='underline'
              href={`https://ephemeris-co.myshopify.com/admin/orders/${jewelryItem.orderId}`}
              target='_blank'
            >
              Open order in Shopify
            </a>
            <HiOutlineExternalLink className='w-4 ml-1' />
          </div>
        </div>
        {isSuspendedJewelryProductionItem(jewelryItem) && (
          <div className='flex items-center'>
            <InfoRow title='Reason' value={jewelryItem.suspensionReason} />
            <div className='mx-3'>|</div>
            <InfoRow title='Suspended by' value={jewelryItem.suspendedBy} />
          </div>
        )}

        {isEither(status, ['ReadyForProduction', 'Suspended']) &&
          !isConfirmingSuspend && (
            <div className='flex items-center'>
              <button
                className='focus:outline-none'
                onClick={() => {
                  if (isSuspendedJewelryProductionItem(jewelryItem)) {
                    toggleSuspensionStatus()
                  } else {
                    setIsConfirmingSuspend(true)
                  }
                }}
              >
                {isSuspendedJewelryProductionItem(jewelryItem)
                  ? 'Unsuspend'
                  : 'Suspend from production temporarily'}
              </button>
              <HiOutlineQuestionMarkCircle
                data-tip='Stops this item from being included in preview batches until it is manually unsuspended'
                className='w-4 ml-1 outline-none cursor-help'
              />
              <ReactTooltip
                type='dark'
                multiline={true}
                effect='solid'
                className='max-w-xs text-center'
              />
            </div>
          )}
        {isConfirmingSuspend && (
          <div className='mt-4'>
            <div className='text-sm font-bold'>
              Why is this item being temporarily suspended?
            </div>
            <textarea
              className='p-2 mt-1 border-2 rounded-md border-lightGold w-lg'
              ref={suspensionReasonTextAreaRef}
            ></textarea>
            <div className='flex items-center mt-2 text-sm'>
              <button
                className='p-2 border-2 rounded-md focus:outline-none text-gray-50 border-amber-400 bg-amber-400'
                onClick={() => {
                  toggleSuspensionStatus()
                  setIsConfirmingSuspend(false)
                }}
              >
                Suspend
              </button>
              <button
                className='ml-2 underline focus:outline-none'
                onClick={() => {
                  setIsConfirmingSuspend(false)
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        <div className='flex flex-col mt-2'>
          {!isNil(batchInfo) ? (
            <>
              <Subheading>Batch info:</Subheading>
              <div className='w-full h-1 mb-3 bg-ephemerisBlue' />

              <div className='flex items-center gap-2'>
                <InfoRow title='Batch' value={`#${batchInfo.batchNumber}`} />
                <Link
                  to={`/production/batches/${batchInfo.batchId}`}
                  className='underline'
                >
                  Show batch
                </Link>
              </div>
              <InfoRow
                title='Coordinate in Jig'
                value={getJigSlotCoordinate(batchInfo.jigSlotIndex)}
              />
              <InfoRow
                title='Production status:'
                value={startCase(jewelryItem.productionStatus)}
              />
            </>
          ) : null}

          {/**
           * Order Info
           */}
          <div className='mt-8' />
          <Subheading>Order info</Subheading>
          <div className='w-full h-1 mb-3 bg-ephemerisBlue' />

          <InfoRow title='Customer' value={`${firstName} ${lastName}`} />
          <InfoRow
            title='Customer email'
            value={email}
            valueStyle='text-field'
            copyValue
          />
          <InfoRow
            title='Customer phone number'
            value={phone ?? 'not informed'}
            valueStyle='text-field'
            copyValue={!!phone}
          />

          <InfoRow title='Order date' value={orderDateText} />

          <InfoRow title='Shipping address' value={shippingAddressText} />

          <div className='mt-4' />
          {jewelryItem.reportsDownloadShortUrl && (
            <>
              <InfoRow title='Reports Download Page QR Code' value='' />
              <div className='mt-4' />
              <img
                src={`${jewelryItem.reportsDownloadShortUrl}.qr?size=128`}
                className='max-w-[128px]'
              />
            </>
          )}

          {/**
           * Customization Info
           */}
          <div className='mt-8' />
          <div className='flex items-center justify-between'>
            <Subheading>Customization info</Subheading>
            {canEditItem ? (
              <Router>
                <EditButton jewelryItem={jewelryItem} path='/' />
              </Router>
            ) : (
              <>
                <a
                  data-tip='Cannot edit an item after it moved to Print status'
                  className='underline opacity-75 cursor-not-allowed text-darkBlue'
                >
                  Edit info
                </a>
                <ReactTooltip type='error' effect='float' />
              </>
            )}
          </div>
          <div className='w-full h-1 mb-3 bg-ephemerisBlue' />

          <Router>
            <CustomizationInfo
              jewelryItem={jewelryItem as JewelryProduction.AnyItem}
              isUploadingCustomRender={isUploadingCustomRender}
              path='/'
            />
            {canEditItem && <Edit path='edit' />}
          </Router>
          <div ref={bottomDivRef} />

          {/**
           * History
           */}
          <div className='mt-8' />
          <Subheading>History</Subheading>
          <div className='w-full h-1 mb-3 bg-ephemerisBlue' />

          <History jewelryItem={jewelryItem} />
        </div>
      </div>

      <input className='hidden' {...getInputProps()} />
    </div>
  )
}
