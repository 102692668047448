import type { RouteComponent } from '@ephemeris/types/src/reach-router'

import React, { FC, useState, useEffect } from 'react'
import axios from 'axios'
import Zip from 'jszip'
import { navigate as globalNavigate } from '@reach/router'
import {
  isNil,
  findIndex,
  some,
  reject,
  isEmpty,
  map,
  filter,
  isNull,
  startCase,
} from 'lodash'
import { useAuth0 } from '@auth0/auth0-react'
import { saveAs } from 'file-saver'
import { useToasts } from 'react-toast-notifications'
import { Dialog, Menu } from '@headlessui/react'
import {
  HiOutlineCube,
  HiOutlineFolderDownload,
  HiOutlineViewGrid,
  HiOutlineViewGridAdd,
  HiOutlineX,
  HiOutlineDuplicate,
  HiOutlineCheckCircle,
  HiOutlineFastForward,
  HiOutlineMinusCircle,
} from 'react-icons/hi'

import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import {
  createPreviewBatch,
  isPreviewBatch,
  isProductionBatch,
} from '@ephemeris/utils/src/jewelry-production'
import Loader from '@ephemeris/react-components/src/Loader'

import Jig from './Jig'
import { BatchContext } from './useBatchContext'
import useProductionContext from '../../useProductionContext'
import { ACTIONS } from '../../ProductionProvider/Store'

import AdvanceBatchControl from './AdvanceBatchControl'
import { useModal, Modal, useModalContext } from '../../../Modal'
import RemoveItemsModalBody from '../RemoveItemsModalBody'

interface ModalConfig {
  title: string
  message: string
  dismissButtonTitle?: string
  acceptButtonTitle?: string
  declineButtonTitle?: string
  showLoader?: boolean
  onDismiss?: () => void
  onAccept?: () => void
  onDecline?: () => void
  textAreaLabel?: string
  textAreaPlaceholder?: string
}

type BatchProps = RouteComponent<{ batch?: JewelryProduction.AnyBatch }> & {
  batchId?: string
  batch?: JewelryProduction.AnyBatch
  isCreatingBatchManually?: boolean
  onRemoveItemFromBatch?: (jewelryItem: JewelryProduction.Item) => void
}

function getOutputRendersFolderName(batch: JewelryProduction.AnyBatch) {
  return `jewelry-production-batch-${batch.batchNumber}-renders.zip`
}

export const Batch: FC<BatchProps> = ({
  location,
  batchId,
  onRemoveItemFromBatch,
  ...props
}) => {
  const { content: modalContent } = useModalContext()
  const { dispatch } = useProductionContext()
  const receivedBatch = location?.state?.batch ?? props.batch
  const [batch, setBatch] = useState<JewelryProduction.AnyBatch | undefined>(
    isNil(receivedBatch?.jewelryItems) ? undefined : receivedBatch
  )

  const navigate = props.navigate ?? globalNavigate
  const [isSelectingItems, setIsSelectingItems] = useState(false)
  const [selectedJewelryItems, setSelectedJewelryItems] = useState<
    { jewelryItem: JewelryProduction.Item; jigIndex: number }[]
  >([])
  const { showModal, dismissModal } = useModal()
  const [isAdvancingBatch, setIsAdvancingBatch] = useState(false)
  const isCreatingBatchManually = props.isCreatingBatchManually ?? false
  const [modalConfig, setModalConfig] = useState<ModalConfig | undefined>()
  const [batchRenderStatus, setBatchRenderStatus] = useState<{
    status: 'loading' | 'done' | 'error' | 'idle'
    error?: Error
  }>({ status: 'idle' })
  const { getAccessTokenSilently, user } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)
  const { addToast } = useToasts()
  const isEmptyBatch = isEmpty(
    filter(batch?.jewelryItems, jewelryItem => !isNull(jewelryItem))
  )

  const StopSelectItemsMenuActions: FC = () => (
    <Menu.Item>
      <Jig.ActionMenu.Button
        onClick={() => {
          selectItems()
        }}
      >
        <Jig.ActionMenu.Button.Icon>
          <HiOutlineX />
        </Jig.ActionMenu.Button.Icon>
        Stop selection of items
      </Jig.ActionMenu.Button>
    </Menu.Item>
  )

  const SelectItemsMenuAction: FC = () => (
    <Menu.Item>
      <Jig.ActionMenu.Button
        onClick={() => {
          selectItems()
        }}
      >
        <Jig.ActionMenu.Button.Icon>
          <HiOutlineViewGridAdd />
        </Jig.ActionMenu.Button.Icon>
        Select items
      </Jig.ActionMenu.Button>
    </Menu.Item>
  )

  const DownloadBatchRendersMenuAction: FC = () => (
    <Menu.Item>
      <Jig.ActionMenu.Button
        onClick={() => {
          if (!isSelectingItems) {
            renderBatch(batch)
            return
          }

          const jewelryItems = map(batch.jewelryItems, (jewelryItem, index) =>
            some(selectedJewelryItems, ({ jigIndex }) => jigIndex === index)
              ? jewelryItem
              : null
          )

          const partialBatch = { ...batch, jewelryItems }

          renderBatch(partialBatch)
        }}
        className={isRenderingBatches ? `cursor-not-allowed opacity-30` : ''}
      >
        {isRenderingBatches ? (
          <Loader style='dark' />
        ) : (
          <Jig.ActionMenu.Button.Icon>
            <HiOutlineFolderDownload />
          </Jig.ActionMenu.Button.Icon>
        )}
        {isRenderingBatches
          ? 'Downloading batch renders'
          : isEmpty(selectedJewelryItems)
          ? 'Download batch renders'
          : 'Download renders of selected items'}
      </Jig.ActionMenu.Button>
    </Menu.Item>
  )

  const StartBatchProductionMenuAction: FC = () => (
    <Menu.Item>
      <Jig.ActionMenu.Button
        onClick={() => {
          if (isNil(batch)) {
            return
          }

          confirmStartBatchProduction(batch)
        }}
        className={isEmptyBatch ? `cursor-not-allowed opacity-30` : ''}
        disabled={isEmptyBatch}
      >
        <Jig.ActionMenu.Button.Icon>
          <HiOutlineCube />
        </Jig.ActionMenu.Button.Icon>
        Start production of this batch
      </Jig.ActionMenu.Button>
    </Menu.Item>
  )

  const CreateBatchFromPreviewMenuAction: FC = () => (
    <Menu.Item>
      <Jig.ActionMenu.Button
        onClick={() => {
          if (isNil(batch)) {
            return
          }

          navigate(`/production/batches/create`, { state: { batch } })
        }}
      >
        <Jig.ActionMenu.Button.Icon>
          <HiOutlineDuplicate />
        </Jig.ActionMenu.Button.Icon>
        Create batch from preview
      </Jig.ActionMenu.Button>
    </Menu.Item>
  )

  const CreateBatchFromSelectionMenuAction: FC = () => (
    <Menu.Item>
      <Jig.ActionMenu.Button
        onClick={async () => {
          if (isNil(batch)) {
            return
          }

          const jewelryItems = map(
            selectedJewelryItems,
            ({ jewelryItem }) => jewelryItem
          )

          const previewBatch = createPreviewBatch(
            jewelryItems,
            batch.batchNumber
          ).batch

          navigate(`/production/batches/create`, {
            state: { batch: previewBatch },
          })
        }}
      >
        <Jig.ActionMenu.Button.Icon>
          <HiOutlineCheckCircle />
        </Jig.ActionMenu.Button.Icon>
        Create batch from selection
      </Jig.ActionMenu.Button>
    </Menu.Item>
  )

  const RegroupItemsMenuAction: FC = () => (
    <Menu.Item>
      <Jig.ActionMenu.Button
        onClick={() => {
          if (isNil(batch)) {
            return
          }

          setBatch(batch)
        }}
      >
        <Jig.ActionMenu.Button.Icon>
          <HiOutlineViewGrid />
        </Jig.ActionMenu.Button.Icon>
        Regroup items
      </Jig.ActionMenu.Button>
    </Menu.Item>
  )

  const AdvanceBatchMenuAction = () => (
    <Menu.Item>
      <Jig.ActionMenu.Button
        onClick={() => {
          showModal({
            title: 'Advance Batch',
            body: (
              <>
                <div className='rounded-md bg-jigBordeaux text-amber-300'>
                  <AdvanceBatchControl
                    onEndSlide={async () => {
                      showModal({
                        title: 'Advance Batch',
                        body: (
                          <>
                            <div className='rounded-md bg-jigBordeaux text-amber-300'>
                              <AdvanceBatchControl
                                isLoading={true}
                                onEndSlide={async () => {}}
                              />
                            </div>
                            <button
                              className='block mt-4 underline'
                              onClick={dismissModal}
                            >
                              Close
                            </button>
                          </>
                        ),
                        useRootModalComponent: false,
                      })

                      await advanceBatch()
                    }}
                  />
                </div>
                <button className='block mt-4 underline' onClick={dismissModal}>
                  Close
                </button>
              </>
            ),
            useRootModalComponent: false,
          })
        }}
      >
        <Jig.ActionMenu.Button.Icon>
          <HiOutlineFastForward />
        </Jig.ActionMenu.Button.Icon>
        Advance batch
      </Jig.ActionMenu.Button>
    </Menu.Item>
  )

  const RemoveSelectedItemsFromBatchMenuAction: FC = () => (
    <Menu.Item>
      <Jig.ActionMenu.Button
        onClick={() => {
          if (!isProductionBatch(batch)) {
            return
          }

          confirmRemoveSelectedItemsFromBatch(batch)
        }}
      >
        <Jig.ActionMenu.Button.Icon>
          <HiOutlineMinusCircle className='text-rose-500' />
        </Jig.ActionMenu.Button.Icon>
        <span className='text-rose-500'>Remove selected items from batch</span>
      </Jig.ActionMenu.Button>
    </Menu.Item>
  )

  function confirmRemoveSelectedItemsFromBatch(batch: JewelryProduction.Batch) {
    const jewelryItemsToBeRemoved = map(
      selectedJewelryItems,
      ({ jewelryItem }) => jewelryItem
    )

    showModal({
      title: 'Remove items?',
      message: `This action cannot be undone. Removed items will be suspended and won't be included in any future batch until they are manually unsuspended.`,
      body: (
        <RemoveItemsModalBody
          batch={batch}
          jewelryItems={jewelryItemsToBeRemoved}
          onConfirmRemove={async reason => {
            await removeSelectedItemsFromBatch(
              batch,
              jewelryItemsToBeRemoved,
              reason
            )
          }}
        />
      ),
    })
  }

  async function removeSelectedItemsFromBatch(
    batch: JewelryProduction.Batch,
    selectedItems: JewelryProduction.Item[],
    reason: string
  ) {
    showModal({
      title: 'Removing items',
      body: <Loader style='dark' />,
    })

    const suspendedBy = user.name
    const suspensionReason = reason
    const jewelryItemsIds = map(selectedItems, ({ id }) => id)

    try {
      const response = await queryFulfillmentApi(
        `/jewelry-production/batch/${batch.id}/jewelry-items/remove`,
        { jewelryItemsIds, suspendedBy, suspensionReason },
        'DELETE'
      )
      const responseBody = await response.json()

      if (response.status > 299) {
        throw (
          responseBody.data?.error ?? {
            error: { name: 'UnkownError', message: 'Unkown Error' },
          }
        )
      }

      const { batch: updatedBatch } = responseBody

      dispatch({
        type: ACTIONS.UPDATE_PRODUCTION_BATCH,
        payload: { batch: updatedBatch },
      })
      setBatch(updatedBatch)
      setSelectedJewelryItems([])
      addToast('The items were removed', {
        appearance: 'info',
        autoDismiss: true,
      })
      dismissModal()
    } catch (error) {
      showModal({
        title: 'Could not remove items',
        message: `Error: ${error.message}`,
        body: (
          <div className='flex gap-2 mt-4'>
            <button
              className='underline focus:outline-none'
              onClick={() => {
                removeSelectedItemsFromBatch(batch, selectedItems, reason)
              }}
            >
              Retry
            </button>
            <button
              className='underline focus:outline-none'
              onClick={dismissModal}
            >
              Close
            </button>
          </div>
        ),
      })
    }
  }

  async function startBatchProduction(batch: JewelryProduction.PreviewBatch) {
    setModalConfig({
      title: 'Starting batch production',
      message: `This can take a few seconds`,
      showLoader: true,
      dismissButtonTitle: 'Close',
    })

    try {
      const data = await queryFulfillmentApi(
        'jewelry-production/batches/start-production',
        { batch },
        'POST'
      )

      const responseBody = await data.json()

      if (data.status !== 201) {
        const {
          data: { error },
        } = responseBody

        throw error
      }

      const { batch: createdBatch } = responseBody as {
        batch: JewelryProduction.Batch
      }

      setBatch(createdBatch)

      dispatch({
        type: ACTIONS.ADD_PRODUCTION_BATCH,
        payload: { batch: createdBatch },
      })

      addToast('Batch is now in production', {
        autoDismiss: true,
        appearance: 'success',
      })

      navigate(`/production/batches/${createdBatch.id}`, {
        replace: true,
        state: { batch: createdBatch },
      })

      setModalConfig(undefined)
    } catch (error) {
      console.log(`--- could not start batch production. Error:`, error)

      setModalConfig({
        title: 'Could not create batch',
        message: `Error: ${error.message}`,
        dismissButtonTitle: 'Ok',
      })
    }
  }

  async function confirmStartBatchProduction(
    batch: JewelryProduction.PreviewBatch
  ) {
    console.log(`--- will start:`, batch)

    setModalConfig({
      title: 'Start production of this batch?',
      message: "This action can't be undone",
      acceptButtonTitle: 'Yes, start',
      declineButtonTitle: 'Cancel',
      onAccept: () => {
        startBatchProduction(batch)
      },
      onDecline: () => {
        setModalConfig(undefined)
      },
    })
  }

  function selectItems() {
    setIsSelectingItems(!isSelectingItems)
    setSelectedJewelryItems([])
  }

  function handleSelectJewelryItem(jewelryItem: JewelryProduction.Item) {
    if (isNil(batch)) {
      return
    }

    const jigIndex = findIndex(
      batch.jewelryItems,
      targetJewelryItem => targetJewelryItem?.id === jewelryItem.id
    )

    const isJewelryItemSelected = some(
      selectedJewelryItems,
      ({ jewelryItem: { id } }) => id === jewelryItem.id
    )

    setSelectedJewelryItems(
      isJewelryItemSelected
        ? reject(
            selectedJewelryItems,
            ({ jewelryItem: { id } }) => id === jewelryItem.id
          )
        : [...selectedJewelryItems, { jewelryItem, jigIndex }]
    )
  }

  async function renderBatch(batch: JewelryProduction.AnyBatch) {
    setBatchRenderStatus({ status: 'loading' })

    const response = await queryFulfillmentApi(
      'jewelry-production/batches/render',
      { batch },
      'POST'
    )
    const responseBody = await response.json()

    if (response.status !== 201) {
      const { error = {} } = responseBody.data ?? {}

      setBatchRenderStatus({
        status: 'error',
        error: {
          name: error.name,
          message:
            error.message ?? response.status === 503 ? 'timeout' : 'unknown',
        },
      })
      return
    }

    const { birthChartsRenderUrl, engravingsRenderUrl, qrCodesRenderUrl } =
      responseBody
    const [birthChartsRender, engravingsRender, qrCodesRender] =
      await Promise.all([
        axios(birthChartsRenderUrl, {
          responseType: 'arraybuffer',
        }),
        axios(engravingsRenderUrl, {
          responseType: 'arraybuffer',
        }),
        axios(qrCodesRenderUrl, {
          responseType: 'arraybuffer',
        }),
      ])

    try {
      const zip = new Zip()
      zip.file('front.jpg', birthChartsRender.data, { binary: true })
      zip.file('engravings.jpg', engravingsRender.data, { binary: true })
      zip.file('qr-codes.jpg', qrCodesRender.data, { binary: true })

      const zipFile = await zip.generateAsync({ type: 'blob' })

      saveAs(zipFile, getOutputRendersFolderName(batch))

      setBatchRenderStatus({ status: 'done' })
    } catch (error) {
      setBatchRenderStatus({
        status: 'error',
        error: { name: error.name, message: error.message },
      })
    }
  }

  async function fetchBatch() {
    const response = await queryFulfillmentApi(
      `jewelry-production/batch/${batchId}`
    )
    const responseBody = await response.json()

    if (response.status !== 200) {
      const {
        data: { error },
      } = responseBody

      setModalConfig({
        title: 'Error loading batch',
        message: `Error: ${error.mesage}`,
        acceptButtonTitle: 'Retry',
        declineButtonTitle: 'Return to batches',
        onAccept: () => {
          fetchBatch()
        },
        onDecline: () => {
          navigate(-1)
        },
      })
      return
    }

    const { batch } = responseBody as { batch: JewelryProduction.Batch }

    setBatch(batch)

    console.log(`--- batch:`, batch)
  }

  async function advanceBatch() {
    setIsAdvancingBatch(true)

    try {
      const response = await queryFulfillmentApi(
        `jewelry-production/batch/${batchId}/advance`,
        null,
        'POST'
      )
      const responseBody = await response.json()

      if (response.status !== 200) {
        const {
          data: { error },
        } = responseBody ?? { data: { error: { message: 'unknown error' } } }

        setModalConfig({
          title: 'Error advancing batch',
          message: `Error: ${error.mesage}`,
          acceptButtonTitle: 'Retry',
          declineButtonTitle: 'Close',
          onAccept: () => {
            advanceBatch()
          },
          onDecline: () => {
            setModalConfig(undefined)
          },
        })
        return
      }

      const oldBatch = batch as JewelryProduction.Batch
      const { batch: updatedBatch } = responseBody as {
        batch: JewelryProduction.Batch
      }

      addToast(
        `Batch state changed from '${startCase(
          oldBatch.status
        )}' to '${startCase(updatedBatch.status)}'`,
        { appearance: 'success', autoDismiss: true }
      )
      dismissModal()

      dispatch({
        type: ACTIONS.UPDATE_PRODUCTION_BATCH,
        payload: { batch: updatedBatch },
      })
      setBatch(updatedBatch)
    } catch (error) {
      setModalConfig({
        title: 'Error advancing batch',
        message: `Error: ${error.mesage}`,
        acceptButtonTitle: 'Retry',
        declineButtonTitle: 'Close',
        onAccept: () => {
          advanceBatch()
        },
        onDecline: () => {
          setModalConfig(undefined)
        },
      })
    } finally {
      setIsAdvancingBatch(false)
    }
  }

  useEffect(() => {
    if (!isNil(batch)) {
      return
    }

    fetchBatch()
  }, [batch])

  useEffect(() => {
    if (isNil(batch)) {
      return
    }

    const { status } = batchRenderStatus

    if (status === 'loading') {
      setModalConfig({
        title: 'Downloading renders',
        message: 'This can take a while depending on the size of the batch',
        showLoader: true,
        dismissButtonTitle: '',
      })
    }

    if (status === 'done') {
      setModalConfig({
        title: 'Download complete',
        message: `Successfully downloaded '${getOutputRendersFolderName(
          batch
        )}'`,
        dismissButtonTitle: 'Close',
        onDismiss: () => {
          setBatchRenderStatus({ status: 'idle' })
        },
      })
    }

    if (status === 'error') {
      const { error } = batchRenderStatus
      setModalConfig({
        title: 'Download failed',
        message: `Error: ${error?.message}`,
        acceptButtonTitle: 'Retry',
        declineButtonTitle: 'Close',
        onAccept: () => {
          renderBatch(batch)
        },
        onDecline: () => {
          setBatchRenderStatus({ status: 'idle' })
        },
      })
    }

    if (status === 'idle') {
      setModalConfig(undefined)
    }
  }, [batchRenderStatus])

  const isRenderingBatches = batchRenderStatus.status === 'loading'
  const isModalOpen = !isNil(modalConfig)
  const {
    message,
    title,
    showLoader,
    dismissButtonTitle,
    acceptButtonTitle,
    declineButtonTitle,
    onAccept,
    onDecline,
    onDismiss,
  } = modalConfig ?? {}
  const shouldUseLocalModal = isNil(modalContent?.useRootModalComponent)
    ? false
    : !modalContent!.useRootModalComponent

  if (isNil(batch)) {
    return (
      <div className='flex flex-col items-center justify-center w-full p-8'>
        <div className='flex items-center gap-2'>
          <Loader style='dark' />
          <h1>Loading batch</h1>
        </div>
      </div>
    )
  }

  return (
    <BatchContext.Provider value={{ batch }}>
      {shouldUseLocalModal && <Modal />}
      {isModalOpen && (
        <Dialog
          open={isModalOpen}
          onClose={() => {
            setModalConfig(undefined)
          }}
          className='fixed inset-0 z-10 overflow-y-auto'
        >
          <div className='flex items-center justify-center min-h-screen'>
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-30' />

            <div className='z-20 w-2/3 p-8 mx-auto bg-white rounded'>
              <Dialog.Title as='h3' className='text-xl font-bold'>
                {title}
              </Dialog.Title>
              <Dialog.Description>{message}</Dialog.Description>

              <div className='flex items-center mt-8'>
                {!isNil(dismissButtonTitle) && (
                  <button
                    onClick={() => {
                      onDismiss && onDismiss()
                      setModalConfig(undefined)
                    }}
                    className='flex items-center underline focus:outline-none'
                  >
                    {showLoader && <Loader style='dark' />}
                    {dismissButtonTitle}
                  </button>
                )}
                {acceptButtonTitle && (
                  <button
                    onClick={onAccept}
                    className='ml-2 first:ml-0 focus:outline-none'
                  >
                    {acceptButtonTitle}
                  </button>
                )}
                {declineButtonTitle && (
                  <button
                    onClick={onDecline}
                    className='ml-2 underline text-rose-500 focus:outline-none'
                  >
                    {declineButtonTitle}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      )}
      <div className='p-12'>
        <div className='flex justify-end w-full px-4'>
          {isSelectingItems
            ? `Selected ${selectedJewelryItems.length}/${batch.jewelryItems.length}`
            : ''}
        </div>
        <Jig
          isRenderingBatches={isRenderingBatches}
          selectedItems={selectedJewelryItems}
          isCreatingBatchManually={isCreatingBatchManually}
          isSelectingItems={isSelectingItems}
          onSelectJewelryItem={handleSelectJewelryItem}
          onRemoveJewelryItem={onRemoveItemFromBatch}
        >
          <Jig.ActionMenu>
            {isSelectingItems ? (
              <StopSelectItemsMenuActions />
            ) : !isCreatingBatchManually ? (
              <SelectItemsMenuAction />
            ) : null}
            {isCreatingBatchManually && !isSelectingItems && (
              <RegroupItemsMenuAction />
            )}
            {isProductionBatch(batch) && !isSelectingItems && (
              <DownloadBatchRendersMenuAction />
            )}
            {isSelectingItems && <CreateBatchFromSelectionMenuAction />}
            {isProductionBatch(batch) && isSelectingItems && (
              <RemoveSelectedItemsFromBatchMenuAction />
            )}
            {!isCreatingBatchManually && !isSelectingItems && (
              <CreateBatchFromPreviewMenuAction />
            )}
            {isPreviewBatch(batch) && !isSelectingItems && (
              <StartBatchProductionMenuAction />
            )}
            {isProductionBatch(batch) && !isSelectingItems && (
              <AdvanceBatchMenuAction />
            )}
          </Jig.ActionMenu>
        </Jig>
      </div>
    </BatchContext.Provider>
  )
}
