import type { FC } from 'react'
import type { RouteComponent } from '@ephemeris/types/src/reach-router'

import React from 'react'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useAuth0 } from '@auth0/auth0-react'

import Loader from '@ephemeris/react-components/src/Loader'

export const ChartGenerator: FC<RouteComponent> = ({}) => {
  const [isUnknownTime, setIsUnknownTime] = React.useState<boolean>(false)
  const [chartImageLoadStatus, setChartImageLoadStatus] = React.useState<
    'idle' | 'loading'
  >('idle')
  const [chartDataUrl, setChartDataUrl] = React.useState<string>(null)
  const { getAccessTokenSilently } = useAuth0()

  const handleIsUnknownTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { target } = event
    setIsUnknownTime(target.checked)
  }

  const defaultDate = React.useMemo(DateTime.local, [])
  const defaultIsoDate = defaultDate.toISODate()
  const defaultBirthHour = defaultDate.hour
  const defaultBirthMinute = defaultDate.minute

  async function generateChart(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const target = event.target as unknown as HTMLFormElement['target'] & {
      elements: (HTMLInputElement | HTMLSelectElement)[]
    }
    const elements = target.elements

    const formData = _.reduce(
      elements,
      (acc, element) => {
        if (!element.name || !element.value) {
          return acc
        }

        return { ...acc, [element.name]: element.value }
      },
      {} as Record<string, string>
    )

    const { isoBirthDate } = formData
    const [year, month, day] = isoBirthDate.split('-')
    const birthHour = isUnknownTime ? '12' : formData.birthHour
    const birthMinute = isUnknownTime ? '0' : formData.birthMinute
    const {
      latitude,
      longitude,
      gmtOffset,
      foreground,
      background,
      houseSystem,
      zodiacSystem,
    } = formData
    const size = 800

    const chartUrl = new URL(
      'https://cmfqldx3fa.execute-api.us-east-1.amazonaws.com/birth-chart/talisman'
    )
    const { searchParams: charttUrlSearchParams } = chartUrl

    charttUrlSearchParams.set('year', year)
    charttUrlSearchParams.set('month', month)
    charttUrlSearchParams.set('day', day)
    charttUrlSearchParams.set('hour', birthHour)
    charttUrlSearchParams.set('minute', birthMinute)
    charttUrlSearchParams.set('isUnknownTime', isUnknownTime ? 'true' : 'false')
    charttUrlSearchParams.set('latitude', latitude)
    charttUrlSearchParams.set('longitude', longitude)
    charttUrlSearchParams.set('gmtOffset', gmtOffset)
    charttUrlSearchParams.set('foreground', foreground)
    charttUrlSearchParams.set('background', background)
    charttUrlSearchParams.set('houseSystem', houseSystem)
    charttUrlSearchParams.set('zodiacSystem', zodiacSystem)
    charttUrlSearchParams.set('size', size.toString())

    try {
      setChartImageLoadStatus('loading')

      const accessToken = await getAccessTokenSilently({
        audience: 'https://ephemeris-fulfillment-api',
      })
      const response = await fetch(chartUrl.toString(), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      const { imageDataUrl } = await response.json()

      setChartDataUrl(imageDataUrl)
    } finally {
      setChartImageLoadStatus('idle')
    }
  }

  return (
    <section>
      <div className='flex flex-1'>
        <div className='w-full p-4'>
          <h1 className='text-2xl font-bold'>Chart Generator</h1>

          <div className='mt-3' />

          <form
            method='post'
            className='flex flex-col gap-3'
            onSubmit={generateChart}
          >
            <div>
              <label className='font-semibold'>Birth date:</label>
              <input
                className='block p-2 rounded'
                type='date'
                name='isoBirthDate'
                defaultValue={defaultIsoDate}
              />
            </div>

            <div className='flex items-center gap-2'>
              <label className='font-semibold'>Unknown time:</label>
              <input
                type='checkbox'
                name='isUnknownTime'
                onChange={handleIsUnknownTimeChange}
              />
            </div>

            {isUnknownTime ? null : (
              <div className='flex items-center gap-4'>
                <div>
                  <label className='font-semibold'>Birth Hour:</label>
                  <select
                    className='block p-2 rounded'
                    name='birthHour'
                    defaultValue={defaultBirthHour}
                  >
                    {Array.from(Array(24).keys()).map(hour => (
                      <option value={hour} key={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className='font-semibold'>Birth Minute:</label>
                  <select
                    className='block p-2 rounded'
                    name='birthMinute'
                    defaultValue={defaultBirthMinute}
                  >
                    {Array.from(Array(60).keys()).map(hour => (
                      <option value={hour} key={hour}>
                        {hour}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div>
              <label className='font-semibold'>Latitude:</label>
              <input
                className='block p-2 rounded'
                type='number'
                name='latitude'
                lang='en'
                step={0.00001}
              />
            </div>

            <div>
              <label className='font-semibold'>Longitude:</label>
              <input
                className='block p-2 rounded'
                type='number'
                name='longitude'
                lang='en'
                step={0.00001}
              />
            </div>

            <label className='font-semibold'>
              GMT Offset:
              <select
                name='gmtOffset'
                defaultValue='0'
                className='block p-2 rounded'
              >
                <option value='-12'>GMT -12:00</option>
                <option value='-11'>GMT -11:00</option>
                <option value='-10'>GMT -10:00</option>
                <option value='-9.5'>GMT -09:30</option>
                <option value='-9'>GMT -09:00</option>
                <option value='-8'>GMT -08:00</option>
                <option value='-7'>GMT -07:00</option>
                <option value='-6'>GMT -06:00</option>
                <option value='-5'>GMT -05:00</option>
                <option value='-4.5'>GMT -04:30</option>
                <option value='-4'>GMT -04:00</option>
                <option value='-3.5'>GMT -03:30</option>
                <option value='-3'>GMT -03:00</option>
                <option value='-2'>GMT -02:00</option>
                <option value='-1'>GMT -01:00</option>
                <option value='0'>GMT</option>
                <option value='1'>GMT +01:00</option>
                <option value='2'>GMT +02:00</option>
                <option value='3'>GMT +03:00</option>
                <option value='3.5'>GMT +03:30</option>
                <option value='4'>GMT +04:00</option>
                <option value='4.5'>GMT +04:30</option>
                <option value='5'>GMT +05:00</option>
                <option value='5.5'>GMT +05:30</option>
                <option value='5.75'>GMT +05:45</option>
                <option value='6'>GMT +06:00</option>
                <option value='6.5'>GMT +06:30</option>
                <option value='7'>GMT +07:00</option>
                <option value='8'>GMT +08:00</option>
                <option value='8.75'>GMT +08:45</option>
                <option value='9'>GMT +09:00</option>
                <option value='9.5'>GMT +09:30</option>
                <option value='10'>GMT +10:00</option>
                <option value='10.5'>GMT +10:30</option>
                <option value='11'>GMT +11:00</option>
                <option value='11.5'>GMT +11:30</option>
                <option value='12'>GMT +12:00</option>
                <option value='12.75'>GMT +12:45</option>
                <option value='13'>GMT +13:00</option>
                <option value='14'>GMT +14:00</option>
              </select>
            </label>

            <label className='font-semibold'>
              Foreground:
              <select name='foreground' className='block p-2 rounded'>
                <option value='silver'>Silver</option>
                <option value='black'>Black</option>
                <option value='gold'>Gold</option>
              </select>
            </label>

            <label className='font-semibold'>
              Background:
              <select name='background' className='block p-2 rounded'>
                <option value='black'>Black</option>
                <option value='silver'>Silver</option>
                <option value='gold'>Gold</option>
              </select>
            </label>

            <label className='font-semibold'>
              House System:
              <select name='houseSystem' className='block p-2 rounded'>
                <option value='placidus'>Placidus</option>
                <option value='equal'>Equal</option>
                <option value='whole_sign'>Whole Sign</option>
                <option value='porphyry'>Porphyry</option>
                <option value='koch'>Koch</option>
              </select>
            </label>

            <label className='font-semibold'>
              Zodiac System:
              <select name='zodiacSystem' className='block p-2 rounded'>
                <option value='tropical'>Tropical</option>
                <option value='sidereal'>Sidereal (Lahiri)</option>
              </select>
            </label>

            <button
              type='submit'
              className='p-4 bg-darkBlue text-[snow] font-semibold text-lg mt-4'
            >
              Generate
            </button>
          </form>
        </div>

        <div className='relative flex flex-col justify-center w-full p-4'>
          {chartDataUrl && (
            <img
              src={chartDataUrl}
              onLoad={() => {
                setChartImageLoadStatus('idle')
              }}
            />
          )}
          {chartImageLoadStatus === 'loading' && (
            <div className='absolute inset-0 flex flex-col items-center justify-center bg-darkBlue bg-opacity-30'>
              <Loader style='light' size='xl' />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
