import type { FC } from 'react'
import type { RouteComponent } from '@ephemeris/types/src/reach-router'

import React from 'react'
import { Redirect } from '@reach/router'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { HiOutlineExternalLink } from 'react-icons/hi'

import { usePostersContext } from '../../providers/PostersProvider'
import {
  getLunarPosterPreviewImageUrl,
  getLunarPosterRenderUrl,
} from '@ephemeris/utils/src/product-customization/lunar-poster'
import Loader from '@ephemeris/react-components/src/Loader'

type PosterPageProps = RouteComponent<{
  poster: Product.Poster
}> & {
  posterId?: string
}

const PreviewImage: FC<{ imageSource: string }> = ({ imageSource }) => {
  const [isLoading, setIsLoading] = React.useState(true)

  const handleLoadFinished = () => setIsLoading(false)

  return (
    <div className='relative w-64'>
      <img src={imageSource} className='w-full' onLoad={handleLoadFinished} />
      {isLoading && (
        <div className='bg-darkBlue bg-opacity-30 absolute inset-0'>
          <Loader style='light' />
        </div>
      )}
    </div>
  )
}

export const PosterPage: FC<PosterPageProps> = ({ location, posterId }) => {
  const { posters } = usePostersContext()

  console.log(`--- posters:`, posters)

  if (_.isNil(posters)) {
    return <>Loading...</>
  }

  const poster =
    location?.state?.poster ?? _.find(posters, ({ id }) => posterId === id)

  if (_.isNil(poster)) {
    return <Redirect to='/posters' />
  }

  const [isDownloadingPosterRender, setIsDownloadingPosterRender] =
    React.useState(false)

  const { uniqueOrderName, orderId, customizedAt } = poster
  const isCustomized = !!customizedAt
  const previewImageUrl = isCustomized
    ? getLunarPosterPreviewImageUrl(poster.customizationInfo)
    : undefined

  const handleDownloadPosterImage = async () => {
    setIsDownloadingPosterRender(true)

    try {
      const lunarPosterRenderUrl = await getLunarPosterRenderUrl(
        poster.customizationInfo
      )

      const posterRenderResponse = await fetch(lunarPosterRenderUrl)
      const blob = await posterRenderResponse.blob()

      saveAs(blob, `${uniqueOrderName}_lunar_poster.jpg`)
    } finally {
      setIsDownloadingPosterRender(false)
    }
  }

  return (
    <div className='p-8'>
      <div className='flex items-center'>
        <h1 className='mr-2 text-2xl font-black'>#{uniqueOrderName}</h1>|
        <div className='flex items-center ml-2'>
          <a
            className='underline'
            href={`https://ephemeris-co.myshopify.com/admin/orders/${orderId}`}
            target='_blank'
          >
            Open order in Shopify
          </a>
          <HiOutlineExternalLink className='w-4 ml-1' />
        </div>
      </div>

      {isCustomized && (
        <div className='jsutify-center inline-flex flex-col mt-8'>
          <PreviewImage imageSource={previewImageUrl} />

          <div className='mt-2' />

          <button
            onClick={handleDownloadPosterImage}
            disabled={isDownloadingPosterRender}
          >
            {isDownloadingPosterRender ? (
              <div className='inline-flex items-center gap-2'>
                <Loader style='dark' />
                Downloading Image
              </div>
            ) : (
              <span className='underline'>Download Poster Image</span>
            )}
          </button>
        </div>
      )}
    </div>
  )
}
