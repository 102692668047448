import React, { FC, useEffect, useState } from 'react'
import { groupBy, isEmpty, isNil, map, orderBy, startCase } from 'lodash'
import { Link } from '@reach/router'
import { useAuth0 } from '@auth0/auth0-react'
import { GiLaserburn } from 'react-icons/gi'
import { IoWaterOutline } from 'react-icons/io5'
import { GoPackage } from 'react-icons/go'
import { GrDeliver } from 'react-icons/gr'
import { HiOutlinePrinter, HiOutlineCheck } from 'react-icons/hi'

import useFulfillmentApi from '@ephemeris/fulfillment-api/src/useFulfillmentApi'
import Loader from '@ephemeris/react-components/src/Loader'
import { RouteComponent } from '@ephemeris/types/src/reach-router'
import { ORDERED_BATCH_PRODUCTION_STATUSES } from '@ephemeris/constants/src/fulfillment/jewelry-production'

import useProductionContext from '../useProductionContext'

interface BatchesListProps {
  emptyMessage: string
  batches: JewelryProduction.Batch[] | undefined
  createLink: (batch: JewelryProduction.Batch) => string
}

const ProductionBatchIcon: FC<{
  batchStatus: JewelryProduction.BatchStatus
}> = ({ batchStatus }) => {
  const className = 'w-4 max-w-4 max-h-4'

  switch (batchStatus) {
    case 'ReadyForProduction':
      return <HiOutlineCheck className={className} />
    case 'Engrave':
      return <GiLaserburn className={className} />
    case 'Print':
      return <HiOutlinePrinter className={className} />
    case 'Resin':
      return <IoWaterOutline className={className} />
    case 'Pack':
      return <GoPackage className={className} />
    case 'Post':
      return <GrDeliver className={className} />
    default:
      return null
  }
}

const BatchesList: FC<BatchesListProps> = ({
  batches,
  createLink,
  emptyMessage,
}) => {
  if (isNil(batches)) {
    return <Loader style='dark' />
  }

  if (isEmpty(batches)) {
    return <span className='italic text-gray-500'>{emptyMessage}</span>
  }

  return (
    <>
      {map(batches, batch => (
        <div key={batch.id}>
          <Link to={createLink(batch)} state={{ batch }} className='underline'>
            Batch #{batch.batchNumber}
          </Link>
        </div>
      ))}
    </>
  )
}

const ProductionBatches: FC = () => {
  const context = useProductionContext()
  const { inProduction: batchesInProduction } = context.batches

  const groupedBatches = groupBy(batchesInProduction, 'status')

  return (
    <div className='flex flex-wrap gap-4'>
      {map(
        ORDERED_BATCH_PRODUCTION_STATUSES,
        (status: JewelryProduction.BatchStatus, index) => {
          const batches = isNil(batchesInProduction)
            ? undefined
            : groupedBatches[status] ?? []
          return (
            <div className='flex gap-4' key={`${status}-batches`}>
              {index > 0 && <div className='w-px h-full bg-gray-900' />}

              <div>
                <div className='flex items-center'>
                  <ProductionBatchIcon batchStatus={status} />
                  <div className='ml-1 font-semibold'>{startCase(status)}</div>
                </div>

                <div className='mt-2' />

                <BatchesList
                  batches={batches}
                  emptyMessage={`none`}
                  createLink={({ id }) => `${id}`}
                />
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}

const PreviewBatches: FC = () => {
  const [batches, setBatches] = useState<JewelryProduction.Batch[]>()
  const { getAccessTokenSilently } = useAuth0()
  const queryFulfillmentApi = useFulfillmentApi(getAccessTokenSilently)

  useEffect(() => {
    async function fetchBatches() {
      const response = await queryFulfillmentApi(
        'jewelry-production/batches/generate-preview'
      )
      const { batches } = (await response.json()) as {
        batches: JewelryProduction.Batch[]
      }

      setBatches(orderBy(batches, 'batchNumber'))
    }

    fetchBatches()
  }, [])

  return (
    <BatchesList
      batches={batches}
      emptyMessage='there are no batches te be previewed'
      createLink={() => 'batch-preview'}
    />
  )
}

export const Batches: FC<RouteComponent> = () => {
  return (
    <div className='p-8'>
      <div>
        <Link to='create'>
          <span className='font-bold'>+</span>{' '}
          <span className='underline'>Create batch manually</span>
        </Link>
      </div>

      <div className='mt-4' />
      <h3 className='text-lg font-bold'>
        Batches in production by current status
      </h3>
      <div className='w-full h-1 bg-darkBlue' />

      <div className='mt-4'>
        <ProductionBatches />
      </div>

      <div className='mt-8 ' />
      <h3 className='text-lg font-bold'>Batch previews</h3>
      <div className='w-full h-1 bg-darkBlue' />

      <div className='mt-4'>
        <PreviewBatches />
      </div>
    </div>
  )
}
