export interface ProductionState {
  jewelryItems?: JewelryProduction.Item[]
  jewelryItemsInitialLastEvaluatedKey?: Dictionary<any>
  batches: {
    inProduction?: JewelryProduction.Batch[]
    preview?: JewelryProduction.Batch[]
  }
}
export interface ProductionReducerAction {
  type: string
  payload: any
}

export const ACTIONS = {
  SET_JEWELRY_ITEMS: 'SET_JEWELRY_ITEMS',
  UPDATE_JEWELRY_ITEM: 'UPDATE_ITEM',
  SET_PRODUCTION_BATCHES: 'SET_PRODUCTION_BATCHES',
  UPDATE_PRODUCTION_BATCH: 'UPDATE_PRODUCTION_BATCH',
  ADD_PRODUCTION_BATCH: 'ADD_PRODUCTION_BATCH',
}
