import React, { FC, useState } from 'react'
import { map, trim, isEmpty, isNil } from 'lodash'

import { useModal } from '../../../Modal'

interface RemoveItemsModalBodyProps {
  batch: JewelryProduction.Batch
  jewelryItems: JewelryProduction.Item[]
  onConfirmRemove: (reason: string) => void
}

export const RemoveItemsModalBody: FC<RemoveItemsModalBodyProps> = ({
  batch,
  jewelryItems,
  onConfirmRemove: handleConfirmRemove,
}) => {
  const { dismissModal } = useModal()
  const [reason, setReason] = useState<string | undefined>()

  const acceptRemove = () => {
    if (isNil(reason) || isEmpty(reason)) {
      return
    }

    handleConfirmRemove(reason)
  }

  const handleTextAreaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => setReason(trim(event.target.value ?? ''))

  const canRemoveItems = !isNil(reason) && !isEmpty(reason)

  return (
    <div className='flex flex-col'>
      <div className='mt-4' />

      <span>
        The following items will be removed from Batch #{batch.batchNumber}:
      </span>
      <div className='flex flex-col'>
        {map(jewelryItems, ({ uniqueOrderName }) => (
          <span key={uniqueOrderName} className='text-xs'>
            - #{uniqueOrderName}
          </span>
        ))}
      </div>

      <div className='mt-4' />

      <span className='text-sm'>
        Why are these items being removed from this batch?
      </span>
      <textarea
        className='p-2 text-sm border-2 rounded-md border-lightGold'
        autoFocus={true}
        onChange={handleTextAreaChange}
      ></textarea>

      <div className='mt-4' />

      <div className='flex gap-2'>
        <button
          className={`
             focus:outline-none
            ${canRemoveItems ? 'text-rose-600' : 'text-gray-300'}
          `}
          disabled={!canRemoveItems}
          onClick={acceptRemove}
        >
          Yes, remove items
        </button>
        <button className='underline focus:outline-none' onClick={dismissModal}>
          Cancel
        </button>
      </div>
    </div>
  )
}
