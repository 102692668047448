import type { FC } from 'react'

import React from 'react'

interface Props {
  style?: 'light' | 'dark'
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

const Loader: FC<Props> = ({ style = 'light', size = 'md' }) => {
  const color = style === 'light' ? 'snow' : '#2A2D3D'
  const sizeClasses = (() => {
    switch (size) {
      case 'sm':
        return 'w-2 h-2'
      case 'md':
        return 'w-5 h-5'
      case 'lg':
        return 'w-8 h-8'
      case 'xl':
        return 'w-20 h-20'
    }
  })()

  return (
    <svg
      className={`${sizeClasses} text-white w- animate-spin`}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <circle
        className='opacity-25'
        cx='12'
        cy='12'
        r='10'
        stroke={color}
        strokeWidth={4}
      ></circle>
      <path
        className='opacity-75'
        fill={color}
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  )
}

export default Loader
