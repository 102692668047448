import { isEmpty, isNil } from 'lodash'
import minimist from 'minimist'

export function getEnvironmentInfo() {
  const args = minimist(process.argv.slice(2))
  const stage = args.stage ?? process.env.STAGE ?? process.env.GATSBY_STAGE

  const isDevelopment = stage === 'development' || stage === 'dev'
  const isProduction = stage === 'production' || stage === 'prod'
  const isTest = process.env.NODE_ENV === 'test'

  const { IS_AWS, IS_LOCAL, IS_OFFLINE, AWS_LAMBDA_FUNCTION_NAME } = process.env

  const isAws = !!IS_AWS
  const isLocal = !!IS_LOCAL
  const isAwsLambda = !isNil(AWS_LAMBDA_FUNCTION_NAME)
  const isServerlessOffline = ((): boolean => {
    const isOfflineFlag = IS_OFFLINE

    if (isNil(isOfflineFlag) || isEmpty(isOfflineFlag)) {
      return false
    }

    try {
      return JSON.parse(isOfflineFlag)
    } catch (_) {
      return false
    }
  })()
  const dryRun = !!args['dry-run']

  const environmentName = ((): string => {
    return isDevelopment
      ? 'development'
      : isProduction
      ? 'production'
      : isTest
      ? 'test'
      : 'unknown'
  })()

  return {
    isDevelopment,
    isProduction,
    isTest,
    isAws,
    isAwsLambda,
    isLocal,
    isServerlessOffline,
    dryRun,
    environmentName,
  }
}
