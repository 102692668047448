import type { FC } from 'react'

import React, { useState } from 'react'
import { map, startCase, groupBy, isEmpty } from 'lodash'
import { useToasts } from 'react-toast-notifications'
import { sha256 } from 'js-sha256'

import { getDateTime } from '@ephemeris/utils/src/DateTime'
import { getReportName } from '@ephemeris/utils/src/report'
import Loader from '@ephemeris/react-components/src/Loader'
import { createReportsBacklogGroup } from '@ephemeris/fulfillment/src/reports-backlog'
import { isEither } from '@ephemeris/utils/src/compare'

type Visibility = 'Visible' | 'Hidden'

type SendReportsButtonStatus =
  | 'Disabled'
  | 'Enabled'
  | 'WaitingForConfirmation'
  | 'Sending'
  | 'Sent'

interface TableItemDetailProps {
  group: ReportsBacklog.Group
  onClose: () => void
  onSendReports: (deliveryInfo: ReportsBacklog.DeliveryInfo) => Promise<void>
  onTriggerGenerateReport: (backlogItem: ReportsBacklog.Item) => Promise<void>
}

interface OnSendReportsButtonProps
  extends Pick<TableItemDetailProps, 'onSendReports'> {
  numberOfReports: number
  deliveryInfo: ReportsBacklog.DeliveryInfo
}

type ActionButtonStyle = 'Full' | 'Outline'
type ActionButtonType = 'Primary' | 'Warning' | 'Danger' | 'Regular'

const CopyTextIcon = (): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    stroke-width='2'
    stroke-linecap='round'
    stroke-linejoin='round'
  >
    <rect x='9' y='9' width='13' height='13' rx='2' ry='2'></rect>
    <path d='M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1'></path>
  </svg>
)

const ActionButton: FC<
  Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
    type?: ActionButtonType
    style?: ActionButtonStyle
  }
> = ({ children, type = 'Regular', style = 'Outline', onClick }) => {
  const backgroundColor = ((): string => {
    if (style === 'Outline') {
      return 'bg-transparent'
    }

    switch (type) {
      case 'Primary':
        return 'bg-primary'
      case 'Regular':
        return 'bg-ephemerisBlue'
      case 'Warning':
        return 'bg-amber-400'
      case 'Danger':
        return 'bg-rose-400'
      default:
        return 'bg-ephemerisBlue'
    }
  })()
  const border = `${
    style === 'Outline'
      ? `border-solid border-2 ${((): string => {
          switch (type) {
            case 'Primary':
              return 'border-primary'
            case 'Regular':
              return 'border-ephemerisBlue'
            case 'Warning':
              return 'border-amber-400'
            case 'Danger':
              return 'border-rose-400'
          }
        })()}`
      : ''
  }`
  const text = `${
    style === 'Full'
      ? 'text-gray-50'
      : `${((): string => {
          switch (type) {
            case 'Primary':
              return 'text-primary'
            case 'Regular':
              return 'text-ephemerisBlue'
            case 'Warning':
              return 'text-amber-400'
            case 'Danger':
              return 'text-rose-400'
          }
        })()}`
  }`

  return (
    <button
      onClick={onClick}
      className={`p-4 pt-2 pb-2 rounded-md ${backgroundColor} ${border} ${text}`}
    >
      {children}
    </button>
  )
}

const ActionMenu: FC<{
  subgroup: ReportsBacklog.Group
  onGenerateReports: (items: ReportsBacklog.Item[]) => void
}> = ({ subgroup, onGenerateReports }) => {
  function toggleVisibility(): void {
    const newVisibility = ((): Visibility => {
      switch (visibility) {
        case 'Visible':
          return 'Hidden'
        case 'Hidden':
          return 'Visible'
      }
    })()

    setVisibility(newVisibility)
  }

  const [visibility, setVisibility] = useState<Visibility>('Hidden')

  const { items } = subgroup
  const isVisible = visibility === 'Visible'
  const isEditable = subgroup.status !== 'BeingGenerated'

  return (
    <div className='flex flex-col items-end'>
      <button
        className={`p-2 pt-3 pb-3 rounded-md hover:shadow-md ${
          isVisible ? 'shadow-md' : ''
        }`}
        onClick={toggleVisibility}
      >
        <EllipsisMenuIcon />
      </button>
      {isVisible && (
        <div className='absolute flex flex-col mt-16 bg-white rounded-md shadow-md whitespace-nowrap'>
          <button
            disabled={!isEditable}
            className={`m-1 mb-0 p-2 rounded-md text-ephemerisBlue ${
              isEditable
                ? 'hover:bg-blueGray-50'
                : 'opacity-40 cursor-not-allowed'
            }`}
            onClick={(): void => {
              window.open(`/edit/${subgroup.items[0].id}`, '_blank')
            }}
          >
            Edit report info
          </button>
          <button
            className='p-2 m-1 mt-0 rounded-md text-ephemerisBlue hover:bg-blueGray-50'
            onClick={(): void => {
              onGenerateReports(items)
            }}
          >
            Generate all reports now
          </button>
          <div className='h-px pl-2 pr-2'>
            <div className='w-full h-full bg-gray-100' />
          </div>
          <button
            className='p-2 m-1 rounded-md text-coolGray-600 hover:bg-rose-50'
            onClick={toggleVisibility}
          >
            Close
          </button>
        </div>
      )}
    </div>
  )
}

const EllipsisMenuIcon = (): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' className='w-8'>
    <path d='M12,7a2,2,0,1,0-2-2A2,2,0,0,0,12,7Zm0,10a2,2,0,1,0,2,2A2,2,0,0,0,12,17Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z' />
  </svg>
)

const CloseButton: FC<{ onClose: () => void }> = ({ onClose }) => (
  <button
    onClick={(): void => onClose()}
    className='p-2 text-gray-800 border-2 border-gray-800 rounded-md'
  >
    Close
  </button>
)

export const TableItemDetail: FC<TableItemDetailProps> = ({
  group,
  onClose,
  onSendReports,
  onTriggerGenerateReport,
}) => {
  const { addToast } = useToasts()

  function handleScroll(event: React.UIEvent<HTMLDivElement, UIEvent>): void {
    const { currentTarget } = event

    if (sendReportsButtonStatus !== 'Disabled') {
      return
    }

    const isBottom =
      currentTarget.offsetHeight + currentTarget.scrollTop >=
      currentTarget.scrollHeight

    if (isBottom) {
      setSendReportsButtonStatus('Enabled')
    }
  }

  function askForConfirmationToSendReports(): void {
    setSendReportsButtonStatus('WaitingForConfirmation')
  }

  function denySendReports(): void {
    setSendReportsButtonStatus('Enabled')
  }

  async function generateReports(items: ReportsBacklog.Item[]): Promise<void> {
    const promises = map(items, onTriggerGenerateReport)

    await Promise.all(promises)
  }

  const ReportPreview: FC<{ backlogItem: ReportsBacklog.Item }> = ({
    backlogItem,
  }) => {
    const EmbededReport = (): JSX.Element => (
      <embed
        type='application/pdf'
        src={`${reportUrl!}#view=Fit&pagemode=none&toolbar=0&navpanes=0&scrollbar=0`}
        className='h-report-3/4 w-report-3/4'
      />
    )

    const { reportUrl, status, generationErrors = [] } = backlogItem
    const canPreviewReport = isEither<ReportsBacklog.Status>(status, [
      'SuccessfullyGenerated',
      'Delivered',
    ])
    const [isVisible, setIsVisible] = useState(!canPreviewReport)

    switch (status) {
      case 'SuccessfullyGenerated':
      case 'Delivered':
        return isVisible ? (
          <EmbededReport />
        ) : (
          <div className='flex items-center justify-center w-full'>
            <ActionButton
              onClick={(): void => {
                setIsVisible(true)
              }}
            >
              Preview Report
            </ActionButton>
          </div>
        )
      case 'FailedToBeGenerated':
        return (
          <>
            <p className='text-center'>This report failed to be generated</p>
            <>
              {!isEmpty(generationErrors) && (
                <>
                  <div className='font-bold'>Errors:</div>
                  <ol className='list-decimal'>
                    {map(generationErrors, ({ name, message }) => (
                      <li>
                        <code>
                          {name}: {message}
                        </code>
                      </li>
                    ))}
                  </ol>
                </>
              )}
            </>
            <div className='flex items-center justify-center w-full'>
              <ActionButton
                style='Outline'
                type='Primary'
                onClick={(): void => {
                  onTriggerGenerateReport(backlogItem)
                }}
              >
                Retry
              </ActionButton>
            </div>
          </>
        )
      case 'WaitingForMissingData':
        return (
          <p className='text-center'>
            This report cannot be generated because some info that is required
            to perform the astrological calculations are missing
          </p>
        )
      case 'BeingGenerated':
        return (
          <div className='flex items-center justify-center w-full'>
            <Loader style='dark' />
            <span className='ml-1'>This Report is Being Generated</span>
          </div>
        )
      case 'ReadyToBeGenerated':
        return (
          <>
            <div className='text-center'>
              This Report will be Generated Soon
            </div>
            <div className='flex items-center justify-center w-full mt-2'>
              <ActionButton
                style='Outline'
                type='Primary'
                onClick={(): void => {
                  onTriggerGenerateReport(backlogItem)
                }}
              >
                Generate Now
              </ActionButton>
            </div>
          </>
        )
    }
  }

  const ReportsPreview: FC<{ subgroup: ReportsBacklog.Group }> = ({
    subgroup,
  }) => {
    const REPORTS_ORDER: ReportType[] = [
      'reading',
      'forecasting',
      'saturnCycles',
    ]
    const { items } = subgroup
    const sortedItems = items.sort(
      (a, b) =>
        REPORTS_ORDER.indexOf(a.reportType) -
        REPORTS_ORDER.indexOf(b.reportType)
    )
    return (
      <div className='flex items-center justify-between pt-4 pb-8 pl-12 pr-12 overflow-x-scroll bg-white border-t border-b border-gray-200'>
        {map(sortedItems, item => {
          const { reportType } = item
          const reportName = getReportName(reportType)
          return (
            <>
              <div className='ml-4 mr-4 first:ml-0'>
                <h3 className='text-lg font-bold text-center'>{reportName}</h3>
                <div className='mt-4 overflow-y-visible h-report-3/4 w-report-3/4'>
                  <ReportPreview backlogItem={item} />
                </div>
              </div>
            </>
          )
        })}
      </div>
    )
  }

  const SendReportsButton: FC<OnSendReportsButtonProps> = ({
    onSendReports,
    numberOfReports,
    deliveryInfo,
  }) => {
    const status = sendReportsButtonStatus
    const onClick =
      status === 'Enabled' ? askForConfirmationToSendReports : (): void => {}
    const pluralizedReport = `report${numberOfReports > 1 ? 's' : ''}`
    const isSending = status === 'Sending'
    const isDisabled = status === 'Disabled'
    const isSent = status === 'Sent'
    const isUnclickable = isDisabled || isSending || isSent
    const isWaitingForConfirmation = status === 'WaitingForConfirmation'
    const text = isWaitingForConfirmation
      ? `Double-click to send ${numberOfReports} ${pluralizedReport} to ${deliveryInfo.destinationEmail}`
      : isSending
      ? `Sending ${numberOfReports} ${pluralizedReport} to ${deliveryInfo.destinationEmail}...`
      : isSent
      ? 'Email Sent!'
      : `Send ${numberOfReports} ${pluralizedReport}`
    const backgroundColor = isWaitingForConfirmation
      ? 'bg-yellow-500'
      : 'bg-primary'

    return (
      <>
        <button
          disabled={isUnclickable}
          className={`flex items-center justify-center w-full h-14 ${backgroundColor} text-gray-50 font-bold rounded-md ${
            isUnclickable ? 'bg-opacity-50 cursor-default' : ''
          }`}
          onClick={onClick}
          onDoubleClick={async (): Promise<void> => {
            if (!isWaitingForConfirmation) {
              return
            }

            try {
              setSendReportsButtonStatus('Sending')
              await onSendReports(deliveryInfo)
              setSendReportsButtonStatus('Sent')
              addToast('Successfully sent reports!', {
                autoDismiss: true,
                appearance: 'success',
              })
            } catch {
              setSendReportsButtonStatus('Enabled')
              addToast('An error occurred and the email could not be sent', {
                autoDismiss: true,
                appearance: 'error',
              })
            }
          }}
        >
          {isSending && <Loader />}
          {text}
        </button>
        {isWaitingForConfirmation && (
          <button
            className={`w-full h-14 text-primary`}
            onClick={(): void => {
              denySendReports()
            }}
          >
            Don't Send Email
          </button>
        )}
      </>
    )
  }

  const { items, destinationEmail, status, id } = group
  const groupedItems = groupBy(items, ({ nameOnReport, birthInfo }) => {
    const { latitude, longitude } = birthInfo.location
    const birthDateString = getDateTime(birthInfo).toISO()
    return sha256(`${nameOnReport}${birthDateString}${latitude}${longitude}`)
  })
  const subgroups = map(groupedItems, createReportsBacklogGroup)
  const canSendReports =
    status === 'SuccessfullyGenerated' || status === 'Delivered'
  const deliveryInfo: ReportsBacklog.DeliveryInfo = {
    groupId: id,
    destinationEmail,
    downloadUrl: `https://reports.ephemeris.co/download/${id}`,
  }

  const [sendReportsButtonStatus, setSendReportsButtonStatus] =
    useState<SendReportsButtonStatus>(
      status === 'Delivered' ? 'Sent' : 'Enabled'
    )

  return (
    <div className='fixed inset-0 z-10 flex items-center justify-center w-screen h-screen bg-black bg-opacity-75'>
      <div
        id='table-item-detail-content'
        className='overflow-y-scroll rounded-md shadow-xl bg-gray-50 max-h-11/12 max-w-11/12'
        onScroll={handleScroll}
      >
        <div className='mt-12' />

        <div className='flex justify-start w-full pl-12'>
          <CloseButton onClose={onClose} />
        </div>

        {map(subgroups, (group, index) => {
          const [backlogItem] = group.items
          const { birthInfo, missingProperties } = backlogItem
          const { status, items } = group
          const {
            location: { name: locationName },
          } = birthInfo
          const birthDate = getDateTime(birthInfo)

          return (
            <div key={`subgroup-${index}`}>
              {index > 0 && (
                <>
                  <div className='bg-ephemerisBlue w-full h-0.5 mt-16' />
                  <div className='bg-white w-full h-0.5 mb-16' />
                </>
              )}
              <div className='sticky top-0 flex items-center justify-between w-full pt-12 pb-4 pl-12 pr-12 bg-gray-50'>
                <h1 className='text-3xl font-light'>
                  {backlogItem.nameOnReport}
                </h1>
                <ActionMenu
                  subgroup={group}
                  onGenerateReports={generateReports}
                />
              </div>
              <div className='flex flex-col pl-12 pr-12 leading-loose first:mt-0'>
                <div>
                  <div className='mt-4' />

                  <p>
                    <b>Birth Date</b>:{' '}
                    {birthDate.toFormat('MMMM dd, yyyy, HH:mm a z')}
                  </p>
                  <p>
                    <b>Place</b>: {locationName}
                  </p>
                  <p>
                    <b>Status</b>: {startCase(status)}
                  </p>
                  <p>
                    <b>Reports that Will be Generated</b>:{' '}
                    {items
                      .map(({ reportType }) => startCase(reportType))
                      .join(', ')}
                  </p>
                  <p>
                    <b>Email to send report</b>: {destinationEmail}
                  </p>
                  {status === 'WaitingForMissingData' && (
                    <>
                      <b>Missing Properties</b>:
                      <ul className='pl-8 list-disc'>
                        {map(missingProperties, property => (
                          <li key={property}>{property}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  <p className='flex items-center'>
                    <b>Reports Download Page</b>:{' '}
                    <div className='flex items-center p-4 ml-2 bg-white border-2 border-gray-200 rounded-md'>
                      <button
                        onClick={async (e): Promise<void> => {
                          e.stopPropagation()
                          await navigator.clipboard.writeText(
                            deliveryInfo.downloadUrl
                          )

                          addToast(`The url has been copied!`, {
                            appearance: 'info',
                            autoDismiss: true,
                          })
                        }}
                        className='w-5 h-5 mr-2'
                      >
                        <CopyTextIcon />
                      </button>
                      <code className='font-mono text-primary'>
                        <a
                          href={deliveryInfo.downloadUrl}
                          className='hover:underline'
                          target='_blank'
                          rel='noreferrer noopener'
                        >
                          {deliveryInfo.downloadUrl}
                        </a>
                      </code>
                    </div>
                  </p>
                  <p className='flex items-center'>
                    <b>Individual Download Page</b>:{' '}
                    <div className='flex items-center p-4 ml-2 bg-white border-2 border-gray-200 rounded-md'>
                      <button
                        onClick={async (e): Promise<void> => {
                          e.stopPropagation()
                          await navigator.clipboard.writeText(
                            `https://reports.ephemeris.co/download/${backlogItem.lineItemId}`
                          )

                          addToast(`The url has been copied!`, {
                            appearance: 'info',
                            autoDismiss: true,
                          })
                        }}
                        className='w-5 h-5 mr-2'
                      >
                        <CopyTextIcon />
                      </button>
                      <code className='font-mono text-primary'>
                        <a
                          href={`https://reports.ephemeris.co/download/${backlogItem.lineItemId}`}
                          className='hover:underline'
                          target='_blank'
                          rel='noreferrer noopener'
                        >
                          {`https://reports.ephemeris.co/download/${backlogItem.lineItemId}`}
                        </a>
                      </code>
                    </div>
                  </p>
                </div>
              </div>
              <div className='mt-8' />
              <ReportsPreview subgroup={group} />
            </div>
          )
        })}

        {canSendReports && (
          <div
            className={`${
              status !== 'Delivered' ? 'sticky bottom-0' : ''
            }  bg-gray-50 p-12 shadow-top-md`}
          >
            <SendReportsButton
              onSendReports={onSendReports}
              deliveryInfo={deliveryInfo}
              numberOfReports={items.length}
            />
          </div>
        )}
      </div>
    </div>
  )
}
